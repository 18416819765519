.seo-publication {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 17px;
    background-color: transparent;
    cursor: pointer;
  }

  &__title {
    text-align: left;
    font-family: inherit;
    font-size: 16px;
  }

  &__arrow {
    flex-shrink: 0;
    transform: rotate(270deg);
    transition: transform .4s;

    &--open {
      transform: rotate(90deg);
    }
  }

  &__content {
    display: none;
    border-top: 1px solid #d1d3e2;

    &--open {
      display: block;
    }
  }

  .seo-edit_wrapper {
    margin: 0;
    border: 0;
  }

  .meta_fields {
    width: 100% !important;
  }
}
