.select_component {
  height: 100%;
  & ~ .date_wrapper {
    margin-left: 20px;
  }
  .select__control--is-focused {
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    border: 1px solid #bac8f3;
    outline: 0;
    .indicator_active {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }


  .select__control {
    height: 100%;
    color: #6e707e!important;
    font-family: 'Nunito Sans', sans-serif!important;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    &:hover {
      cursor: pointer;
      border: 1px solid #EDEDED;

    }
    .select__placeholder {
      color: #6e707e;
      opacity: 0.5;
    }
    .select__single-value {
      font-size: 1rem;
      color: #6e707e;
    }
    .select__input {
      font-size: 1rem;
      color: #6e707e;
      font-family: 'Nunito Sans', sans-serif;
    }
  }
  .select__menu {
    z-index: 9999;
    margin: 0;
    position: unset; //////////////////////////////////////////////////////////////////////////////////////////////
    .select__menu-list {
      border-radius: 0.35rem;
      padding-top: 0;
      &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #EDF2F8;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #4e73df;
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: #4e73df;
      }
      font-family: 'Nunito Sans', sans-serif;
      color: #6e707e;
      background: transparent;
      .select__option{
        padding: 0 12px;
        background: transparent;
        color: #6e707e;
        cursor: pointer;
        &:hover{
          background-color: #4e73df;
          color: #FFFFFF;
        }
      }
      .select__option--is-selected {
        color: #FFFFFF;
        background-color: #4e73df;
      }

      .status_select{
        display: flex;
        align-items: center;
        background: transparent;
        &:hover{
          background: transparent;
        }
      }
    .select__menu-notice--no-options {
      padding: 0 12px;
      text-align: left;
      color: #6e707e;
    }
    }
  }
}

.state_selector {
  margin: 8px 0;
}


.select_wrapper.multi{
  width: 100%;
  margin-top: 8px;
  max-height: 38px!important;
  .multi_select_component {
    .css-yk16xz-control {
      width: 100%;
      height: 38px;
    }
    .css-1pahdxg-control {
      height: 38px;
      max-height: 38px;
    }
    .css-tlfecz-indicatorContainer {
      padding: 0;
    }
    .css-1gtu0rj-indicatorContainer {
      padding: 0px!important;
    }
    .css-1hwfws3 {
      flex-wrap: nowrap;
      max-height: 38px;
      height: 38px;
    }
  }
}




