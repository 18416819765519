.block-input-pass {
    width: 100%;
    position: relative;

    .error {
        display: none;
    }
    &.error_border,
    &.value.error_border {
        position: relative;

        .error {
            display: block;
            color: #d0021b;
            position: absolute;
            right: 0;
            font-size: 10px;
            line-height: 13px;
        }
    }
}

.block-input-mail {
    display: block;
    width: 100%;
    position: relative;

    &.error_border,
    &.value.error_border {
        position: relative;

        span.error {
            color: #d0021b;
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 10px;
            line-height: 13px;
            display: block;
        }
    }
}

.block-input-description {
    display: block;
    width: 100%;
    position: relative;

    &.error_border,
    &.value.error_border {
        position: relative;

        span.error {
            color: #d0021b;
            position: absolute;
            right: 0;
            bottom: -8px;
            left: 0;
            font-size: 9px;
            line-height: 13px;
            display: block;
        }
    }
}
