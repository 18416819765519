.publications_profile_wrapper{
  margin: 0 24px;
  .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 0 16px ;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796;
    a{
      color: #4e73df;
      text-decoration: none;
    }
  }

  .button {
    color: #fff;
    width: fit-content;
    background-color: #1cc88a;
    border-color: #1cc88a;
    cursor: pointer;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 5.6px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
    }
    &:hover{
      background-color: #17a673;

    }
    &:active{
      background-color: #169b6b;
      border-color: #149063;
    }
    &:disabled{
      opacity: 0.7;
      &:hover{
        background-color: #1cc88a;
      }
    }
  }
  .profile_title {
    color: #858796;
    font-size: 40px;
    padding: 12px 20px;
  }
  .profile_name{
    padding: 12px 20px;
    color: #858796;
    margin-bottom: 24px;
    font-size: 32px;
    display: block;
  }

  .connection_row{
    margin-bottom: 16px;
    display: flex;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    max-width: 66%;
    padding: 0 12px;
    position: relative;
    .connection_title{
      box-sizing: border-box;
      min-width: fit-content;
      color: #858796;
      border: 1px solid rgb(209, 211, 226);
      background-color: #eaecf4;
      padding: 6px 12px;
      border-bottom-left-radius: 5.6px;
      border-top-left-radius: 5.6px;
      display: flex;
      align-items: center;
    }
    .connection_input{
      border: 1px solid rgb(209, 211, 226);
      border-top-right-radius: 5.6px;
      border-bottom-right-radius: 5.6px;
      background-color: #FFFFFF;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      margin-right: 8px;
      color: #858796;
      &:disabled {
        background-color: #eaecf4;
      }
    }
  }

  .blocks_wrapper {
    display: flex;
    flex-direction: row;
  }

  .column{
    display: flex;
    flex-direction: column;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .add_input{
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus{
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }
    &::placeholder {
      color: rgb(133, 135, 150);
    }
  }

  .profile_block {
    border: 1px solid #e3e6f0;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 5.6px;
    margin-bottom: 16px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    .card_body {
      padding: 20px;
      background-color: #FFFFFF;
      .profile_banner{
        width: 100%;
      }
      span{
        color: #858796;
      }
      .flag-icon {
        border-radius: 50%;
        margin: 0 20px 0 auto;
      }
      .input_title{
        margin-bottom: 8px;
        margin-top: 16px;
        display: block;
      }
      .field_wrapper {
        display: flex;
        flex-direction: row;
        .lang_switcher {
          display: flex;
          flex-direction: column;
          margin-right: 24px;
          .item {
            cursor: pointer;
            display: block;
            padding: 0.75rem 1.25rem;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.125);
            &:first-child {
              border-top-left-radius: 0.35rem;
              border-top-right-radius: 0.35rem;
              border-bottom: none;
            }
            &:last-child {
              border-bottom-right-radius: 0.35rem;
              border-bottom-left-radius: 0.35rem;
            }
          }
          .item.active {
            z-index: 2;
            color: #fff;
            background-color: #4e73df;
            border-color: #4e73df;
          }
        }
      }
    }
    .title_information_block {
      max-width: 59%;
      width: 100%;
      padding: 20px 20px 20px 0 ;

      .lang_switcher {
        display: flex;
        margin-bottom: 15px;
        width: fit-content;
        .item {
          cursor: pointer;
          display: block;
          padding: 6px 12px;
          box-sizing: border-box;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.125);
          color: rgb(133, 135, 150);
          &:first-child {
            border-top-left-radius: 0.35rem;
            border-bottom-left-radius: 0.35rem;
          }
          &:last-child {
            border-bottom-right-radius: 0.35rem;
            border-top-right-radius: 0.35rem;
          }
        }
        .item.active {
          z-index: 2;
          color: #fff;
          background-color: #4e73df;
          border-color: #4e73df;
        }
      }
      .lang_block {
        display: flex;
        flex-direction: column;
        .add_input {
          margin-bottom: 16px;
        }
      }
    }
    &.picture {
      flex-direction: column;
      margin: 20px 10px 20px 20px;
      max-width: 40%;
      width: 100%;
    }

    .input_row {
      height: fit-content;
      margin: 0 20px;
      display: flex;
      justify-content: space-between;
      .textarea{
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Nunito Sans', sans-serif;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 0;
        margin-bottom: 0;
        min-height: 150px;
        border-bottom-right-radius: 5.6px;
        border-top-right-radius: 5.6px;
        resize: vertical;
        &:focus{
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
          border-color: #bac8f3;
        }
      }
      &:last-child {
        margin-bottom: 36px;
      }
    }
    &:last-child {
      .content_title {
        padding: 20px 0;
        color: #858796;
        font-size: 32px;
        font-weight: 400;
      }
      .buttons {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        .green_button {
          margin: unset;
          width: unset;
          height: unset;
          padding: 6px 12px;
        }
        .red_button {
            background-color: #e74a3b;
            cursor: pointer;
            padding: 9px 12px 6px;
            line-height: 24px;
            font-size: 16px;
            color: #FFFFFF;
            border-radius: 5.6px;
            font-family: "Nunito Sans";
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          &:focus{
            box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
            border-color: #ca2819;
          }
          &:active{
            background-color: #d52a1a;
            border-color: #ca2819;
          }
          &:hover{
            background-color: #e02d1b;
          }
        }
      }
    }
    .back_error {
      font-size: 14px!important;
      top: 5px!important;
    }
  }
  .profile_block.wysiwyg {
    padding: 0 20px 20px 20px;
  }

  .card_footer{
    padding: 12px 20px;
    background-color: #f8f9fc;
    border-top: 1px solid #e3e6f0;
    .pic_err{
      margin-bottom: 16px;
      display: flex;
      color: #D0021B;
    }
    .upload_file{
      width: 100%;
      position: relative;
      .upload_input{
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        margin: 0;
        opacity: 0;
      }
      .upload_label{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        padding: 6px 80px 6px 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &::after{
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 3;
          display: block;
          padding: 0.375rem 0.75rem;
          line-height: 1.5;
          color: #6e707e;
          content: "Browse";
          background-color: #eaecf4;
          border-left: inherit;
          border-radius: 0 0.35rem 0.35rem 0;
        }
      }
    }
  }
  .text_row {
    color: rgb(133, 135, 150);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
}
.dialog_footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between!important;
}