.sidebar_wrapper {
    background-color: #4e73df;
    background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
    background-size: cover;
    min-width: 224px;
    .sidebar {
        color: rgba(255, 255, 255, 0.4);
        padding: 0 16px;
    }
    .sidebar_tab.lawthor {
        height: 69px;
        align-items: center;
        padding-top: 0;
        display: flex;
        justify-content: space-around;
        img {
            height: 32px;
            margin-right: 16px;
        }
        a {
            font-size: 16px;
            font-weight: 900;
            color: #ffffff;
            display: flex;
            padding-right: 15px;
        }
    }
    .sidebar_tab.dashboard {
        padding-top: 0;
    }
    .sidebar_tab {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        text-transform: uppercase;
        font-size: 10.5px;
        font-weight: 900;
        padding-top: 16px;
        .active {
            font-weight: 900;
            color: white;
            path {
                fill: #ffffff;
            }
        }
        svg {
            height: 13.6px;
            margin-right: 8px;
            path {
                fill: rgba(255, 255, 255, 0.3);
            }
        }
    }
    a {
        text-decoration: none;
        padding: 16px 15px 16px 0;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        text-transform: none;
        font-weight: 400;
        display: flex;
        align-items: center;
        &:hover {
            color: #ffffff;
            path {
                fill: #ffffff;
            }
        }
    }
}
