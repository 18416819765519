.default_dialog_root {
  .paper_custom_dialog{
    min-width: 840px;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .dialog_close_button {
    cursor: pointer;
    position: absolute;
    right:30px;
    top: 30px;
    background: transparent;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      background: transparent;
      svg{
        opacity: 1;
      }
    }
  }
}
.header_dialog {
  .MuiDialog-container{
    height: 100%!important;
    .paper_custom_dialog{
    min-width: 500px;
    border-radius: 5.6px;
      .dialog_wrapper{
        color: #858796;
        box-sizing: border-box;
        padding: 0;
        .dialog_close_button{
          right: 20px;
          top: 25px;
          svg{
            height: 15px;
            opacity: 0.3;
            &:hover{
             opacity: 1;
            }
            path{
              fill: #1A1A1A;
            }
          }
        }
       .dialog_header{
          padding: 16px;
          border-bottom: 1px solid #e3e6f0;
          font-size: 20px;
        }
        .dialog_body{
          padding: 16px;
          border-bottom: 1px solid #e3e6f0;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          color: #858796;
          text-align: left;
        }
        .dialog_footer{
           display: flex;
           align-items: center;
           justify-content: flex-end;
           padding: 16px;
           button{
             background-color: #858796;
             cursor: pointer;
             padding: 9px 12px 6px;
             line-height: 24px;
             font-size: 16px;
             color: #FFFFFF;
             border-radius: 5.6px;
             font-family: "Nunito Sans";
             margin-right: 8px;
             transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
             &:focus{
               box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
             }
             &:active{
               background-color: #6b6d7d;
               border-color: #656776;
             }
             &:hover{
               background-color: #717384;
             }

           }
           button:nth-child(2){
             line-height: 24px;
             font-size: 16px;
             border-radius: 5.6px;
             color: #FFFFFF;
             padding: 9px 12px 6px;
             background-color: #4e73df;
             &:hover{
               background-color: #2e59d9;
               border-color: #2653d4;
             }
             &:active{
               background-color: #2653d4;
               border-color: #244ec9;
             }
             &:focus{
               box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
             }
           }
         }
      }
    }
  }
}

.delete_dialog {
  .MuiDialog-container{
    height: 100%!important;
    .paper_custom_dialog{
      min-width: 500px;
      border-radius: 5.6px;
      .dialog_wrapper{
        color: #858796;
        box-sizing: border-box;
        padding: 0;
        .dialog_close_button{
          right: 20px;
          top: 25px;
          svg{
            height: 15px;
            opacity: 0.3;
            &:hover{
              opacity: 1;
            }
            path{
              fill: #1A1A1A;
            }
          }
        }
        .dialog_header{
          padding: 16px;
          border-bottom: 1px solid #e3e6f0;
          font-size: 20px;
        }
        .dialog_body{
          padding: 16px;
          border-bottom: 1px solid #e3e6f0;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          color: #858796;
          text-align: left;
        }
        .dialog_footer{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 16px;
          button{

            cursor: pointer;
            padding: 9px 12px 6px;
            line-height: 24px;
            font-size: 16px;
            color: #FFFFFF;
            border-radius: 5.6px;
            font-family: "Nunito Sans";
            margin-right: 8px;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            &:first-child{
              background-color: #e74a3b;

              &:focus{
                 box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
               }
              &:active{
                background-color: #d52a1a;
                border-color: #ca2819;
              }
              &:hover{
                background-color: #e02d1b;
              }
              &:disabled {
                opacity: .5;
              }
            }
            &:first-child.add_btn{
              background-color: #1cc88a;
              border-color: #1cc88a;
              &:disabled{
                opacity: .5;
              }
              &:focus {
                box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
              }
              &:hover{
                background-color: #17a673;
                border-color: #169b6b;
              }
              &:active{
                background-color: #169b6b;
                border-color: #149063;
              }
            }
            &:last-child{
              background-color: #4e73df;
              &:focus{
                box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
              }
              &:active{
                background-color: #2653d4;
                border-color: #244ec9;
              }
              &:hover{
                background-color: #2e59d9;
                border-color: #2653d4;
              }
            }
            &:last-child.cancel_btn{
              background-color: #4e73df;
              &:focus{
                box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
              }
              &:active{
                background-color: #2653d4;
                border-color: #244ec9;
              }
              &:hover{
                background-color: #2e59d9;
                border-color: #2653d4;
              }
            }
          }
        }
        .dialog_footer.profile_trash{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          button{

            cursor: pointer;
            padding: 9px 12px 6px;
            line-height: 24px;
            font-size: 16px;
            color: #FFFFFF;
            border-radius: 5.6px;
            font-family: "Nunito Sans";
            margin-right: 8px;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            &:first-child{
              background-color: #e74a3b;

              &:focus{
                box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
              }
              &:active{
                background-color: #d52a1a;
                border-color: #ca2819;
              }
              &:hover{
                background-color: #e02d1b;
              }
            }
            &:last-child{
              background-color: #4e73df;
              &:focus{
                box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
              }
              &:active{
                background-color: #2653d4;
                border-color: #244ec9;
              }
              &:hover{
                background-color: #2e59d9;
                border-color: #2653d4;
              }
            }
          }
        }
      }
    }
  }
}

.disconnect_dialog {
  .MuiDialog-container{
    height: 100%!important;
    .paper_custom_dialog{
      min-width: 500px;
      border-radius: 5.6px;
      .dialog_wrapper{
        color: #858796;
        box-sizing: border-box;
        padding: 0;
        .dialog_close_button{
          right: 20px;
          top: 25px;
          svg{
            height: 15px;
            opacity: 0.3;
            &:hover{
              opacity: 1;
            }
            path{
              fill: #1A1A1A;
            }
          }
        }
        .dialog_header{
          padding: 16px;
          border-bottom: 1px solid #e3e6f0;
          font-size: 20px;
        }
        .dialog_body{
          padding: 16px;
          border-bottom: 1px solid #e3e6f0;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          color: #858796;
          text-align: left;
        }
        .dialog_footer{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          button{

            cursor: pointer;
            padding: 9px 12px 6px;
            line-height: 24px;
            font-size: 16px;
            color: #FFFFFF;
            border-radius: 5.6px;
            font-family: "Nunito Sans";
            margin-right: 8px;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            &:first-child{
              background-color: #f6c23e;
              border-color: #f6c23e;

              &:focus{
                box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
              }
              &:active{
                background-color: #f4b30d;
                border-color: #e9aa0b;
              }
              &:hover{
                background-color: #f4b619;
                border-color: #f4b30d;
              }
            }
            &:last-child{
              background-color: #4e73df;
              &:focus{
                box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
              }
              &:active{
                background-color: #2653d4;
                border-color: #244ec9;
              }
              &:hover{
                background-color: #2e59d9;
                border-color: #2653d4;
              }
            }
          }
        }
      }
    }
  }
}

.dialog_wrapper {
  padding: 40px 60px 30px 60px;
  -moz-margin-end : 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  background-color: #FFFFFF;
  max-height: 850px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;

    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #5CE6CD;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #5CE6CD;
  }
}





