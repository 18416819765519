.container {
  max-width: 936px;
  margin: 48px auto 0;
  display: flex;
  flex-direction: row;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  box-sizing: border-box;
  height: 400px;
  border-radius: 4px;

  .image_site {
    width: 50%;
    background: url('https://source.unsplash.com/K4mSJ7kc0As/600x800') no-repeat center;
    background-size: cover;
    border-radius: 4px;
  }
  }
  .form_title {
    margin-bottom: 20px;
    font-size: 24px;
  }
  .form_site {
    width: 50%;
    padding: 48px 60px;
    text-align: center;
    flex: 2;
    .form_auth {
      display: flex;
      flex-direction: column;
      align-items: center;
      .back_error {
        top: 130px;
        right: unset;
      }
      .email_input{
          border-radius: 50px;
          border: 1px solid #d1d3e2;
          font-weight: 400;
          font-size: 13px;
          line-height: 1.5;
          color: #6e707e;
          width: 100%;
          height: 50px;
          padding: 0 16px;
          box-sizing: border-box;

      }
      .checkbox_input{
        width: 100%;
        display: flex;

      }
      label {
        color: #858796;
        font-size: 13px;
        margin-bottom: 16px;
      }
      button {
        width: 100%;
        background: #4E73DF;
        border-radius: 50px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #FFFFFF;
        text-decoration: none;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        cursor: pointer;
        &:hover{
          background-color: #2e59d9;
        }
      }
      label {
        .error {
          bottom: unset!important;
        }
      }
    }
    hr {
      margin: 16px 0;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

  }

