.control_btn {
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 40px;
  height: 38px;
  cursor: pointer;
  margin-right: 3px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 17px;
    height: 16px;
  }
}
.trash {
  background-color: #e74a3b;
  border-color: #e74a3b;
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
  }
  &:hover{
    background-color: #e02d1b;
    border-color: #d52a1a;
  }
  &:disabled{
    opacity: .5;
  }
}
.change {
  background-color: #4e73df;
  border-color: #4e73df;

  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
  }
  &:hover{
    background-color: #2e59d9;
    border-color: #2653d4;
  }
}
.link {
  background-color: #36b9cc;
  border-color: #36b9cc;

  &:active{
    box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
  }
  &:hover{
    background-color: #2c9faf;
    border-color: #2a96a5;
  }
}
.disconnect{
  background-color: #f6c23e;
  border-color: #f6c23e;
    svg{
      width: 17px;
      path{
        fill: #FFFFFF;
      }
    }

  &:active{
    background-color: #f4b30d;
    border-color: #e9aa0b;
    box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
  }
  &:hover{
    background-color: #f4b619;
    border-color: #f4b30d;
  }
}
