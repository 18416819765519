body{
  max-height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
  &::-webkit-scrollbar {
    width: 10px;
    height:10px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;

    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #4e73df;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #4e73df;
  }
}


a{
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-decoration: none;
}

button{
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

h1{

  font-size: 32px;
  line-height: 30px;
}


.back_error {
  color: #D0021B;
  font-size: 10px;
  line-height: 13px;
  top: 12px;
  right: 0;
  position: absolute;
  text-align: end;
  font-family: "GothaProMed",sans-serif;
}

.error_sign_up {
  margin: -25px 0 12px;
}

.text_back_error_pos_abs {
  position: absolute;
  bottom: -15px;
}

.pos_rel {
  position: relative;
}

.text_red{
  color: #D0021B;
}
.text_green{
  color: #2CC197;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(92, 230, 205, 0.02);
}

//textarea{
//  max-height: 96px;
//  overflow: auto;
//  box-sizing: border-box;
//  font-family: "GothaProReg",sans-serif;
//  &::placeholder{
//    font-family: "GothaProLig",sans-serif;
//  }
//  &::-webkit-scrollbar {
//    width: 4px;
//  }
//  &::-webkit-scrollbar-track {
//    -webkit-border-radius: 2px;
//    border-radius: 2px;
//    margin-right: 5px;
//    background: #EDF2F8;
//  }
//  &::-webkit-scrollbar-thumb {
//    -webkit-border-radius: 10px;
//    border-radius: 10px;
//    background: #5CE6CD;
//  }
//  &::-webkit-scrollbar-thumb:window-inactive {
//    background: #5CE6CD;
//  }
//}

input {
  font-family: 'Nunito Sans', sans-serif;
}

button {
  font-family: 'Nunito Sans', sans-serif;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.green_button {
  cursor: pointer;
  background-color: #1cc88a;
  font-family: 'Nunito Sans', sans-serif;
  border-color: #1cc88a;
  color: #FFFFFF;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  border-radius: 0.35rem;
  width: 175px;
  height: 50px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0 15px 16px 39px ;
  &:hover{
    background-color: #17a673;
    border-color: #169b6b;
  }
  &:focus{
    box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
  }
  &:active{
    background-color: #169b6b;
    border-color: #149063;
  }
}
.main_wrapper {
  background-color: #f8f9fc;
  display: flex;
  box-sizing: border-box;
  min-height: 100vh;

  .main_content {
    width: calc(100% - 224px);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
}

// common styles //

.relative_position {
  position: relative;
}

.no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.small_br {
  height: 10px;
}

.Unactive {
  color: #5a5c69!important;
}

.Data {
  color: #f6c23e!important;
}

.Active {
  color: #1cc88a!important;
}

@media only screen and (max-width: 1680px) {
  .media_error {
    top: -37px!important;
    max-width: 46px;
  }
}