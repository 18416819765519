.seo_wrapper {
    display: flex;
    flex-direction: column;
    .breadcrumbs {
        display: flex;
        padding: 12px 16px;
        margin: 0 24px 16px;
        list-style: none;
        background-color: #eaecf4;
        border-radius: 0.35rem;
        color: #858796;
        a {
            color: #4e73df;
            text-decoration: none;
        }
    }
}
