.page-animation-enter {
  opacity: 0.01;
}

.page-animation-enter.page-animation-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.page-animation-leave {
  opacity: 1;
}

.page-animation-leave.page-animation-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.page-animation-appear {
  opacity: 0.01;
}

.page-animation-appear.page-animation-appear-active {
  opacity: 1;
  transition: all 1s ease;
}

.default_block {
  width: 100%;
}