.user_profile_wrapper {
  margin: 0 24px;
  .user_name{
    color: #5a5c69;
    margin-bottom: 24px;
    font-size: 28px;
    display: block;
  }
  .profile_block{
    margin-bottom: 16px;
    .profile_block_title{
      background-color: rgba(0, 0, 0, 0.03);
      border-top-left-radius: 5.6px;
      border-top-right-radius: 5.6px;
      border-bottom: 1px solid rgba(0,0,0,.125);
      padding: 12px 20px;
      color: #858796;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .add_button{
        display: flex;
        align-items: center;
        color: #fff;
        background-color: #1cc88a;
        border-color: #1cc88a;
        border-radius: 5.6px;
        cursor: pointer;
        div{
          display: flex;
          align-items: center;
          padding: 6px 12px;
          line-height: 24px;
          background-color: rgba(0, 0, 0, 0.15);
          height: 24px;
        }
        span{
          font-size: 16px;
          padding: 6px 12px;

        }
        svg{
          width: 16px;

        }
        &:hover{
          background-color: #17a673;
          border-color: #169b6b;
        }
        &:active{
          background-color: #169b6b;
          border-color: #149063;
        }
        &:focus{
          box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
        }
      }
    }
    .card_body{
      padding: 20px;
      background-color: #FFFFFF;
      .collapse_form{
         width: 50%;
         padding: 20px;
         border: 1px solid rgb(227, 230, 240);
         border-radius: 5.6px;
         box-sizing: border-box;
         margin-bottom: 16px;
         button{
           line-height: 24px;
           font-size: 16px;
           border-radius: 5.6px;
           color: #FFFFFF;
           padding: 7px 12px 7px;
           background-color: #4e73df;
           cursor: pointer;
           &:hover{
             background-color: #2e59d9;
             border-color: #2653d4;
           }
           &:active{
             background-color: #2653d4;
             border-color: #244ec9;
           }
           &:focus{
             box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
           }
         }
         .language_row{
           margin-bottom: 16px;
           display: flex;
           line-height: 24px;
           font-size: 16px;
           .language_title{
             box-sizing: border-box;
             min-width: fit-content;
             color: #858796;
             border: 1px solid rgb(209, 211, 226);
             background-color: #eaecf4;
             padding: 4px 8px;
           }
           .language_input{
             border: 1px solid rgb(209, 211, 226);
             width: 100%;
             padding: 4px 8px;
             color: #6e707e;
             font-size: 14px;
           }
         }
       }
      .card_items_row{
        display: flex;
        border-top: 1px solid #e3e6f0;
        color: #858796;
        font-size:16px;
        font-weight:800;
        justify-content: flex-start;
        padding: 12px 6px ;
        & > div{
          display: flex;
          align-items: flex-end;
          width: 10%;
          justify-content: space-around;
          text-align: center;
          .table_div{
            margin: 0 auto;
          }
          &:first-child{
            width: 10%;
          }
          &:nth-child(2){
            width: 15%;
          }
          &:nth-child(3){
            width: 15%;
          }
          &:nth-child(4){
            width: 25%;
          }
          &:nth-child(5){
            width: 15%;
          }
          &:last-child{
            width: 20%;
          }
          .arrows{
            display: flex;
            margin: 5px 10px;
            cursor: pointer;
            img{
              height: 15px;
              margin-right: 3px;
              opacity: 0.1;
              fill: #1A1A1A;
            }
            .active_arrow {
              opacity: 0.5;
            }
          }
        }
        &:nth-child(even){
          background-color: rgba(0, 0, 0, 0.05);
        }



      }
      .card_items_row.companies{
        display: flex;
        border-top: 1px solid #e3e6f0;
        color: #858796;
        font-size:16px;
        font-weight:800;
        justify-content: flex-start;
        padding: 12px 6px ;
        & > div{
          display: flex;
          align-items: flex-end;
          width: 10%;
          justify-content: space-around;
          text-align: center;
          .table_div{
            margin: 0 auto;
          }
          &:first-child{
            width: 20%;
          }
          &:nth-child(2){
            width: 30%;
          }
          &:nth-child(3){
            width: 20%;
          }
          &:last-child{
            width: 30%;
          }
          .arrows{
            display: flex;
            margin: 5px 10px;
            cursor: pointer;
            img{
              height: 15px;
              margin-right: 3px;
              opacity: 0.1;
              fill: #1A1A1A;
            }
            .active_arrow {
              opacity: 0.5;
            }
          }
        }
        &:nth-child(even){
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
      .default_row.companies{
        & > div{
          &:nth-child(3){
            display: flex;
            justify-content: center!important;
            .table_circle {
              height: 15px;
              width: 15px;
              background-color: #bbbbbb;
              border-radius: 50%;
              margin-right: 11px;
            }
            .active {
              background-color: green;
            }
          }
        }
      }

.default_row.lawyers div:nth-child(4){
    display: flex;
    flex-direction: column;

}

      .default_row {
        font-weight: 400!important;
        align-items: center;
        & > div{
          justify-content: center!important;
          align-items: center!important;
          &:nth-child(5){
            display: flex;
            justify-content: center!important;
            .table_circle {
              height: 15px;
              width: 15px;
              background-color: #bbbbbb;
              border-radius: 50%;
              margin-right: 11px;
            }
            .active {
              background-color: green;
            }
          }
        }
      }
      .header_row {
        background-color: #FFFFFF!important;
      }
    }
    .form_select{
      display: block;
      padding: 0.375rem 0.75rem;
      margin-bottom: 16px;
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #6e707e;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #d1d3e2;
      border-radius: 0.35rem;
      box-sizing: border-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin-top: 8px;
      -webkit-appearance: none;
      -moz-appearance: none;
      &:focus{
        border-color: #bac8f3;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
      }
    }

  }
  .blocks_wrapper{
    display: flex;
    flex-direction: row;
    .profile_block:first-child{
      width: 100%;
      margin-right: 24px;
      .collapse_form{
        width: 100%;
      }
    }
    .profile_block:last-child{
      width: 100%;
      .collapse_form{
        width: 100%;
      }
    }
  }

  .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 0 16px ;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796;
    a{
      color: #4e73df;
      text-decoration: none;
    }
  }
  .add_form {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid rgb(227, 230, 240);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #858796;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-bottom-left-radius: 5.6px;
    border-bottom-right-radius: 5.6px;


    .add_form_city{
      display: flex;
      flex-direction: row;
      .names{
        width: calc(25%);
        margin-left: 10px;
      }
      .names.first{
        width: calc(50% + 10px);
        margin-left: 0;
      }

    }
    .block-input-mail {
      span.error{
        top: -10px;
      }
    }
    .block-input-pass {
      width: calc(50% - 5px);
      span.error{
        top: -25px;
      }
    }

    .add_form_names{
      display: flex;
      flex-direction: row;
      .names{
        width: 100%;
      }
      div:first-child{
        margin-right: 10px;
      }
    }

    .add_input{
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #6e707e;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #d1d3e2;
      border-radius: 0.35rem;
      box-sizing: border-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin-top: 8px;
      margin-bottom: 16px;
      &::placeholder{
        color: #858796;
      }
      &:focus{
        border-color: #bac8f3;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
      }
    }
    button {
      color: #fff;
      width: fit-content;
      background-color: #1cc88a;
      border-color: #1cc88a;
      cursor: pointer;
      padding: 6px 12px;
      font-size: 16px;
      line-height: 1.5;
      border-radius: 5.6px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      text-align: center;
      vertical-align: middle;
      margin-top: 16px;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
      }
      &:hover{
        background-color: #17a673;

      }
      &:active{
        background-color: #169b6b;
        border-color: #149063;
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .user_profile_wrapper .blocks_wrapper{
    flex-direction: column;
    .profile_block:first-child{
       .collapse_form{
         width: 50%;
       }
     }
    .profile_block:last-child{
      .collapse_form{
        width: 50%;
      }
    }
  }
}