.seo-edit_wrapper {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid rgb(227, 230, 240);
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #858796;
    margin: 32px 23px;

    .seo-edit_title {
        background: rgba(0, 0, 0, 0.03);
        padding: 11px 20px;
        display: flex;
        a {
            display: flex;
            margin-left: 8px;
        }
    }
    .tabs {
        margin: 32px 32px 10px;
        button {
            background-color: transparent;
            border: 1px solid;
            border-color: #e3e6f0;
            padding: 11px 23px;
            color: #858796;
            cursor: pointer;
            &:first-child {
                border-radius: 6px 0 0 6px;
                border-right: none;
            }
            &:last-child {
                border-radius: 0 6px 6px 0;
                border-left: none;
            }
            &.active {
                background-color: #4e73df;
                border-color: #4e73df;
                color: #ffffff;
            }
            &.error {
                border-color: red;
            }
        }
    }
    form {
        .form_wrapper {
            margin: 10px 32px 40px;
            display: none;
            flex-direction: column;
            justify-content: space-between;
            &.visible {
                display: flex;
                flex-direction: row;
            }
            .home_fields {
                width: calc(50% - 20px);
            }
            .meta_fields {
                width: calc(50% - 20px);
            }
            .seo_field {
                display: flex;
                flex-direction: column;
                margin-bottom: 16px;

                span {
                    font-size: 16px;
                    line-height: 22px;
                    color: #858796;
                    margin-bottom: 8px;
                }
                input {
                    font-size: 16px;
                    padding: 8px 13px;
                    border-radius: 6px;
                    width: 100%;
                }
                textarea {
                    &::-webkit-scrollbar {
                        width: 5px;
                        height: 5px;
                    }
                    &::-webkit-scrollbar-track {
                        -webkit-border-radius: 2px;

                        background: #edf2f8;
                    }
                    &::-webkit-scrollbar-thumb {
                        -webkit-border-radius: 5px;
                        border-radius: 5px;
                        background: #4e73df;
                    }
                    &::-webkit-scrollbar-thumb:window-inactive {
                        background: #4e73df;
                    }
                }
                .description {
                    border: 1px solid rgba(0, 0, 0, 0.87);
                    resize: none;
                    &.error {
                        border-color: red;
                    }
                }

                .MuiInputAdornment-positionEnd {
                    margin-left: 0;
                }

                .MuiInputAdornment-root {
                    position: absolute;
                    top: -20px;
                    right: 6px;
                }

                fieldset {
                    top: -10px;
                }
            }
        }
        .seo-save-btn-wrapper {
            padding: 20px 32px;
            border-top: 1px solid #d1d3e2;
            button {
                background: rgb(97, 217, 173);
                border-radius: 6px;
                padding: 10px 12px;
                color: #ffffff;
                width: 60px;
                height: 36px;
                cursor: pointer;
                &:hover {
                    background: rgb(75, 187, 146);
                }
                &:disabled {
                    cursor: auto;
                    user-select: none;
                    pointer-events: none;
                }
            }
        }
    }
    .btn_loader {
        width: 23px;
        height: 23px;
        border-radius: 50%;
        position: relative;
        animation: rotate 1s linear infinite;
        bottom: 17px;
        display: inline-table;
    }
    .btn_loader::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 5px solid #fff;
        animation: prixClipFix 2s linear infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes prixClipFix {
        0% {
            clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        }
        25% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
        }
        50% {
            clip-path: polygon(
                50% 50%,
                0 0,
                100% 0,
                100% 100%,
                100% 100%,
                100% 100%
            );
        }
        75% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
        }
        100% {
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        }
    }
}
