.header_wrapper {
  height: 54px;
  background-color: #FFFFFF;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  display: flex;
  align-items: center;
  padding: 8px 32px;
  justify-content: space-between;
  margin-bottom: 24px;
  .search_form{
    max-width: 400px;
    width: 100%;
    display: flex;
  }
  .search_input{
    background-color: #f8f9fc;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    color: #6e707e;
    width: 100%;
    height: 38px;
    padding: 0 12px;
    box-sizing: border-box;
    letter-spacing: normal;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }
 }
  .search_button{
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    background-color: #4e73df;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    z-index: 10;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }
    svg{
      height: 14px;
      path{
        fill: #FFFFFF;
      }
    }
  }

  .media_wrapper{
    display: flex;
    height: 38px;
    align-items: center;
    .alerts_dropdown{
      width: 320px;
      position: absolute;
      .alerts_title{
        padding: 12px 24px;
        color: #FFFFFF;
        font-size: 10.4px;
        background-color: #4e73df;
        width: 100%;
        display: block;
      }
    }
    .notification, .message {
      position: relative;
      background-color: #FFFFFF;
      width: 20px;
      height: 16px;
      margin: 0 16px;
      cursor: pointer;
      svg {
        height: 16.8px;
        path{
          fill: #d1d3e2;;
        }
        &:hover{
          path{
            fill: #b7b9cc;
          }
        }
      }
    }

    .profile_wrapper{
      margin-left: 16px;
      border-left: 1px solid #d1d3e2;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 28px;
      color: #858796;
      font-size: 13px;
      cursor: pointer;
      position: relative;
      .menu{
        z-index: 10;
        position: absolute;
        right: -10px;
        top: 57px;
        padding: 8px 0;
        background: #fff;
        border: 1px solid #EDEDED;
        width: 160px;
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
        border-radius: 5.6px;
        >a{
          padding: 4px 24px;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 13.6px;
          color:  #3a3b45;
          &:hover{
            background-color: #f8f9fc;
          }
          &:focus{
            color: #fff;
            background-color: #4e73df;
          }
          .svg{
            width: 14.88px;
            margin-right: 8px;
          }
          svg{
            height: 12px;
            path{
              fill: #d1d3e2;
            }
          }
        }

        hr{
          height: 1px;
          display: flex;
          width: 100%;
          background: rgba(151, 151, 151, 0.2);
          margin: 8px 0;
        }
        button{
          padding: 4px 24px;
          width: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 13.6px;
          color:  #3a3b45;
          background: transparent;
          &:hover{
            background-color: #f8f9fc;
          }
          &:focus{
            color: #fff;
            background-color: #4e73df;
          }
          .svg{
            width: 14.88px;
            margin-right: 8px;
          }
          svg{
            height: 12px;
            path{
              fill: #d1d3e2;
            }
          }
        }
      }
      .box_photo{
        margin-left: 8px;
        width: 32px;
        height: 32px;
        background: #d1d3e2;
        border-radius: 50%;
      }
    }
  }

  }