.data_card_wrapper {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 24px 24px 24px;
  background-color: #FFFFFF;
  .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #5a5c69;
    font-weight: 800;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0;
  }
  .card_body {
    padding: 20px;
    .card_select_row {
      display: flex;
      align-items: center;
      span {
        color: #858796;
        margin: 0 15px 0 0;
        &:first-of-type {
          margin-right: 10px;
        }
        &:last-of-type {
          margin-left: 10px;
        }
      }
      .form_select{
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        &:focus{
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        }
      }
    }
    .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px;

      .search_form{
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px;
      }
      .search_input{
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal;
      }
    }
    .card_controls{
      display: flex;
    }
    .card_table{
      overflow: auto;
    }
    .card_items_row.row{
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size:16px;
      font-weight:800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px ;
      & > div{
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center;
        .table_div{
          margin: 0 auto;
        }
        &:first-child{
          width: 9%;
        }
        &:nth-child(2){
          width: 9%;
        }
        &:nth-child(3){
          width: 9%;
          text-align: center;
        }
        &:nth-child(4){
          width: 9%;
        }
        &:nth-child(5){
          width: 9%;
        }
        &:nth-child(6){
          width: 9%;
        }
        &:nth-child(7){
          width: 9%;
        }
        &:nth-child(8){
          width: 9%;
        }
        &:nth-child(9){
          width: 9%;
        }
        &:nth-child(10){
          width: 9%;
        }
        &:last-child{
          width: 10%;
        }
        .arrows{
          display: flex;
          margin: 5px 10px;
          cursor: pointer;
          img{
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A;
          }
          .active_arrow {
            opacity: 0.5;
          }
        }
      }
      &:nth-child(even){
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:last-child{
        background-color: #FFFFFF;
        span{
          .arrows{
            svg{
              path{
                fill: #FFFFFF;
              }
            }
          }
        }
      }
    }
    .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0;
      }
      .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .header_row {
      background-color: #FFFFFF!important;
    }
    .footer_row {
      background-color: #FFFFFF!important;
      align-items: flex-start;
    }
    .default_row.row {
      font-weight: 400!important;
      & > div{
        justify-content: center!important;
        align-items: center!important;
        &:nth-child(3){
           text-transform: uppercase;
         }
        &:nth-child(5){
          justify-content: center!important;
        }
        &:nth-child(11){
          .table_circle {
            height: 15px;
            width: 15px;
            background-color: #bbbbbb;
            border-radius: 50%;
            margin-right: 11px;
          }
          .active {
            background-color: green;
          }
        }
      }
    }
  }
}