@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap);
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre, progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%; }

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block; }

b, strong {
  font-weight: bold; }

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

li {
  display: list-item;
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th, td, caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

sub, sup, small {
  font-size: 75%; }

sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg {
  overflow: hidden; }

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none; }

.clear:after, .clear:before {
  clear: both;
  display: block;
  zoom: 1;
  content: ''; }

body {
  max-height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif; }
  body::-webkit-scrollbar {
    width: 10px;
    height: 10px; }
  body::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #EDF2F8; }
  body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #4e73df; }
  body::-webkit-scrollbar-thumb:window-inactive {
    background: #4e73df; }

a {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-decoration: none; }

button {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }

h1 {
  font-size: 32px;
  line-height: 30px; }

.back_error {
  color: #D0021B;
  font-size: 10px;
  line-height: 13px;
  top: 12px;
  right: 0;
  position: absolute;
  text-align: end;
  font-family: "GothaProMed",sans-serif; }

.error_sign_up {
  margin: -25px 0 12px; }

.text_back_error_pos_abs {
  position: absolute;
  bottom: -15px; }

.pos_rel {
  position: relative; }

.text_red {
  color: #D0021B; }

.text_green {
  color: #2CC197; }

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(92, 230, 205, 0.02); }

input {
  font-family: 'Nunito Sans', sans-serif; }

button {
  font-family: 'Nunito Sans', sans-serif; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type='number'] {
  -moz-appearance: textfield; }

.green_button {
  cursor: pointer;
  background-color: #1cc88a;
  font-family: 'Nunito Sans', sans-serif;
  border-color: #1cc88a;
  color: #FFFFFF;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  border-radius: 0.35rem;
  width: 175px;
  height: 50px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0 15px 16px 39px; }
  .green_button:hover {
    background-color: #17a673;
    border-color: #169b6b; }
  .green_button:focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
  .green_button:active {
    background-color: #169b6b;
    border-color: #149063; }

.main_wrapper {
  background-color: #f8f9fc;
  display: flex;
  box-sizing: border-box;
  min-height: 100vh; }
  .main_wrapper .main_content {
    width: calc(100% - 224px);
    display: flex;
    flex-flow: column;
    justify-content: space-between; }

.relative_position {
  position: relative; }

.no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer; }

.small_br {
  height: 10px; }

.Unactive {
  color: #5a5c69 !important; }

.Data {
  color: #f6c23e !important; }

.Active {
  color: #1cc88a !important; }

@media only screen and (max-width: 1680px) {
  .media_error {
    top: -37px !important;
    max-width: 46px; } }

.page-animation-enter {
  opacity: 0.01; }

.page-animation-enter.page-animation-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in; }

.page-animation-leave {
  opacity: 1; }

.page-animation-leave.page-animation-leave-active {
  opacity: 0.01;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

.page-animation-appear {
  opacity: 0.01; }

.page-animation-appear.page-animation-appear-active {
  opacity: 1;
  -webkit-transition: all 1s ease;
  transition: all 1s ease; }

.default_block {
  width: 100%; }

.select_component {
  height: 100%; }
  .select_component ~ .date_wrapper {
    margin-left: 20px; }
  .select_component .select__control--is-focused {
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    border: 1px solid #bac8f3;
    outline: 0; }
    .select_component .select__control--is-focused .indicator_active svg {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .select_component .select__control {
    height: 100%;
    color: #6e707e !important;
    font-family: "Nunito Sans", sans-serif !important;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem; }
    .select_component .select__control:hover {
      cursor: pointer;
      border: 1px solid #EDEDED; }
    .select_component .select__control .select__placeholder {
      color: #6e707e;
      opacity: 0.5; }
    .select_component .select__control .select__single-value {
      font-size: 1rem;
      color: #6e707e; }
    .select_component .select__control .select__input {
      font-size: 1rem;
      color: #6e707e;
      font-family: 'Nunito Sans', sans-serif; }
  .select_component .select__menu {
    z-index: 9999;
    margin: 0;
    position: unset; }
    .select_component .select__menu .select__menu-list {
      border-radius: 0.35rem;
      padding-top: 0;
      font-family: 'Nunito Sans', sans-serif;
      color: #6e707e;
      background: transparent; }
      .select_component .select__menu .select__menu-list::-webkit-scrollbar {
        width: 5px;
        height: 10px; }
      .select_component .select__menu .select__menu-list::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #EDF2F8; }
      .select_component .select__menu .select__menu-list::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #4e73df; }
      .select_component .select__menu .select__menu-list::-webkit-scrollbar-thumb:window-inactive {
        background: #4e73df; }
      .select_component .select__menu .select__menu-list .select__option {
        padding: 0 12px;
        background: transparent;
        color: #6e707e;
        cursor: pointer; }
        .select_component .select__menu .select__menu-list .select__option:hover {
          background-color: #4e73df;
          color: #FFFFFF; }
      .select_component .select__menu .select__menu-list .select__option--is-selected {
        color: #FFFFFF;
        background-color: #4e73df; }
      .select_component .select__menu .select__menu-list .status_select {
        display: flex;
        align-items: center;
        background: transparent; }
        .select_component .select__menu .select__menu-list .status_select:hover {
          background: transparent; }
      .select_component .select__menu .select__menu-list .select__menu-notice--no-options {
        padding: 0 12px;
        text-align: left;
        color: #6e707e; }

.state_selector {
  margin: 8px 0; }

.select_wrapper.multi {
  width: 100%;
  margin-top: 8px;
  max-height: 38px !important; }
  .select_wrapper.multi .multi_select_component .css-yk16xz-control {
    width: 100%;
    height: 38px; }
  .select_wrapper.multi .multi_select_component .css-1pahdxg-control {
    height: 38px;
    max-height: 38px; }
  .select_wrapper.multi .multi_select_component .css-tlfecz-indicatorContainer {
    padding: 0; }
  .select_wrapper.multi .multi_select_component .css-1gtu0rj-indicatorContainer {
    padding: 0px !important; }
  .select_wrapper.multi .multi_select_component .css-1hwfws3 {
    flex-wrap: nowrap;
    max-height: 38px;
    height: 38px; }

.summary_table {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 24px; }
  .summary_table:nth-last-child {
    margin-right: 0; }
  .summary_table .data_card {
    width: 100%;
    min-height: 60px;
    padding: 20px;
    border-left: 0.25rem solid #1cc88a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 5.6px;
    margin: 0 24px 8px 0;
    align-items: center; }
    .summary_table .data_card .card_text {
      display: flex;
      flex-direction: column; }
      .summary_table .data_card .card_text .card_title {
        margin-bottom: 4px;
        font-weight: 800;
        text-transform: uppercase;
        color: #1cc88a;
        font-size: 11px; }
      .summary_table .data_card .card_text .card_amount {
        color: #5a5c69;
        font-weight: 700;
        font-size: 20px; }
  .summary_table .data_card.no_data {
    border-left: 0.25rem solid #f6c23e; }
    .summary_table .data_card.no_data img {
      height: 32px; }
    .summary_table .data_card.no_data .card_title {
      color: #f6c23e !important; }
  .summary_table .data_card.unactive {
    border-left: 0.25rem solid #5a5c69; }
    .summary_table .data_card.unactive .card_title {
      color: #5a5c69 !important; }
  .summary_table .data_card.active_without_lawyer {
    border-left: 0.25rem solid #4e73df; }
    .summary_table .data_card.active_without_lawyer .card_title {
      color: #4e73df !important; }
  .summary_table img {
    width: 32px; }

.default_dialog_root .paper_custom_dialog {
  min-width: 840px;
  width: 100%;
  box-shadow: none;
  border-radius: 0; }

.default_dialog_root .dialog_close_button {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 30px;
  background: transparent;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  .default_dialog_root .dialog_close_button:hover {
    background: transparent; }
    .default_dialog_root .dialog_close_button:hover svg {
      opacity: 1; }

.header_dialog .MuiDialog-container {
  height: 100% !important; }
  .header_dialog .MuiDialog-container .paper_custom_dialog {
    min-width: 500px;
    border-radius: 5.6px; }
    .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper {
      color: #858796;
      box-sizing: border-box;
      padding: 0; }
      .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button {
        right: 20px;
        top: 25px; }
        .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button svg {
          height: 15px;
          opacity: 0.3; }
          .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button svg:hover {
            opacity: 1; }
          .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button svg path {
            fill: #1A1A1A; }
      .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_header {
        padding: 16px;
        border-bottom: 1px solid #e3e6f0;
        font-size: 20px; }
      .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_body {
        padding: 16px;
        border-bottom: 1px solid #e3e6f0;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #858796;
        text-align: left; }
      .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px; }
        .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button {
          background-color: #858796;
          cursor: pointer;
          padding: 9px 12px 6px;
          line-height: 24px;
          font-size: 16px;
          color: #FFFFFF;
          border-radius: 5.6px;
          font-family: "Nunito Sans";
          margin-right: 8px;
          -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
          .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:focus {
            box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5); }
          .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:active {
            background-color: #6b6d7d;
            border-color: #656776; }
          .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:hover {
            background-color: #717384; }
        .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:nth-child(2) {
          line-height: 24px;
          font-size: 16px;
          border-radius: 5.6px;
          color: #FFFFFF;
          padding: 9px 12px 6px;
          background-color: #4e73df; }
          .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:nth-child(2):hover {
            background-color: #2e59d9;
            border-color: #2653d4; }
          .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:nth-child(2):active {
            background-color: #2653d4;
            border-color: #244ec9; }
          .header_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:nth-child(2):focus {
            box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }

.delete_dialog .MuiDialog-container {
  height: 100% !important; }
  .delete_dialog .MuiDialog-container .paper_custom_dialog {
    min-width: 500px;
    border-radius: 5.6px; }
    .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper {
      color: #858796;
      box-sizing: border-box;
      padding: 0; }
      .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button {
        right: 20px;
        top: 25px; }
        .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button svg {
          height: 15px;
          opacity: 0.3; }
          .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button svg:hover {
            opacity: 1; }
          .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button svg path {
            fill: #1A1A1A; }
      .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_header {
        padding: 16px;
        border-bottom: 1px solid #e3e6f0;
        font-size: 20px; }
      .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_body {
        padding: 16px;
        border-bottom: 1px solid #e3e6f0;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #858796;
        text-align: left; }
      .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px; }
        .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button {
          cursor: pointer;
          padding: 9px 12px 6px;
          line-height: 24px;
          font-size: 16px;
          color: #FFFFFF;
          border-radius: 5.6px;
          font-family: "Nunito Sans";
          margin-right: 8px;
          -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
          .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child {
            background-color: #e74a3b; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child:focus {
              box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5); }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child:active {
              background-color: #d52a1a;
              border-color: #ca2819; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child:hover {
              background-color: #e02d1b; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child:disabled {
              opacity: .5; }
          .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child.add_btn {
            background-color: #1cc88a;
            border-color: #1cc88a; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child.add_btn:disabled {
              opacity: .5; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child.add_btn:focus {
              box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child.add_btn:hover {
              background-color: #17a673;
              border-color: #169b6b; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child.add_btn:active {
              background-color: #169b6b;
              border-color: #149063; }
          .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child {
            background-color: #4e73df; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child:focus {
              box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child:active {
              background-color: #2653d4;
              border-color: #244ec9; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child:hover {
              background-color: #2e59d9;
              border-color: #2653d4; }
          .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child.cancel_btn {
            background-color: #4e73df; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child.cancel_btn:focus {
              box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child.cancel_btn:active {
              background-color: #2653d4;
              border-color: #244ec9; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child.cancel_btn:hover {
              background-color: #2e59d9;
              border-color: #2653d4; }
      .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer.profile_trash {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px; }
        .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer.profile_trash button {
          cursor: pointer;
          padding: 9px 12px 6px;
          line-height: 24px;
          font-size: 16px;
          color: #FFFFFF;
          border-radius: 5.6px;
          font-family: "Nunito Sans";
          margin-right: 8px;
          -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
          .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer.profile_trash button:first-child {
            background-color: #e74a3b; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer.profile_trash button:first-child:focus {
              box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5); }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer.profile_trash button:first-child:active {
              background-color: #d52a1a;
              border-color: #ca2819; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer.profile_trash button:first-child:hover {
              background-color: #e02d1b; }
          .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer.profile_trash button:last-child {
            background-color: #4e73df; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer.profile_trash button:last-child:focus {
              box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer.profile_trash button:last-child:active {
              background-color: #2653d4;
              border-color: #244ec9; }
            .delete_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer.profile_trash button:last-child:hover {
              background-color: #2e59d9;
              border-color: #2653d4; }

.disconnect_dialog .MuiDialog-container {
  height: 100% !important; }
  .disconnect_dialog .MuiDialog-container .paper_custom_dialog {
    min-width: 500px;
    border-radius: 5.6px; }
    .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper {
      color: #858796;
      box-sizing: border-box;
      padding: 0; }
      .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button {
        right: 20px;
        top: 25px; }
        .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button svg {
          height: 15px;
          opacity: 0.3; }
          .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button svg:hover {
            opacity: 1; }
          .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_close_button svg path {
            fill: #1A1A1A; }
      .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_header {
        padding: 16px;
        border-bottom: 1px solid #e3e6f0;
        font-size: 20px; }
      .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_body {
        padding: 16px;
        border-bottom: 1px solid #e3e6f0;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #858796;
        text-align: left; }
      .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px; }
        .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button {
          cursor: pointer;
          padding: 9px 12px 6px;
          line-height: 24px;
          font-size: 16px;
          color: #FFFFFF;
          border-radius: 5.6px;
          font-family: "Nunito Sans";
          margin-right: 8px;
          -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
          .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child {
            background-color: #f6c23e;
            border-color: #f6c23e; }
            .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child:focus {
              box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5); }
            .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child:active {
              background-color: #f4b30d;
              border-color: #e9aa0b; }
            .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:first-child:hover {
              background-color: #f4b619;
              border-color: #f4b30d; }
          .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child {
            background-color: #4e73df; }
            .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child:focus {
              box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
            .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child:active {
              background-color: #2653d4;
              border-color: #244ec9; }
            .disconnect_dialog .MuiDialog-container .paper_custom_dialog .dialog_wrapper .dialog_footer button:last-child:hover {
              background-color: #2e59d9;
              border-color: #2653d4; }

.dialog_wrapper {
  padding: 40px 60px 30px 60px;
  -moz-margin-end: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  background-color: #FFFFFF;
  max-height: 850px;
  overflow: auto; }
  .dialog_wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .dialog_wrapper::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #EDF2F8; }
  .dialog_wrapper::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #5CE6CD; }
  .dialog_wrapper::-webkit-scrollbar-thumb:window-inactive {
    background: #5CE6CD; }

.control_btn {
  border-radius: 0.35rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 40px;
  height: 38px;
  cursor: pointer;
  margin-right: 3px !important;
  display: flex;
  align-items: center;
  justify-content: center; }
  .control_btn img {
    width: 17px;
    height: 16px; }

.trash {
  background-color: #e74a3b;
  border-color: #e74a3b; }
  .trash:focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5); }
  .trash:hover {
    background-color: #e02d1b;
    border-color: #d52a1a; }
  .trash:disabled {
    opacity: .5; }

.change {
  background-color: #4e73df;
  border-color: #4e73df; }
  .change:focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
  .change:hover {
    background-color: #2e59d9;
    border-color: #2653d4; }

.link {
  background-color: #36b9cc;
  border-color: #36b9cc; }
  .link:active {
    box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5); }
  .link:hover {
    background-color: #2c9faf;
    border-color: #2a96a5; }

.disconnect {
  background-color: #f6c23e;
  border-color: #f6c23e; }
  .disconnect svg {
    width: 17px; }
    .disconnect svg path {
      fill: #FFFFFF; }
  .disconnect:active {
    background-color: #f4b30d;
    border-color: #e9aa0b;
    box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5); }
  .disconnect:hover {
    background-color: #f4b619;
    border-color: #f4b30d; }

.companies_data_card {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 24px 24px 24px;
  background-color: #FFFFFF; }
  .companies_data_card .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #5a5c69;
    font-weight: 800;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0; }
  .companies_data_card .card_body {
    padding: 20px; }
    .companies_data_card .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .companies_data_card .card_body .card_select_row span {
        color: #858796; }
        .companies_data_card .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .companies_data_card .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .companies_data_card .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .companies_data_card .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .companies_data_card .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .companies_data_card .card_body .card_search_row span {
        color: #858796; }
      .companies_data_card .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .companies_data_card .card_body .card_search_row .search_input {
        font-family: "Nunito Sans", sans-serif;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .companies_data_card .card_body .card_controls {
      display: flex; }
    .companies_data_card .card_body .card_table {
      overflow: auto; }
    .companies_data_card .card_body .card_items_row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .companies_data_card .card_body .card_items_row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .companies_data_card .card_body .card_items_row > div .table_div {
          margin: 0 auto; }
        .companies_data_card .card_body .card_items_row > div:first-child {
          width: 10%; }
        .companies_data_card .card_body .card_items_row > div:nth-child(2) {
          width: 10%; }
        .companies_data_card .card_body .card_items_row > div:nth-child(3) {
          width: 10%;
          text-align: center; }
        .companies_data_card .card_body .card_items_row > div:nth-child(4) {
          width: 10%; }
        .companies_data_card .card_body .card_items_row > div:nth-child(5) {
          width: 10%; }
        .companies_data_card .card_body .card_items_row > div:nth-child(6) {
          width: 10%; }
        .companies_data_card .card_body .card_items_row > div:nth-child(7) {
          width: 10%; }
        .companies_data_card .card_body .card_items_row > div:nth-child(8) {
          width: 10%; }
        .companies_data_card .card_body .card_items_row > div:nth-child(9) {
          width: 10%; }
        .companies_data_card .card_body .card_items_row > div:last-child {
          width: 10%; }
        .companies_data_card .card_body .card_items_row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .companies_data_card .card_body .card_items_row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .companies_data_card .card_body .card_items_row > div .arrows .active_arrow {
            opacity: 0.5; }
      .companies_data_card .card_body .card_items_row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .companies_data_card .card_body .card_items_row:last-child {
        background-color: #FFFFFF; }
        .companies_data_card .card_body .card_items_row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .companies_data_card .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .companies_data_card .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .companies_data_card .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .companies_data_card .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .companies_data_card .card_body .header_row {
      background-color: #FFFFFF !important; }
    .companies_data_card .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .companies_data_card .card_body .default_row {
      font-weight: 400 !important; }
      .companies_data_card .card_body .default_row > div {
        justify-content: center !important;
        align-items: center !important; }
        .companies_data_card .card_body .default_row > div:nth-child(9) {
          justify-content: left !important; }
          .companies_data_card .card_body .default_row > div:nth-child(9) .table_circle {
            height: 15px;
            width: 15px;
            background-color: #bbbbbb;
            border-radius: 50%;
            margin-right: 11px; }
          .companies_data_card .card_body .default_row > div:nth-child(9) .active {
            background-color: green; }

.block-input-pass {
  width: 100%;
  position: relative; }
  .block-input-pass .error {
    display: none; }
  .block-input-pass.error_border, .block-input-pass.value.error_border {
    position: relative; }
    .block-input-pass.error_border .error, .block-input-pass.value.error_border .error {
      display: block;
      color: #d0021b;
      position: absolute;
      right: 0;
      font-size: 10px;
      line-height: 13px; }

.block-input-mail {
  display: block;
  width: 100%;
  position: relative; }
  .block-input-mail.error_border, .block-input-mail.value.error_border {
    position: relative; }
    .block-input-mail.error_border span.error, .block-input-mail.value.error_border span.error {
      color: #d0021b;
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 10px;
      line-height: 13px;
      display: block; }

.block-input-description {
  display: block;
  width: 100%;
  position: relative; }
  .block-input-description.error_border, .block-input-description.value.error_border {
    position: relative; }
    .block-input-description.error_border span.error, .block-input-description.value.error_border span.error {
      color: #d0021b;
      position: absolute;
      right: 0;
      bottom: -8px;
      left: 0;
      font-size: 9px;
      line-height: 13px;
      display: block; }

.tooltip-mui {
  font-family: inherit; }
  .tooltip-mui--error-color {
    background-color: var(--error); }
    .tooltip-mui--error-color .tooltip-mui__arrow {
      color: var(--error); }

.custom-input-wrapper.readonly .custom-input {
  background-color: rgba(235, 243, 250, 0.3); }

.custom-input-wrapper.readonly input {
  cursor: default; }

.custom-input-wrapper .custom-input-label {
  font-family: inherit;
  font-size: 10px;
  line-height: 13px;
  font-weight: 600;
  color: inherit;
  -webkit-transform: translate(15px, 17px);
          transform: translate(15px, 17px);
  -webkit-transition: 0.1s linear;
  transition: 0.1s linear; }
  .custom-input-wrapper .custom-input-label.custom-input-label-focused, .custom-input-wrapper .custom-input-label.custom-input-label-active {
    -webkit-transform: translate(0, -20px);
            transform: translate(0, -20px);
    color: inherit; }
  .custom-input-wrapper .custom-input-label.custom-input-label-error {
    color: inherit; }

.custom-input-wrapper .custom-input {
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  background-color: var(--white);
  border: 1px solid rgba(32, 69, 105, 0.3);
  border-radius: 4px;
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear; }
  .custom-input-wrapper .custom-input:hover {
    border-color: rgba(32, 69, 105, 0.3); }
  .custom-input-wrapper .custom-input input,
  .custom-input-wrapper .custom-input textarea:not([aria-hidden]) {
    box-sizing: border-box; }
    .custom-input-wrapper .custom-input input::-webkit-input-placeholder, .custom-input-wrapper .custom-input textarea:not([aria-hidden])::-webkit-input-placeholder {
      color: var(--primary);
      opacity: 0.5; }
    .custom-input-wrapper .custom-input input::-moz-placeholder, .custom-input-wrapper .custom-input textarea:not([aria-hidden])::-moz-placeholder {
      color: var(--primary);
      opacity: 0.5; }
    .custom-input-wrapper .custom-input input::-ms-input-placeholder, .custom-input-wrapper .custom-input textarea:not([aria-hidden])::-ms-input-placeholder {
      color: var(--primary);
      opacity: 0.5; }
    .custom-input-wrapper .custom-input input::placeholder,
    .custom-input-wrapper .custom-input textarea:not([aria-hidden])::placeholder {
      color: var(--primary);
      opacity: 0.5; }
  .custom-input-wrapper .custom-input input {
    padding: 15px;
    height: 48px; }
  .custom-input-wrapper .custom-input textarea:not([aria-hidden]) {
    min-height: calc(100px - 39px); }
  .custom-input-wrapper .custom-input.custom-input-focused {
    border-color: #204569; }
  .custom-input-wrapper .custom-input.custom-input-error, .custom-input-wrapper .custom-input.custom-input-error:hover {
    border-color: var(--error); }
  .custom-input-wrapper .custom-input .custom-input-outline {
    display: none; }

.custom-input-wrapper .custom-input-disabled {
  background: var(--light);
  border-color: var(--light);
  cursor: not-allowed; }
  .custom-input-wrapper .custom-input-disabled:hover {
    border-color: var(--light); }

.custom-input-wrapper .error-text {
  position: absolute;
  bottom: -17px;
  left: 0;
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-family: inherit;
  font-size: 10px;
  line-height: 13px;
  font-weight: 600;
  color: var(--error); }

.custom-input-wrapper .symbol {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 24px;
  height: 24px; }

.input-mui__label {
  font-family: inherit; }
  .input-mui__label.input-mui__label--focused {
    color: var(--info); }

.input-mui__input {
  font-family: inherit; }
  .input-mui__input.input-mui__input--focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: var(--info); }

.input-mui__helper-text {
  position: absolute;
  bottom: -20px;
  font-family: inherit; }

.input-mui__error-icon {
  cursor: pointer; }

.input-mui .MuiFilledInput-root.MuiInput-underline:hover:before {
  border-color: var(--info); }

.input-mui .MuiFilledInput-underline:after,
.input-mui .MuiInput-underline:after,
.input-mui .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid var(--info); }

.seo-edit_wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #858796;
  margin: 32px 23px; }
  .seo-edit_wrapper .seo-edit_title {
    background: rgba(0, 0, 0, 0.03);
    padding: 11px 20px;
    display: flex; }
    .seo-edit_wrapper .seo-edit_title a {
      display: flex;
      margin-left: 8px; }
  .seo-edit_wrapper .tabs {
    margin: 32px 32px 10px; }
    .seo-edit_wrapper .tabs button {
      background-color: transparent;
      border: 1px solid;
      border-color: #e3e6f0;
      padding: 11px 23px;
      color: #858796;
      cursor: pointer; }
      .seo-edit_wrapper .tabs button:first-child {
        border-radius: 6px 0 0 6px;
        border-right: none; }
      .seo-edit_wrapper .tabs button:last-child {
        border-radius: 0 6px 6px 0;
        border-left: none; }
      .seo-edit_wrapper .tabs button.active {
        background-color: #4e73df;
        border-color: #4e73df;
        color: #ffffff; }
      .seo-edit_wrapper .tabs button.error {
        border-color: red; }
  .seo-edit_wrapper form .form_wrapper {
    margin: 10px 32px 40px;
    display: none;
    flex-direction: column;
    justify-content: space-between; }
    .seo-edit_wrapper form .form_wrapper.visible {
      display: flex;
      flex-direction: row; }
    .seo-edit_wrapper form .form_wrapper .home_fields {
      width: calc(50% - 20px); }
    .seo-edit_wrapper form .form_wrapper .meta_fields {
      width: calc(50% - 20px); }
    .seo-edit_wrapper form .form_wrapper .seo_field {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px; }
      .seo-edit_wrapper form .form_wrapper .seo_field span {
        font-size: 16px;
        line-height: 22px;
        color: #858796;
        margin-bottom: 8px; }
      .seo-edit_wrapper form .form_wrapper .seo_field input {
        font-size: 16px;
        padding: 8px 13px;
        border-radius: 6px;
        width: 100%; }
      .seo-edit_wrapper form .form_wrapper .seo_field textarea::-webkit-scrollbar {
        width: 5px;
        height: 5px; }
      .seo-edit_wrapper form .form_wrapper .seo_field textarea::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #edf2f8; }
      .seo-edit_wrapper form .form_wrapper .seo_field textarea::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #4e73df; }
      .seo-edit_wrapper form .form_wrapper .seo_field textarea::-webkit-scrollbar-thumb:window-inactive {
        background: #4e73df; }
      .seo-edit_wrapper form .form_wrapper .seo_field .description {
        border: 1px solid rgba(0, 0, 0, 0.87);
        resize: none; }
        .seo-edit_wrapper form .form_wrapper .seo_field .description.error {
          border-color: red; }
      .seo-edit_wrapper form .form_wrapper .seo_field .MuiInputAdornment-positionEnd {
        margin-left: 0; }
      .seo-edit_wrapper form .form_wrapper .seo_field .MuiInputAdornment-root {
        position: absolute;
        top: -20px;
        right: 6px; }
      .seo-edit_wrapper form .form_wrapper .seo_field fieldset {
        top: -10px; }
  .seo-edit_wrapper form .seo-save-btn-wrapper {
    padding: 20px 32px;
    border-top: 1px solid #d1d3e2; }
    .seo-edit_wrapper form .seo-save-btn-wrapper button {
      background: #61d9ad;
      border-radius: 6px;
      padding: 10px 12px;
      color: #ffffff;
      width: 60px;
      height: 36px;
      cursor: pointer; }
      .seo-edit_wrapper form .seo-save-btn-wrapper button:hover {
        background: #4bbb92; }
      .seo-edit_wrapper form .seo-save-btn-wrapper button:disabled {
        cursor: auto;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        pointer-events: none; }
  .seo-edit_wrapper .btn_loader {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    position: relative;
    -webkit-animation: rotate 1s linear infinite;
            animation: rotate 1s linear infinite;
    bottom: 17px;
    display: inline-table; }
  .seo-edit_wrapper .btn_loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #fff;
    -webkit-animation: prixClipFix 2s linear infinite;
            animation: prixClipFix 2s linear infinite; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes prixClipFix {
  0% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
            clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0); }
  25% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0); }
  50% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%); }
  75% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%); }
  100% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0); } }

@keyframes prixClipFix {
  0% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
            clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0); }
  25% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0); }
  50% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%); }
  75% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%); }
  100% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0); } }

.companies_wrapper .breadcrumbs {
  display: flex;
  padding: 12px 16px;
  margin: 0 24px 16px;
  list-style: none;
  background-color: #eaecf4;
  border-radius: 0.35rem;
  color: #858796; }
  .companies_wrapper .breadcrumbs a {
    color: #4e73df;
    text-decoration: none; }

.companies_wrapper .forms_items {
  margin: 0 24px 24px 24px;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  cursor: pointer; }
  .companies_wrapper .forms_items .choose_form {
    border-bottom: 1px solid #d1d3e2; }
    .companies_wrapper .forms_items .choose_form .seo-edit_wrapper {
      margin: 0;
      border: 0;
      border-top: 1px solid #d1d3e2;
      border-radius: 0; }
    .companies_wrapper .forms_items .choose_form .forms_title {
      padding: 17px;
      display: flex;
      justify-content: space-between; }
      .companies_wrapper .forms_items .choose_form .forms_title img {
        -webkit-transition: 0.4s;
        transition: 0.4s;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
    .companies_wrapper .forms_items .choose_form:last-child {
      border-bottom: unset; }
    .companies_wrapper .forms_items .choose_form.clicked .forms_title img {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

.companies_wrapper .data_wrapper {
  display: flex; }
  .companies_wrapper .data_wrapper .data_card {
    width: 100%;
    margin-right: 24px;
    margin-bottom: 24px; }
  .companies_wrapper .data_wrapper .active {
    margin-left: 24px; }
    .companies_wrapper .data_wrapper .active .card_title {
      color: #1cc88a; }
  .companies_wrapper .data_wrapper .no_data .card_title {
    color: #f6c23e; }
  .companies_wrapper .data_wrapper .unactive .card_title {
    color: #5a5c69; }

.companies_wrapper .add_form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  border-radius: 5.6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #858796;
  width: auto;
  box-sizing: border-box;
  margin: 0 24px 16px 24px; }
  .companies_wrapper .add_form .MuiFormControl-root {
    display: flex; }
  .companies_wrapper .add_form .add_form-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 32px 40px; }
    .companies_wrapper .add_form .add_form-wrapper .create_info {
      width: calc(50% - 20px); }
    .companies_wrapper .add_form .add_form-wrapper .add_form_seo {
      width: calc(50% - 20px); }
    .companies_wrapper .add_form .add_form-wrapper .input-mui__error-icon {
      position: absolute;
      top: -20px;
      right: 5px; }
    .companies_wrapper .add_form .add_form-wrapper .add_form_seo .seo_form {
      display: none; }
      .companies_wrapper .add_form .add_form-wrapper .add_form_seo .seo_form.visible {
        display: flex;
        flex-direction: column; }
    .companies_wrapper .add_form .add_form-wrapper .add_form_seo .tabs {
      display: flex;
      margin: 0px 0px 10px; }
      .companies_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn {
        background-color: transparent;
        border: 1px solid;
        border-color: #e3e6f0;
        padding: 11px 23px;
        color: #858796;
        cursor: pointer;
        font-size: 16px;
        width: auto; }
        .companies_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn:first-child {
          border-radius: 6px 0 0 6px;
          border-right: none; }
        .companies_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn:last-child {
          border-radius: 0 6px 6px 0;
          border-left: none; }
        .companies_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn.active {
          background-color: #4e73df;
          border-color: #4e73df;
          color: #ffffff; }
        .companies_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn.error {
          border-color: red; }
  .companies_wrapper .add_form .add_form-button-wrapper {
    padding: 20px 32px;
    border-top: 1px solid #d1d3e2; }
  .companies_wrapper .add_form .form_select {
    margin-bottom: 10px;
    display: block;
    width: 50%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer; }
    .companies_wrapper .add_form .form_select:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .companies_wrapper .add_form .block-input-mail {
    margin-bottom: 10px; }
    .companies_wrapper .add_form .block-input-mail span.error {
      top: -10px; }
  .companies_wrapper .add_form .block-input-pass {
    margin-bottom: 10px; }
    .companies_wrapper .add_form .block-input-pass span.error {
      top: -25px; }
  .companies_wrapper .add_form .add_form_city {
    display: flex;
    flex-direction: row; }
    .companies_wrapper .add_form .add_form_city .names {
      width: calc(25% - 10px);
      margin-left: 10px;
      position: relative; }
    .companies_wrapper .add_form .add_form_city .names.first {
      width: 50%;
      margin-left: 0; }
  .companies_wrapper .add_form .add_input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px; }
    .companies_wrapper .add_form .add_input:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .companies_wrapper .add_form .add_description {
    font-family: 'Nunito Sans', sans-serif;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    margin-bottom: 16px;
    resize: none; }
    .companies_wrapper .add_form .add_description::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .companies_wrapper .add_form .add_description::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #edf2f8; }
    .companies_wrapper .add_form .add_description::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #4e73df; }
    .companies_wrapper .add_form .add_description::-webkit-scrollbar-thumb:window-inactive {
      background: #4e73df; }
    .companies_wrapper .add_form .add_description:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .companies_wrapper .add_form button {
    color: #fff;
    background-color: #4e73df;
    border-color: #4e73df;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.35rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    min-height: 38px;
    width: 200px; }
    .companies_wrapper .add_form button:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
    .companies_wrapper .add_form button:hover {
      background-color: #2e59d9;
      border-color: #2653d4; }
    .companies_wrapper .add_form button:active {
      background-color: #2653d4;
      border-color: #244ec9; }

.companies_wrapper .data_card_wrapper .card_body .card_items_row span {
  text-align: center;
  width: 10% !important; }

.companies_wrapper .data_card_wrapper .card_body .card_items_row.user span:nth-child(11) {
  display: none; }

.companies_wrapper .data_card_wrapper .card_body .card_items_row.user span:nth-child(12) {
  display: none; }

.header_wrapper {
  height: 54px;
  background-color: #FFFFFF;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  display: flex;
  align-items: center;
  padding: 8px 32px;
  justify-content: space-between;
  margin-bottom: 24px; }
  .header_wrapper .search_form {
    max-width: 400px;
    width: 100%;
    display: flex; }
  .header_wrapper .search_input {
    background-color: #f8f9fc;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    color: #6e707e;
    width: 100%;
    height: 38px;
    padding: 0 12px;
    box-sizing: border-box;
    letter-spacing: normal;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .header_wrapper .search_input:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .header_wrapper .search_button {
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    background-color: #4e73df;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    z-index: 10;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .header_wrapper .search_button:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .header_wrapper .search_button svg {
      height: 14px; }
      .header_wrapper .search_button svg path {
        fill: #FFFFFF; }
  .header_wrapper .media_wrapper {
    display: flex;
    height: 38px;
    align-items: center; }
    .header_wrapper .media_wrapper .alerts_dropdown {
      width: 320px;
      position: absolute; }
      .header_wrapper .media_wrapper .alerts_dropdown .alerts_title {
        padding: 12px 24px;
        color: #FFFFFF;
        font-size: 10.4px;
        background-color: #4e73df;
        width: 100%;
        display: block; }
    .header_wrapper .media_wrapper .notification, .header_wrapper .media_wrapper .message {
      position: relative;
      background-color: #FFFFFF;
      width: 20px;
      height: 16px;
      margin: 0 16px;
      cursor: pointer; }
      .header_wrapper .media_wrapper .notification svg, .header_wrapper .media_wrapper .message svg {
        height: 16.8px; }
        .header_wrapper .media_wrapper .notification svg path, .header_wrapper .media_wrapper .message svg path {
          fill: #d1d3e2; }
        .header_wrapper .media_wrapper .notification svg:hover path, .header_wrapper .media_wrapper .message svg:hover path {
          fill: #b7b9cc; }
    .header_wrapper .media_wrapper .profile_wrapper {
      margin-left: 16px;
      border-left: 1px solid #d1d3e2;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 28px;
      color: #858796;
      font-size: 13px;
      cursor: pointer;
      position: relative; }
      .header_wrapper .media_wrapper .profile_wrapper .menu {
        z-index: 10;
        position: absolute;
        right: -10px;
        top: 57px;
        padding: 8px 0;
        background: #fff;
        border: 1px solid #EDEDED;
        width: 160px;
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
        border-radius: 5.6px; }
        .header_wrapper .media_wrapper .profile_wrapper .menu > a {
          padding: 4px 24px;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 13.6px;
          color: #3a3b45; }
          .header_wrapper .media_wrapper .profile_wrapper .menu > a:hover {
            background-color: #f8f9fc; }
          .header_wrapper .media_wrapper .profile_wrapper .menu > a:focus {
            color: #fff;
            background-color: #4e73df; }
          .header_wrapper .media_wrapper .profile_wrapper .menu > a .svg {
            width: 14.88px;
            margin-right: 8px; }
          .header_wrapper .media_wrapper .profile_wrapper .menu > a svg {
            height: 12px; }
            .header_wrapper .media_wrapper .profile_wrapper .menu > a svg path {
              fill: #d1d3e2; }
        .header_wrapper .media_wrapper .profile_wrapper .menu hr {
          height: 1px;
          display: flex;
          width: 100%;
          background: rgba(151, 151, 151, 0.2);
          margin: 8px 0; }
        .header_wrapper .media_wrapper .profile_wrapper .menu button {
          padding: 4px 24px;
          width: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 13.6px;
          color: #3a3b45;
          background: transparent; }
          .header_wrapper .media_wrapper .profile_wrapper .menu button:hover {
            background-color: #f8f9fc; }
          .header_wrapper .media_wrapper .profile_wrapper .menu button:focus {
            color: #fff;
            background-color: #4e73df; }
          .header_wrapper .media_wrapper .profile_wrapper .menu button .svg {
            width: 14.88px;
            margin-right: 8px; }
          .header_wrapper .media_wrapper .profile_wrapper .menu button svg {
            height: 12px; }
            .header_wrapper .media_wrapper .profile_wrapper .menu button svg path {
              fill: #d1d3e2; }
      .header_wrapper .media_wrapper .profile_wrapper .box_photo {
        margin-left: 8px;
        width: 32px;
        height: 32px;
        background: #d1d3e2;
        border-radius: 50%; }

.sidebar_wrapper {
  background-color: #4e73df;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #4e73df), to(#224abe));
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  background-size: cover;
  min-width: 224px; }
  .sidebar_wrapper .sidebar {
    color: rgba(255, 255, 255, 0.4);
    padding: 0 16px; }
  .sidebar_wrapper .sidebar_tab.lawthor {
    height: 69px;
    align-items: center;
    padding-top: 0;
    display: flex;
    justify-content: space-around; }
    .sidebar_wrapper .sidebar_tab.lawthor img {
      height: 32px;
      margin-right: 16px; }
    .sidebar_wrapper .sidebar_tab.lawthor a {
      font-size: 16px;
      font-weight: 900;
      color: #ffffff;
      display: flex;
      padding-right: 15px; }
  .sidebar_wrapper .sidebar_tab.dashboard {
    padding-top: 0; }
  .sidebar_wrapper .sidebar_tab {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    text-transform: uppercase;
    font-size: 10.5px;
    font-weight: 900;
    padding-top: 16px; }
    .sidebar_wrapper .sidebar_tab .active {
      font-weight: 900;
      color: white; }
      .sidebar_wrapper .sidebar_tab .active path {
        fill: #ffffff; }
    .sidebar_wrapper .sidebar_tab svg {
      height: 13.6px;
      margin-right: 8px; }
      .sidebar_wrapper .sidebar_tab svg path {
        fill: rgba(255, 255, 255, 0.3); }
  .sidebar_wrapper a {
    text-decoration: none;
    padding: 16px 15px 16px 0;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    text-transform: none;
    font-weight: 400;
    display: flex;
    align-items: center; }
    .sidebar_wrapper a:hover {
      color: #ffffff; }
      .sidebar_wrapper a:hover path {
        fill: #ffffff; }

.data_wrapper {
  margin-right: 24px;
  width: 100%; }
  .data_wrapper:nth-last-child {
    margin-right: 0; }
  .data_wrapper .data_title {
    color: #4e73df;
    font-size: 20px;
    margin-bottom: 16px;
    display: block; }
  .data_wrapper .data_card {
    padding: 20px;
    border-left: 0.25rem solid #1cc88a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 5.6px;
    margin-bottom: 8px;
    align-items: center; }
    .data_wrapper .data_card .card_text {
      display: flex;
      flex-direction: column; }
      .data_wrapper .data_card .card_text .card_title {
        margin-bottom: 4px;
        font-weight: 800;
        text-transform: uppercase;
        color: #4e73df;
        font-size: 11px; }
      .data_wrapper .data_card .card_text .card_amount {
        font-weight: 700;
        font-size: 20px; }
  .data_wrapper .data_card.no_data {
    border-left: 0.25rem solid #f6c23e; }
    .data_wrapper .data_card.no_data img {
      height: 32px; }
  .data_wrapper .data_card.unactive {
    border-left: 0.25rem solid #5a5c69; }
  .data_wrapper img {
    width: 32px; }

.dashboard_wrapper {
  background-color: #f8f9fc;
  display: flex;
  box-sizing: border-box;
  padding: 0 24px;
  flex-direction: column; }
  .dashboard_wrapper .top_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px; }
    .dashboard_wrapper .top_wrapper .title {
      color: #5a5c69;
      font-size: 28px; }
    .dashboard_wrapper .top_wrapper .report {
      background-color: #4e73df;
      border-radius: 3px;
      box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2);
      font-size: 14px;
      line-height: 1.5;
      height: 31px;
      box-sizing: border-box;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      cursor: pointer; }
  .dashboard_wrapper .row {
    display: flex;
    flex-direction: row; }
    .dashboard_wrapper .row .data_wrapper:last-child {
      margin-right: 0; }

.footer_wrapper {
  padding: 32px 0;
  text-align: center;
  font-size: 12.8px;
  background-color: #FFFFFF;
  color: #858796;
  font-family: 'Nunito Sans', sans-serif; }

.summary_table {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 24px; }
  .summary_table:nth-last-child {
    margin-right: 0; }
  .summary_table .data_card {
    width: 100%;
    min-height: 60px;
    padding: 20px;
    border-left: 0.25rem solid #1cc88a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 5.6px;
    margin: 0 24px 8px 0;
    align-items: center; }
    .summary_table .data_card .card_text {
      display: flex;
      flex-direction: column; }
      .summary_table .data_card .card_text .card_title {
        margin-bottom: 4px;
        font-weight: 800;
        text-transform: uppercase;
        color: #1cc88a;
        font-size: 11px; }
      .summary_table .data_card .card_text .card_amount {
        color: #5a5c69;
        font-weight: 700;
        font-size: 20px; }
  .summary_table .data_card.no_data {
    border-left: 0.25rem solid #f6c23e; }
    .summary_table .data_card.no_data img {
      height: 32px; }
    .summary_table .data_card.no_data .card_title {
      color: #f6c23e !important; }
  .summary_table .data_card.unactive {
    border-left: 0.25rem solid #5a5c69; }
    .summary_table .data_card.unactive .card_title {
      color: #5a5c69 !important; }
  .summary_table .data_card.active_without_lawyer {
    border-left: 0.25rem solid #4e73df; }
    .summary_table .data_card.active_without_lawyer .card_title {
      color: #4e73df !important; }
  .summary_table img {
    width: 32px; }

.data_card_wrapper1 {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 24px 24px 24px;
  background-color: #FFFFFF; }
  .data_card_wrapper1 .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #5a5c69;
    font-weight: 800;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0; }
  .data_card_wrapper1 .card_body {
    padding: 20px; }
    .data_card_wrapper1 .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .data_card_wrapper1 .card_body .card_select_row span {
        color: #858796;
        margin: 0 15px 0 0; }
        .data_card_wrapper1 .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .data_card_wrapper1 .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .data_card_wrapper1 .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .data_card_wrapper1 .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .data_card_wrapper1 .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .data_card_wrapper1 .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .data_card_wrapper1 .card_body .card_search_row .search_input {
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .data_card_wrapper1 .card_body .card_controls {
      display: flex; }
    .data_card_wrapper1 .card_body .card_table {
      overflow: auto; }
    .data_card_wrapper1 .card_body .card_items_row.row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .data_card_wrapper1 .card_body .card_items_row.row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .data_card_wrapper1 .card_body .card_items_row.row > div .table_div {
          margin: 0 auto; }
        .data_card_wrapper1 .card_body .card_items_row.row > div:first-child {
          width: 9%; }
        .data_card_wrapper1 .card_body .card_items_row.row > div:nth-child(2) {
          width: 9%; }
        .data_card_wrapper1 .card_body .card_items_row.row > div:nth-child(3) {
          width: 9%;
          text-align: center; }
        .data_card_wrapper1 .card_body .card_items_row.row > div:nth-child(4) {
          width: 9%; }
        .data_card_wrapper1 .card_body .card_items_row.row > div:nth-child(5) {
          width: 9%; }
        .data_card_wrapper1 .card_body .card_items_row.row > div:nth-child(6) {
          width: 9%; }
        .data_card_wrapper1 .card_body .card_items_row.row > div:nth-child(7) {
          width: 9%; }
        .data_card_wrapper1 .card_body .card_items_row.row > div:nth-child(8) {
          width: 9%; }
        .data_card_wrapper1 .card_body .card_items_row.row > div:nth-child(9) {
          width: 9%; }
        .data_card_wrapper1 .card_body .card_items_row.row > div:last-child {
          width: 10%; }
        .data_card_wrapper1 .card_body .card_items_row.row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .data_card_wrapper1 .card_body .card_items_row.row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .data_card_wrapper1 .card_body .card_items_row.row > div .arrows .active_arrow {
            opacity: 0.5; }
      .data_card_wrapper1 .card_body .card_items_row.row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .data_card_wrapper1 .card_body .card_items_row.row:last-child {
        background-color: #FFFFFF; }
        .data_card_wrapper1 .card_body .card_items_row.row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .data_card_wrapper1 .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .data_card_wrapper1 .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .data_card_wrapper1 .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .data_card_wrapper1 .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .data_card_wrapper1 .card_body .header_row {
      background-color: #FFFFFF !important; }
    .data_card_wrapper1 .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .data_card_wrapper1 .card_body .default_row.row {
      font-weight: 400 !important; }
      .data_card_wrapper1 .card_body .default_row.row > div {
        justify-content: center !important;
        align-items: center !important; }
        .data_card_wrapper1 .card_body .default_row.row > div:nth-child(3) {
          text-transform: uppercase; }
        .data_card_wrapper1 .card_body .default_row.row > div:nth-child(5) {
          justify-content: center !important; }
        .data_card_wrapper1 .card_body .default_row.row > div:nth-child(10) .table_circle {
          height: 15px;
          width: 15px;
          background-color: #bbbbbb;
          border-radius: 50%;
          margin-right: 11px; }
        .data_card_wrapper1 .card_body .default_row.row > div:nth-child(10) .active {
          background-color: green; }

.lawyers_wrapper .breadcrumbs {
  display: flex;
  padding: 12px 16px;
  margin: 0 24px 16px;
  list-style: none;
  background-color: #eaecf4;
  border-radius: 0.35rem;
  color: #858796; }
  .lawyers_wrapper .breadcrumbs a {
    color: #4e73df;
    text-decoration: none; }

.lawyers_wrapper .forms_items {
  margin: 0 24px 24px 24px;
  border: 1px solid #d1d3e2;
  cursor: pointer;
  border-radius: 0.35rem; }
  .lawyers_wrapper .forms_items .choose_form {
    border-bottom: 1px solid #d1d3e2; }
    .lawyers_wrapper .forms_items .choose_form .seo-edit_wrapper {
      margin: 0;
      border: 0;
      border-top: 1px solid #d1d3e2;
      border-radius: 0; }
    .lawyers_wrapper .forms_items .choose_form .forms_title {
      padding: 17px;
      display: flex;
      justify-content: space-between; }
      .lawyers_wrapper .forms_items .choose_form .forms_title img {
        -webkit-transition: 0.4s;
        transition: 0.4s;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
    .lawyers_wrapper .forms_items .choose_form:last-child {
      border-bottom: unset; }
    .lawyers_wrapper .forms_items .choose_form.clicked .forms_title img {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

.lawyers_wrapper .data_wrapper {
  display: flex; }
  .lawyers_wrapper .data_wrapper .data_card {
    width: 100%;
    margin-right: 24px;
    margin-bottom: 24px; }
  .lawyers_wrapper .data_wrapper .active {
    margin-left: 24px; }
    .lawyers_wrapper .data_wrapper .active .card_title {
      color: #1cc88a; }
  .lawyers_wrapper .data_wrapper .no_data .card_title {
    color: #f6c23e; }
  .lawyers_wrapper .data_wrapper .unactive .card_title {
    color: #5a5c69; }

.lawyers_wrapper .add_form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  border-radius: 5.6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #858796;
  width: auto;
  box-sizing: border-box;
  margin: 0 24px 16px 24px; }
  .lawyers_wrapper .add_form .MuiFormControl-root {
    display: flex; }
  .lawyers_wrapper .add_form .add_form-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 32px 40px; }
    .lawyers_wrapper .add_form .add_form-wrapper .create_info {
      width: calc(50% - 20px); }
    .lawyers_wrapper .add_form .add_form-wrapper .add_form_seo {
      width: calc(50% - 20px); }
    .lawyers_wrapper .add_form .add_form-wrapper .input-mui__error-icon {
      position: absolute;
      top: -20px;
      right: 5px; }
    .lawyers_wrapper .add_form .add_form-wrapper .add_form_seo .seo_form {
      display: none; }
      .lawyers_wrapper .add_form .add_form-wrapper .add_form_seo .seo_form.visible {
        display: flex;
        flex-direction: column; }
    .lawyers_wrapper .add_form .add_form-wrapper .add_form_seo .tabs {
      margin: 0px 0px 10px;
      display: flex; }
      .lawyers_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn {
        background-color: transparent;
        border: 1px solid;
        border-color: #e3e6f0;
        padding: 11px 23px;
        color: #858796;
        cursor: pointer;
        font-size: 16px;
        width: auto; }
        .lawyers_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn:first-child {
          border-radius: 6px 0 0 6px;
          border-right: none; }
        .lawyers_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn:last-child {
          border-radius: 0 6px 6px 0;
          border-left: none; }
        .lawyers_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn.active {
          background-color: #4e73df;
          border-color: #4e73df;
          color: #ffffff; }
        .lawyers_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn.error {
          border-color: red; }
  .lawyers_wrapper .add_form .add_form-button-wrapper {
    padding: 20px 32px;
    border-top: 1px solid #d1d3e2; }
    .lawyers_wrapper .add_form .add_form-button-wrapper button {
      margin-top: 0; }
  .lawyers_wrapper .add_form .form_select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .lawyers_wrapper .add_form .form_select:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .lawyers_wrapper .add_form .block-input-mail span.error {
    top: -10px; }
  .lawyers_wrapper .add_form .block-input-pass span.error {
    top: -25px; }
  .lawyers_wrapper .add_form .add_form_names {
    display: flex;
    flex-direction: row; }
    .lawyers_wrapper .add_form .add_form_names .names {
      width: 100%; }
    .lawyers_wrapper .add_form .add_form_names div:first-child {
      margin-right: 10px; }
  .lawyers_wrapper .add_form .add_input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px; }
    .lawyers_wrapper .add_form .add_input:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .lawyers_wrapper .add_form .add_description {
    font-family: 'Nunito Sans', sans-serif;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    margin-bottom: 16px;
    resize: none; }
    .lawyers_wrapper .add_form .add_description::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .lawyers_wrapper .add_form .add_description::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #edf2f8; }
    .lawyers_wrapper .add_form .add_description::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #4e73df; }
    .lawyers_wrapper .add_form .add_description::-webkit-scrollbar-thumb:window-inactive {
      background: #4e73df; }
    .lawyers_wrapper .add_form .add_description:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .lawyers_wrapper .add_form button {
    color: #fff;
    background-color: #4e73df;
    border-color: #4e73df;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.35rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px; }
    .lawyers_wrapper .add_form button:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
    .lawyers_wrapper .add_form button:hover {
      background-color: #2e59d9;
      border-color: #2653d4; }
    .lawyers_wrapper .add_form button:active {
      background-color: #2653d4;
      border-color: #244ec9; }

.lawyers_wrapper .data_card_wrapper .card_body .card_items_row span {
  text-align: center;
  width: 10% !important; }

@media only screen and (max-width: 1000px) {
  .add_form_names .names span.error {
    width: 50px;
    top: -35px !important;
    right: -10px; } }

.data_card_wrapper {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 24px 24px 24px;
  background-color: #FFFFFF; }
  .data_card_wrapper .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #5a5c69;
    font-weight: 800;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0; }
  .data_card_wrapper .card_body {
    padding: 20px; }
    .data_card_wrapper .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .data_card_wrapper .card_body .card_select_row span {
        color: #858796;
        margin: 0 15px 0 0; }
        .data_card_wrapper .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .data_card_wrapper .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .data_card_wrapper .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .data_card_wrapper .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .data_card_wrapper .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .data_card_wrapper .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .data_card_wrapper .card_body .card_search_row .search_input {
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .data_card_wrapper .card_body .card_controls {
      display: flex; }
    .data_card_wrapper .card_body .card_table {
      overflow: auto; }
    .data_card_wrapper .card_body .card_items_row.row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .data_card_wrapper .card_body .card_items_row.row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .data_card_wrapper .card_body .card_items_row.row > div .table_div {
          margin: 0 auto; }
        .data_card_wrapper .card_body .card_items_row.row > div:first-child {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(2) {
          width: 8%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(3) {
          width: 9%;
          text-align: center; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(4) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(5) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(6) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(7) {
          width: 8%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(8) {
          width: 10%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(9) {
          width: 8%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(10) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:last-child {
          width: 10%; }
        .data_card_wrapper .card_body .card_items_row.row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .data_card_wrapper .card_body .card_items_row.row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .data_card_wrapper .card_body .card_items_row.row > div .arrows .active_arrow {
            opacity: 0.5; }
      .data_card_wrapper .card_body .card_items_row.row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .data_card_wrapper .card_body .card_items_row.row:last-child {
        background-color: #FFFFFF; }
        .data_card_wrapper .card_body .card_items_row.row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .data_card_wrapper .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .data_card_wrapper .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .data_card_wrapper .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .data_card_wrapper .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .data_card_wrapper .card_body .header_row {
      background-color: #FFFFFF !important; }
    .data_card_wrapper .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .data_card_wrapper .card_body .default_row.row {
      font-weight: 400 !important; }
      .data_card_wrapper .card_body .default_row.row > div {
        justify-content: center !important;
        align-items: center !important; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(2) {
          text-transform: uppercase; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(5) {
          justify-content: center !important; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(10) .table_circle {
          height: 15px;
          width: 15px;
          background-color: #bbbbbb;
          border-radius: 50%;
          margin-right: 11px; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(10) .active {
          background-color: green; }

.summary_table {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 24px; }
  .summary_table:nth-last-child {
    margin-right: 0; }
  .summary_table .data_card {
    width: 100%;
    min-height: 60px;
    padding: 20px;
    border-left: 0.25rem solid #1cc88a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 5.6px;
    margin: 0 24px 8px 0;
    align-items: center; }
    .summary_table .data_card .card_text {
      display: flex;
      flex-direction: column; }
      .summary_table .data_card .card_text .card_title {
        margin-bottom: 4px;
        font-weight: 800;
        text-transform: uppercase;
        color: #1cc88a;
        font-size: 11px; }
      .summary_table .data_card .card_text .card_amount {
        color: #5a5c69;
        font-weight: 700;
        font-size: 20px; }
  .summary_table .data_card.no_data {
    border-left: 0.25rem solid #f6c23e; }
    .summary_table .data_card.no_data img {
      height: 32px; }
    .summary_table .data_card.no_data .card_title {
      color: #f6c23e !important; }
  .summary_table .data_card.unactive {
    border-left: 0.25rem solid #5a5c69; }
    .summary_table .data_card.unactive .card_title {
      color: #5a5c69 !important; }
  .summary_table .data_card.active_without_lawyer {
    border-left: 0.25rem solid #4e73df; }
    .summary_table .data_card.active_without_lawyer .card_title {
      color: #4e73df !important; }
  .summary_table img {
    width: 32px; }

.categories_wrapper .breadcrumbs {
  display: flex;
  padding: 12px 16px;
  margin: 0 24px 16px;
  list-style: none;
  background-color: #eaecf4;
  border-radius: 0.35rem;
  color: #858796; }
  .categories_wrapper .breadcrumbs a {
    color: #4e73df;
    text-decoration: none; }

.categories_wrapper .forms_items {
  margin: 0 24px 24px 24px;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  cursor: pointer; }
  .categories_wrapper .forms_items .choose_form {
    border-bottom: 1px solid #d1d3e2; }
    .categories_wrapper .forms_items .choose_form .seo-edit_wrapper {
      margin: 0;
      border: 0;
      border-top: 1px solid #d1d3e2;
      border-radius: 0; }
    .categories_wrapper .forms_items .choose_form .forms_title {
      padding: 17px;
      display: flex;
      justify-content: space-between; }
      .categories_wrapper .forms_items .choose_form .forms_title img {
        -webkit-transition: 0.4s;
        transition: 0.4s;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
    .categories_wrapper .forms_items .choose_form:last-child {
      border-bottom: unset; }
    .categories_wrapper .forms_items .choose_form.clicked .forms_title img {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

.categories_wrapper .add_form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  border-radius: 5.6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #858796;
  width: auto;
  box-sizing: border-box;
  margin: 0 24px 16px 24px; }
  .categories_wrapper .add_form .relative_position span:first-child {
    border-radius: 50%;
    margin-right: 5px; }
  .categories_wrapper .add_form .add_input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    margin-bottom: 16px; }
    .categories_wrapper .add_form .add_input:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .categories_wrapper .add_form .MuiFormControl-root {
    display: flex; }
  .categories_wrapper .add_form .add_form-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 32px 40px; }
    .categories_wrapper .add_form .add_form-wrapper .create_info {
      width: calc(50% - 20px); }
    .categories_wrapper .add_form .add_form-wrapper .input-mui__error-icon {
      position: absolute;
      top: -20px;
      right: 5px; }
    .categories_wrapper .add_form .add_form-wrapper .add_form_seo {
      width: calc(50% - 20px); }
      .categories_wrapper .add_form .add_form-wrapper .add_form_seo .seo_form {
        display: none; }
        .categories_wrapper .add_form .add_form-wrapper .add_form_seo .seo_form.visible {
          display: flex;
          flex-direction: column; }
      .categories_wrapper .add_form .add_form-wrapper .add_form_seo .tabs {
        margin: 0px 0px 10px;
        display: flex; }
        .categories_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn {
          background-color: transparent;
          border: 1px solid;
          border-color: #e3e6f0;
          padding: 11px 23px;
          color: #858796;
          cursor: pointer;
          font-size: 16px;
          width: auto; }
          .categories_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn:first-child {
            border-radius: 6px 0 0 6px;
            border-right: none; }
          .categories_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn:last-child {
            border-radius: 0 6px 6px 0;
            border-left: none; }
          .categories_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn.active {
            background-color: #4e73df;
            border-color: #4e73df;
            color: #ffffff; }
          .categories_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn.error {
            border-color: red; }
  .categories_wrapper .add_form .add_form-button-wrapper {
    padding: 20px 32px;
    border-top: 1px solid #d1d3e2; }
  .categories_wrapper .add_form .add_description {
    font-family: 'Nunito Sans', sans-serif;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    margin-bottom: 16px;
    resize: none; }
    .categories_wrapper .add_form .add_description::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .categories_wrapper .add_form .add_description::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #edf2f8; }
    .categories_wrapper .add_form .add_description::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #4e73df; }
    .categories_wrapper .add_form .add_description::-webkit-scrollbar-thumb:window-inactive {
      background: #4e73df; }
    .categories_wrapper .add_form .add_description:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .categories_wrapper .add_form button {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.35rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px;
    min-height: 38px; }
    .categories_wrapper .add_form button:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .categories_wrapper .add_form button:hover {
      background-color: #17a673;
      border-color: #169b6b; }
    .categories_wrapper .add_form button:active {
      background-color: #169b6b;
      border-color: #149063; }
  .categories_wrapper .add_form .level_option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%; }
    .categories_wrapper .add_form .level_option label {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 24px;
      text-transform: uppercase; }
      .categories_wrapper .add_form .level_option label input {
        margin-right: 5px; }
      .categories_wrapper .add_form .level_option label span {
        border-radius: 5px; }
      .categories_wrapper .add_form .level_option label .at {
        color: purple;
        border: 1px solid purple; }
      .categories_wrapper .add_form .level_option label .ma {
        color: blue;
        border: 1px solid blue; }
      .categories_wrapper .add_form .level_option label .ba {
        color: green;
        border: 1px solid green; }
    .categories_wrapper .add_form .level_option .back_error {
      top: 14px;
      right: -39px;
      font-family: 'Nunito Sans', sans-serif; }

.categories_wrapper .data_card_wrapper .card_body .card_items_row span {
  text-align: center;
  width: 10% !important; }

.categories_wrapper .MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6 {
  background-color: #1cc88a; }

.categories_wrapper .MuiSnackbar-root.MuiSnackbar-anchorOriginTopLeft {
  top: 270px;
  left: 500px; }

.data_card_wrapper {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 24px 24px 24px;
  background-color: #FFFFFF; }
  .data_card_wrapper .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #5a5c69;
    font-weight: 800;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0; }
    .data_card_wrapper .card_title.In {
      color: #f6c23e; }
    .data_card_wrapper .card_title.Pu {
      color: #1cc88a; }
  .data_card_wrapper .card_body {
    padding: 20px; }
    .data_card_wrapper .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .data_card_wrapper .card_body .card_select_row span {
        color: #858796;
        margin: 0 15px 0 0; }
        .data_card_wrapper .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .data_card_wrapper .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .data_card_wrapper .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .data_card_wrapper .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .data_card_wrapper .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .data_card_wrapper .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .data_card_wrapper .card_body .card_search_row .search_input {
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .data_card_wrapper .card_body .card_controls {
      display: flex; }
    .data_card_wrapper .card_body .card_table {
      overflow: auto; }
    .data_card_wrapper .card_body .card_items_row.row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .data_card_wrapper .card_body .card_items_row.row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .data_card_wrapper .card_body .card_items_row.row > div .table_div {
          margin: 0 auto; }
        .data_card_wrapper .card_body .card_items_row.row > div:first-child {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(2) {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(3) {
          width: 10%;
          text-align: center; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(4) {
          width: 10%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(5) {
          width: 10%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(6) {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(7) {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row.row > div:last-child {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row.row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .data_card_wrapper .card_body .card_items_row.row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .data_card_wrapper .card_body .card_items_row.row > div .arrows .active_arrow {
            opacity: 0.5; }
      .data_card_wrapper .card_body .card_items_row.row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .data_card_wrapper .card_body .card_items_row.row:last-child {
        background-color: #FFFFFF; }
        .data_card_wrapper .card_body .card_items_row.row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .data_card_wrapper .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .data_card_wrapper .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .data_card_wrapper .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .data_card_wrapper .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .data_card_wrapper .card_body .header_row {
      background-color: #FFFFFF !important; }
    .data_card_wrapper .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .data_card_wrapper .card_body .default_row.row {
      font-weight: 400 !important; }
      .data_card_wrapper .card_body .default_row.row > div {
        justify-content: center !important;
        align-items: center !important; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(2) {
          text-transform: unset; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(5) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(7) .table_circle {
          height: 15px;
          width: 15px;
          background-color: orange;
          border-radius: 50%;
          margin-right: 11px; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(7) .active {
          background-color: green; }

.summary_table {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 24px; }
  .summary_table:nth-last-child {
    margin-right: 0; }
  .summary_table .data_card {
    width: 100%;
    min-height: 60px;
    padding: 20px;
    border-left: 0.25rem solid #1cc88a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 5.6px;
    margin: 0 24px 8px 0;
    align-items: center; }
    .summary_table .data_card .card_text {
      display: flex;
      flex-direction: column; }
      .summary_table .data_card .card_text .card_title {
        margin-bottom: 4px;
        font-weight: 800;
        text-transform: uppercase;
        color: #1cc88a;
        font-size: 11px; }
      .summary_table .data_card .card_text .card_amount {
        color: #5a5c69;
        font-weight: 700;
        font-size: 20px; }
  .summary_table .data_card.no_data {
    border-left: 0.25rem solid #f6c23e; }
    .summary_table .data_card.no_data img {
      height: 32px; }
    .summary_table .data_card.no_data .card_title {
      color: #f6c23e !important; }
  .summary_table .data_card.unactive {
    border-left: 0.25rem solid #5a5c69; }
    .summary_table .data_card.unactive .card_title {
      color: #5a5c69 !important; }
  .summary_table .data_card.active_without_lawyer {
    border-left: 0.25rem solid #4e73df; }
    .summary_table .data_card.active_without_lawyer .card_title {
      color: #4e73df !important; }
  .summary_table img {
    width: 32px; }

.publications_wrapper .breadcrumbs {
  display: flex;
  padding: 12px 16px;
  margin: 0 24px 16px;
  list-style: none;
  background-color: #eaecf4;
  border-radius: 0.35rem;
  color: #858796; }
  .publications_wrapper .breadcrumbs a {
    color: #4e73df;
    text-decoration: none; }

.publications_wrapper .forms_items {
  margin: 0 24px 24px 24px;
  border: 1px solid #d1d3e2;
  cursor: pointer;
  border-radius: 0.35rem; }
  .publications_wrapper .forms_items .choose_form {
    border-bottom: 1px solid #d1d3e2; }
    .publications_wrapper .forms_items .choose_form .seo-edit_wrapper {
      margin: 0;
      border: 0;
      border-top: 1px solid #d1d3e2;
      border-radius: 0; }
    .publications_wrapper .forms_items .choose_form .forms_title {
      padding: 17px;
      display: flex;
      justify-content: space-between; }
      .publications_wrapper .forms_items .choose_form .forms_title img {
        -webkit-transition: 0.4s;
        transition: 0.4s;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
    .publications_wrapper .forms_items .choose_form:last-child {
      border-bottom: unset; }
    .publications_wrapper .forms_items .choose_form.clicked .forms_title img {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

.publications_wrapper .add_form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  border-radius: 5.6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #858796;
  width: 100%;
  padding: 20px 32px;
  max-width: 50%;
  box-sizing: border-box;
  margin: 0 0 16px 24px; }
  .publications_wrapper .add_form .relative_position span:first-child {
    border-radius: 50%;
    margin-right: 5px; }
  .publications_wrapper .add_form .add_input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    margin-bottom: 16px; }
    .publications_wrapper .add_form .add_input:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .publications_wrapper .add_form button {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.35rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px;
    min-height: 38px; }
    .publications_wrapper .add_form button:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .publications_wrapper .add_form button:hover {
      background-color: #17a673;
      border-color: #169b6b; }
    .publications_wrapper .add_form button:active {
      background-color: #169b6b;
      border-color: #149063; }
  .publications_wrapper .add_form .level_option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%; }
    .publications_wrapper .add_form .level_option label {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 24px;
      text-transform: uppercase; }
      .publications_wrapper .add_form .level_option label input {
        margin-right: 5px; }
      .publications_wrapper .add_form .level_option label span {
        border-radius: 5px; }
      .publications_wrapper .add_form .level_option label .at {
        color: purple;
        border: 1px solid purple; }
      .publications_wrapper .add_form .level_option label .ma {
        color: blue;
        border: 1px solid blue; }
      .publications_wrapper .add_form .level_option label .ba {
        color: green;
        border: 1px solid green; }
    .publications_wrapper .add_form .level_option .back_error {
      top: 14px;
      right: -39px;
      font-family: 'Nunito Sans', sans-serif; }

.publications_wrapper .data_card_wrapper .card_body .card_items_row span {
  text-align: center;
  width: 10% !important; }

.publications_wrapper .MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6 {
  background-color: #1cc88a; }

.publications_wrapper .MuiSnackbar-root.MuiSnackbar-anchorOriginTopLeft {
  top: 270px;
  left: 500px; }

.summary_table {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 24px; }
  .summary_table:nth-last-child {
    margin-right: 0; }
  .summary_table .data_card {
    width: 100%;
    min-height: 60px;
    padding: 20px;
    border-left: 0.25rem solid #1cc88a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 5.6px;
    margin: 0 24px 8px 0;
    align-items: center; }
    .summary_table .data_card .card_text {
      display: flex;
      flex-direction: column; }
      .summary_table .data_card .card_text .card_title {
        margin-bottom: 4px;
        font-weight: 800;
        text-transform: uppercase;
        color: #1cc88a;
        font-size: 11px; }
      .summary_table .data_card .card_text .card_amount {
        color: #5a5c69;
        font-weight: 700;
        font-size: 20px; }
  .summary_table .data_card.no_data {
    border-left: 0.25rem solid #f6c23e; }
    .summary_table .data_card.no_data img {
      height: 32px; }
    .summary_table .data_card.no_data .card_title {
      color: #f6c23e !important; }
  .summary_table .data_card.unactive {
    border-left: 0.25rem solid #5a5c69; }
    .summary_table .data_card.unactive .card_title {
      color: #5a5c69 !important; }
  .summary_table .data_card.active_without_lawyer {
    border-left: 0.25rem solid #4e73df; }
    .summary_table .data_card.active_without_lawyer .card_title {
      color: #4e73df !important; }
  .summary_table img {
    width: 32px; }

.data_card_wrapper {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 24px 24px 24px;
  background-color: #FFFFFF; }
  .data_card_wrapper .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #5a5c69;
    font-weight: 800;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0; }
  .data_card_wrapper .card_body {
    padding: 20px; }
    .data_card_wrapper .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .data_card_wrapper .card_body .card_select_row span {
        color: #858796;
        margin: 0 15px 0 0; }
        .data_card_wrapper .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .data_card_wrapper .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .data_card_wrapper .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .data_card_wrapper .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .data_card_wrapper .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .data_card_wrapper .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .data_card_wrapper .card_body .card_search_row .search_input {
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .data_card_wrapper .card_body .card_controls {
      display: flex; }
    .data_card_wrapper .card_body .card_table {
      overflow: auto; }
    .data_card_wrapper .card_body .card_items_row.row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .data_card_wrapper .card_body .card_items_row.row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .data_card_wrapper .card_body .card_items_row.row > div .table_div {
          margin: 0 auto; }
        .data_card_wrapper .card_body .card_items_row.row > div:first-child {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(2) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(3) {
          width: 9%;
          text-align: center; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(4) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(5) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(6) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(7) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(8) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(9) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(10) {
          width: 9%; }
        .data_card_wrapper .card_body .card_items_row.row > div:last-child {
          width: 10%; }
        .data_card_wrapper .card_body .card_items_row.row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .data_card_wrapper .card_body .card_items_row.row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .data_card_wrapper .card_body .card_items_row.row > div .arrows .active_arrow {
            opacity: 0.5; }
      .data_card_wrapper .card_body .card_items_row.row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .data_card_wrapper .card_body .card_items_row.row:last-child {
        background-color: #FFFFFF; }
        .data_card_wrapper .card_body .card_items_row.row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .data_card_wrapper .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .data_card_wrapper .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .data_card_wrapper .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .data_card_wrapper .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .data_card_wrapper .card_body .header_row {
      background-color: #FFFFFF !important; }
    .data_card_wrapper .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .data_card_wrapper .card_body .default_row.row {
      font-weight: 400 !important; }
      .data_card_wrapper .card_body .default_row.row > div {
        justify-content: center !important;
        align-items: center !important; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(3) {
          text-transform: uppercase; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(5) {
          justify-content: center !important; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(11) .table_circle {
          height: 15px;
          width: 15px;
          background-color: #bbbbbb;
          border-radius: 50%;
          margin-right: 11px; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(11) .active {
          background-color: green; }

.services_wrapper .breadcrumbs {
  display: flex;
  padding: 12px 16px;
  margin: 0 24px 16px;
  list-style: none;
  background-color: #eaecf4;
  border-radius: 0.35rem;
  color: #858796; }
  .services_wrapper .breadcrumbs a {
    color: #4e73df;
    text-decoration: none; }

.services_wrapper .forms_items {
  margin: 0 24px 24px 24px;
  border: 1px solid #d1d3e2;
  cursor: pointer;
  border-radius: 0.35rem; }
  .services_wrapper .forms_items .choose_form {
    border-bottom: 1px solid #d1d3e2; }
    .services_wrapper .forms_items .choose_form .seo-edit_wrapper {
      margin: 0;
      border: 0;
      border-top: 1px solid #d1d3e2;
      border-radius: 0; }
    .services_wrapper .forms_items .choose_form .forms_title {
      padding: 17px;
      display: flex;
      justify-content: space-between; }
      .services_wrapper .forms_items .choose_form .forms_title img {
        -webkit-transition: 0.4s;
        transition: 0.4s;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
    .services_wrapper .forms_items .choose_form:last-child {
      border-bottom: unset; }
    .services_wrapper .forms_items .choose_form.clicked .forms_title img {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

.services_wrapper .add_form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  border-radius: 5.6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #858796;
  width: auto;
  box-sizing: border-box;
  margin: 0 24px 16px 24px; }
  .services_wrapper .add_form .relative_position span:first-child {
    border-radius: 50%;
    margin-right: 5px; }
  .services_wrapper .add_form .add_input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    margin-bottom: 16px; }
    .services_wrapper .add_form .add_input:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .services_wrapper .add_form .MuiFormControl-root {
    display: flex; }
  .services_wrapper .add_form .add_form-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 32px 40px; }
    .services_wrapper .add_form .add_form-wrapper .create_info {
      width: calc(50% - 20px); }
    .services_wrapper .add_form .add_form-wrapper .input-mui__error-icon {
      position: absolute;
      top: -20px;
      right: 5px; }
    .services_wrapper .add_form .add_form-wrapper .add_form_seo {
      width: calc(50% - 20px); }
      .services_wrapper .add_form .add_form-wrapper .add_form_seo .seo_form {
        display: none; }
        .services_wrapper .add_form .add_form-wrapper .add_form_seo .seo_form.visible {
          display: flex;
          flex-direction: column; }
      .services_wrapper .add_form .add_form-wrapper .add_form_seo .tabs {
        margin: 0px 0px 10px;
        display: flex; }
        .services_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn {
          background-color: transparent;
          border: 1px solid;
          border-color: #e3e6f0;
          padding: 11px 23px;
          color: #858796;
          cursor: pointer;
          font-size: 16px;
          width: auto; }
          .services_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn:first-child {
            border-radius: 6px 0 0 6px;
            border-right: none; }
          .services_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn:last-child {
            border-radius: 0 6px 6px 0;
            border-left: none; }
          .services_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn.active {
            background-color: #4e73df;
            border-color: #4e73df;
            color: #ffffff; }
          .services_wrapper .add_form .add_form-wrapper .add_form_seo .tabs .control_lang-btn.error {
            border-color: red; }
  .services_wrapper .add_form .add_form-button-wrapper {
    padding: 20px 32px;
    border-top: 1px solid #d1d3e2; }
    .services_wrapper .add_form .add_form-button-wrapper button {
      margin-top: 0; }
  .services_wrapper .add_form .add_description {
    font-family: 'Nunito Sans', sans-serif;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    margin-bottom: 16px;
    resize: none; }
    .services_wrapper .add_form .add_description::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
    .services_wrapper .add_form .add_description::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #edf2f8; }
    .services_wrapper .add_form .add_description::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #4e73df; }
    .services_wrapper .add_form .add_description::-webkit-scrollbar-thumb:window-inactive {
      background: #4e73df; }
    .services_wrapper .add_form .add_description:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .services_wrapper .add_form button {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.35rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px;
    min-height: 38px; }
    .services_wrapper .add_form button:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .services_wrapper .add_form button:hover {
      background-color: #17a673;
      border-color: #169b6b; }
    .services_wrapper .add_form button:active {
      background-color: #169b6b;
      border-color: #149063; }
  .services_wrapper .add_form .level_option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%; }
    .services_wrapper .add_form .level_option label {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 24px;
      text-transform: uppercase; }
      .services_wrapper .add_form .level_option label input {
        margin-right: 5px; }
      .services_wrapper .add_form .level_option label span {
        border-radius: 5px; }
      .services_wrapper .add_form .level_option label .at {
        color: purple;
        border: 1px solid purple; }
      .services_wrapper .add_form .level_option label .ma {
        color: blue;
        border: 1px solid blue; }
      .services_wrapper .add_form .level_option label .ba {
        color: green;
        border: 1px solid green; }
    .services_wrapper .add_form .level_option .back_error {
      top: 14px;
      right: -39px;
      font-family: 'Nunito Sans', sans-serif; }

.services_wrapper .data_card_wrapper .card_body .card_items_row span {
  text-align: center;
  width: 10% !important; }

.services_wrapper .MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6 {
  background-color: #1cc88a; }

.services_wrapper .MuiSnackbar-root.MuiSnackbar-anchorOriginTopLeft {
  top: 270px;
  left: 500px; }

.data_card_wrapper {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 24px 24px 24px;
  background-color: #FFFFFF; }
  .data_card_wrapper .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #5a5c69;
    font-weight: 800;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0; }
    .data_card_wrapper .card_title.In {
      color: #f6c23e; }
    .data_card_wrapper .card_title.Pu {
      color: #1cc88a; }
  .data_card_wrapper .card_body {
    padding: 20px; }
    .data_card_wrapper .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .data_card_wrapper .card_body .card_select_row span {
        color: #858796;
        margin: 0 15px 0 0; }
        .data_card_wrapper .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .data_card_wrapper .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .data_card_wrapper .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .data_card_wrapper .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .data_card_wrapper .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .data_card_wrapper .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .data_card_wrapper .card_body .card_search_row .search_input {
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .data_card_wrapper .card_body .card_controls {
      display: flex; }
    .data_card_wrapper .card_body .card_table {
      overflow: auto; }
    .data_card_wrapper .card_body .card_items_row.row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .data_card_wrapper .card_body .card_items_row.row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .data_card_wrapper .card_body .card_items_row.row > div .table_div {
          margin: 0 auto; }
        .data_card_wrapper .card_body .card_items_row.row > div:first-child {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(2) {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(3) {
          width: 10%;
          text-align: center; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(4) {
          width: 10%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(5) {
          width: 10%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(6) {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row.row > div:nth-child(7) {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row.row > div:last-child {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row.row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .data_card_wrapper .card_body .card_items_row.row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .data_card_wrapper .card_body .card_items_row.row > div .arrows .active_arrow {
            opacity: 0.5; }
      .data_card_wrapper .card_body .card_items_row.row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .data_card_wrapper .card_body .card_items_row.row:last-child {
        background-color: #FFFFFF; }
        .data_card_wrapper .card_body .card_items_row.row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .data_card_wrapper .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .data_card_wrapper .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .data_card_wrapper .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .data_card_wrapper .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .data_card_wrapper .card_body .header_row {
      background-color: #FFFFFF !important; }
    .data_card_wrapper .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .data_card_wrapper .card_body .default_row.row {
      font-weight: 400 !important; }
      .data_card_wrapper .card_body .default_row.row > div {
        justify-content: center !important;
        align-items: center !important; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(2) {
          text-transform: unset; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(5) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(6) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(7) .table_circle {
          height: 15px;
          width: 15px;
          background-color: orange;
          border-radius: 50%;
          margin-right: 11px; }
        .data_card_wrapper .card_body .default_row.row > div:nth-child(7) .active {
          background-color: green; }

.summary_table {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 24px; }
  .summary_table:nth-last-child {
    margin-right: 0; }
  .summary_table .data_card {
    width: 100%;
    min-height: 60px;
    padding: 20px;
    border-left: 0.25rem solid #1cc88a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 5.6px;
    margin: 0 24px 8px 0;
    align-items: center; }
    .summary_table .data_card .card_text {
      display: flex;
      flex-direction: column; }
      .summary_table .data_card .card_text .card_title {
        margin-bottom: 4px;
        font-weight: 800;
        text-transform: uppercase;
        color: #1cc88a;
        font-size: 11px; }
      .summary_table .data_card .card_text .card_amount {
        color: #5a5c69;
        font-weight: 700;
        font-size: 20px; }
  .summary_table .data_card.no_data {
    border-left: 0.25rem solid #f6c23e; }
    .summary_table .data_card.no_data img {
      height: 32px; }
    .summary_table .data_card.no_data .card_title {
      color: #f6c23e !important; }
  .summary_table .data_card.unactive {
    border-left: 0.25rem solid #5a5c69; }
    .summary_table .data_card.unactive .card_title {
      color: #5a5c69 !important; }
  .summary_table .data_card.active_without_lawyer {
    border-left: 0.25rem solid #4e73df; }
    .summary_table .data_card.active_without_lawyer .card_title {
      color: #4e73df !important; }
  .summary_table img {
    width: 32px; }

.news_wrapper .breadcrumbs {
  display: flex;
  padding: 12px 16px;
  margin: 0 24px 16px;
  list-style: none;
  background-color: #eaecf4;
  border-radius: 0.35rem;
  color: #858796; }
  .news_wrapper .breadcrumbs a {
    color: #4e73df;
    text-decoration: none; }

.news_wrapper .forms_items {
  margin: 0 24px 24px 24px;
  border: 1px solid #d1d3e2;
  cursor: pointer;
  border-radius: 0.35rem; }
  .news_wrapper .forms_items .choose_form {
    border-bottom: 1px solid #d1d3e2; }
    .news_wrapper .forms_items .choose_form .seo-edit_wrapper {
      margin: 0;
      border: 0;
      border-top: 1px solid #d1d3e2;
      border-radius: 0; }
    .news_wrapper .forms_items .choose_form .forms_title {
      padding: 17px;
      display: flex;
      justify-content: space-between; }
      .news_wrapper .forms_items .choose_form .forms_title img {
        -webkit-transition: 0.4s;
        transition: 0.4s;
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
    .news_wrapper .forms_items .choose_form:last-child {
      border-bottom: unset; }
    .news_wrapper .forms_items .choose_form.clicked .forms_title img {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

.news_wrapper .add_form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  border-radius: 5.6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #858796;
  width: 100%;
  padding: 20px 32px;
  max-width: 50%;
  box-sizing: border-box;
  margin: 0 0 16px 24px; }
  .news_wrapper .add_form .relative_position span:first-child {
    border-radius: 50%;
    margin-right: 5px; }
  .news_wrapper .add_form .add_input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    margin-bottom: 16px; }
    .news_wrapper .add_form .add_input:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .news_wrapper .add_form button {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.35rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px;
    min-height: 38px; }
    .news_wrapper .add_form button:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .news_wrapper .add_form button:hover {
      background-color: #17a673;
      border-color: #169b6b; }
    .news_wrapper .add_form button:active {
      background-color: #169b6b;
      border-color: #149063; }
  .news_wrapper .add_form .level_option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%; }
    .news_wrapper .add_form .level_option label {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 24px;
      text-transform: uppercase; }
      .news_wrapper .add_form .level_option label input {
        margin-right: 5px; }
      .news_wrapper .add_form .level_option label span {
        border-radius: 5px; }
      .news_wrapper .add_form .level_option label .at {
        color: purple;
        border: 1px solid purple; }
      .news_wrapper .add_form .level_option label .ma {
        color: blue;
        border: 1px solid blue; }
      .news_wrapper .add_form .level_option label .ba {
        color: green;
        border: 1px solid green; }
    .news_wrapper .add_form .level_option .back_error {
      top: 14px;
      right: -39px;
      font-family: 'Nunito Sans', sans-serif; }

.news_wrapper .data_card_wrapper .card_body .card_items_row span {
  text-align: center;
  width: 10% !important; }

.news_wrapper .MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6 {
  background-color: #1cc88a; }

.news_wrapper .MuiSnackbar-root.MuiSnackbar-anchorOriginTopLeft {
  top: 270px;
  left: 500px; }

.seo_wrapper {
  display: flex;
  flex-direction: column; }
  .seo_wrapper .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 24px 16px;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796; }
    .seo_wrapper .breadcrumbs a {
      color: #4e73df;
      text-decoration: none; }

.data_card_wrapper {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 24px 24px 24px;
  background-color: #FFFFFF; }
  .data_card_wrapper .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #5a5c69;
    font-weight: 800;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0; }
  .data_card_wrapper .yellow {
    color: #f6c23e; }
  .data_card_wrapper .green {
    color: #1cc88a; }
  .data_card_wrapper .card_body {
    padding: 20px; }
    .data_card_wrapper .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .data_card_wrapper .card_body .card_select_row span {
        color: #858796;
        margin: 0 15px 0 0; }
        .data_card_wrapper .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .data_card_wrapper .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .data_card_wrapper .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .data_card_wrapper .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .data_card_wrapper .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .data_card_wrapper .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .data_card_wrapper .card_body .card_search_row .search_input {
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .data_card_wrapper .card_body .card_controls {
      display: flex; }
    .data_card_wrapper .card_body .card_table {
      overflow: auto; }
    .data_card_wrapper .card_body .card_items_row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .data_card_wrapper .card_body .card_items_row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .data_card_wrapper .card_body .card_items_row > div .table_div {
          margin: 0 auto; }
        .data_card_wrapper .card_body .card_items_row > div:first-child {
          width: 10%; }
        .data_card_wrapper .card_body .card_items_row > div:nth-child(2) {
          width: 22%; }
        .data_card_wrapper .card_body .card_items_row > div:nth-child(3) {
          width: 18%;
          text-align: center; }
        .data_card_wrapper .card_body .card_items_row > div:nth-child(4) {
          width: 18%; }
        .data_card_wrapper .card_body .card_items_row > div:nth-child(5) {
          width: 15%; }
        .data_card_wrapper .card_body .card_items_row > div:last-child {
          width: 17%; }
        .data_card_wrapper .card_body .card_items_row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .data_card_wrapper .card_body .card_items_row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .data_card_wrapper .card_body .card_items_row > div .arrows .active_arrow {
            opacity: 0.5; }
      .data_card_wrapper .card_body .card_items_row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .data_card_wrapper .card_body .card_items_row:last-child {
        background-color: #FFFFFF; }
        .data_card_wrapper .card_body .card_items_row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .data_card_wrapper .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .data_card_wrapper .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .data_card_wrapper .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .data_card_wrapper .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .data_card_wrapper .card_body .header_row {
      background-color: #FFFFFF !important; }
    .data_card_wrapper .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .data_card_wrapper .card_body .default_row {
      font-weight: 400 !important; }
      .data_card_wrapper .card_body .default_row > div {
        justify-content: center !important;
        align-items: center !important; }
        .data_card_wrapper .card_body .default_row > div:nth-child(5) {
          justify-content: left !important; }
          .data_card_wrapper .card_body .default_row > div:nth-child(5) .table_circle {
            height: 15px;
            width: 15px;
            background-color: #bbbbbb;
            border-radius: 50%;
            margin-right: 11px; }
          .data_card_wrapper .card_body .default_row > div:nth-child(5) .active {
            background-color: green; }

.users_wrapper .breadcrumbs {
  display: flex;
  padding: 12px 16px;
  margin: 0 24px 16px;
  list-style: none;
  background-color: #eaecf4;
  border-radius: 0.35rem;
  color: #858796; }
  .users_wrapper .breadcrumbs a {
    color: #4e73df;
    text-decoration: none; }

.users_wrapper .data_wrapper {
  display: flex; }
  .users_wrapper .data_wrapper .data_card {
    width: 100%;
    margin-right: 24px;
    margin-bottom: 24px; }
  .users_wrapper .data_wrapper .active {
    margin-left: 24px; }
    .users_wrapper .data_wrapper .active .card_title {
      color: #1cc88a; }
  .users_wrapper .data_wrapper .no_data {
    display: none; }
  .users_wrapper .data_wrapper .unactive .card_title {
    color: #5a5c69; }

.users_wrapper .add_form {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border: 1px solid #e3e6f0;
  border-radius: 5.6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #858796;
  width: 100%;
  padding: 20px;
  max-width: 40%;
  box-sizing: border-box;
  margin: 0 0 16px 24px; }
  .users_wrapper .add_form .block-input-mail span.error {
    top: -10px; }
  .users_wrapper .add_form .block-input-pass span.error {
    top: -25px; }
  .users_wrapper .add_form .add_form_names {
    display: flex;
    flex-direction: row; }
    .users_wrapper .add_form .add_form_names .names {
      width: 100%; }
      .users_wrapper .add_form .add_form_names .names .back_error {
        width: 50px;
        top: 5px; }
    .users_wrapper .add_form .add_form_names div:first-child {
      margin-right: 10px; }
  .users_wrapper .add_form .add_input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px; }
    .users_wrapper .add_form .add_input:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .users_wrapper .add_form button {
    color: #fff;
    background-color: #4e73df;
    border-color: #4e73df;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.35rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px; }
    .users_wrapper .add_form button:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
    .users_wrapper .add_form button:hover {
      background-color: #2e59d9;
      border-color: #2653d4; }
    .users_wrapper .add_form button:active {
      background-color: #2653d4;
      border-color: #244ec9; }

.users_wrapper .data_card_wrapper .card_body .card_items_row {
  min-width: unset; }
  .users_wrapper .data_card_wrapper .card_body .card_items_row span {
    text-align: center;
    justify-content: space-evenly; }

.users_wrapper .MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6 {
  background-color: #1cc88a; }

.users_wrapper .MuiSnackbar-root.MuiSnackbar-anchorOriginTopLeft {
  top: 260px;
  left: 500px; }

.summary_table {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 24px; }
  .summary_table:nth-last-child {
    margin-right: 0; }
  .summary_table .data_card {
    width: 100%;
    min-height: 60px;
    padding: 20px;
    border-left: 0.25rem solid #1cc88a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 5.6px;
    margin: 0 24px 8px 0;
    align-items: center; }
    .summary_table .data_card .card_text {
      display: flex;
      flex-direction: column; }
      .summary_table .data_card .card_text .card_title {
        margin-bottom: 4px;
        font-weight: 800;
        text-transform: uppercase;
        color: #1cc88a;
        font-size: 11px; }
      .summary_table .data_card .card_text .card_amount {
        color: #5a5c69;
        font-weight: 700;
        font-size: 20px; }
  .summary_table .data_card.no_data {
    border-left: 0.25rem solid #f6c23e; }
    .summary_table .data_card.no_data img {
      height: 32px; }
    .summary_table .data_card.no_data .card_title {
      color: #f6c23e !important; }
  .summary_table .data_card.unactive {
    border-left: 0.25rem solid #5a5c69; }
    .summary_table .data_card.unactive .card_title {
      color: #5a5c69 !important; }
  .summary_table .data_card.active_without_lawyer {
    border-left: 0.25rem solid #4e73df; }
    .summary_table .data_card.active_without_lawyer .card_title {
      color: #4e73df !important; }
  .summary_table img {
    width: 32px; }

.user_profile_wrapper {
  margin: 0 24px; }
  .user_profile_wrapper .user_name {
    color: #5a5c69;
    margin-bottom: 24px;
    font-size: 28px;
    display: block; }
  .user_profile_wrapper .profile_block {
    margin-bottom: 16px; }
    .user_profile_wrapper .profile_block .profile_block_title {
      background-color: rgba(0, 0, 0, 0.03);
      border-top-left-radius: 5.6px;
      border-top-right-radius: 5.6px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      padding: 12px 20px;
      color: #858796;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .user_profile_wrapper .profile_block .profile_block_title .add_button {
        display: flex;
        align-items: center;
        color: #fff;
        background-color: #1cc88a;
        border-color: #1cc88a;
        border-radius: 5.6px;
        cursor: pointer; }
        .user_profile_wrapper .profile_block .profile_block_title .add_button div {
          display: flex;
          align-items: center;
          padding: 6px 12px;
          line-height: 24px;
          background-color: rgba(0, 0, 0, 0.15);
          height: 24px; }
        .user_profile_wrapper .profile_block .profile_block_title .add_button span {
          font-size: 16px;
          padding: 6px 12px; }
        .user_profile_wrapper .profile_block .profile_block_title .add_button svg {
          width: 16px; }
        .user_profile_wrapper .profile_block .profile_block_title .add_button:hover {
          background-color: #17a673;
          border-color: #169b6b; }
        .user_profile_wrapper .profile_block .profile_block_title .add_button:active {
          background-color: #169b6b;
          border-color: #149063; }
        .user_profile_wrapper .profile_block .profile_block_title .add_button:focus {
          box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .user_profile_wrapper .profile_block .card_body {
      padding: 20px;
      background-color: #FFFFFF; }
      .user_profile_wrapper .profile_block .card_body .collapse_form {
        width: 50%;
        padding: 20px;
        border: 1px solid #e3e6f0;
        border-radius: 5.6px;
        box-sizing: border-box;
        margin-bottom: 16px; }
        .user_profile_wrapper .profile_block .card_body .collapse_form button {
          line-height: 24px;
          font-size: 16px;
          border-radius: 5.6px;
          color: #FFFFFF;
          padding: 7px 12px 7px;
          background-color: #4e73df;
          cursor: pointer; }
          .user_profile_wrapper .profile_block .card_body .collapse_form button:hover {
            background-color: #2e59d9;
            border-color: #2653d4; }
          .user_profile_wrapper .profile_block .card_body .collapse_form button:active {
            background-color: #2653d4;
            border-color: #244ec9; }
          .user_profile_wrapper .profile_block .card_body .collapse_form button:focus {
            box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
        .user_profile_wrapper .profile_block .card_body .collapse_form .language_row {
          margin-bottom: 16px;
          display: flex;
          line-height: 24px;
          font-size: 16px; }
          .user_profile_wrapper .profile_block .card_body .collapse_form .language_row .language_title {
            box-sizing: border-box;
            min-width: -webkit-fit-content;
            min-width: -moz-fit-content;
            min-width: fit-content;
            color: #858796;
            border: 1px solid #d1d3e2;
            background-color: #eaecf4;
            padding: 4px 8px; }
          .user_profile_wrapper .profile_block .card_body .collapse_form .language_row .language_input {
            border: 1px solid #d1d3e2;
            width: 100%;
            padding: 4px 8px;
            color: #6e707e;
            font-size: 14px; }
      .user_profile_wrapper .profile_block .card_body .card_items_row {
        display: flex;
        border-top: 1px solid #e3e6f0;
        color: #858796;
        font-size: 16px;
        font-weight: 800;
        justify-content: flex-start;
        padding: 12px 6px; }
        .user_profile_wrapper .profile_block .card_body .card_items_row > div {
          display: flex;
          align-items: flex-end;
          width: 10%;
          justify-content: space-around;
          text-align: center; }
          .user_profile_wrapper .profile_block .card_body .card_items_row > div .table_div {
            margin: 0 auto; }
          .user_profile_wrapper .profile_block .card_body .card_items_row > div:first-child {
            width: 10%; }
          .user_profile_wrapper .profile_block .card_body .card_items_row > div:nth-child(2) {
            width: 15%; }
          .user_profile_wrapper .profile_block .card_body .card_items_row > div:nth-child(3) {
            width: 15%; }
          .user_profile_wrapper .profile_block .card_body .card_items_row > div:nth-child(4) {
            width: 25%; }
          .user_profile_wrapper .profile_block .card_body .card_items_row > div:nth-child(5) {
            width: 15%; }
          .user_profile_wrapper .profile_block .card_body .card_items_row > div:last-child {
            width: 20%; }
          .user_profile_wrapper .profile_block .card_body .card_items_row > div .arrows {
            display: flex;
            margin: 5px 10px;
            cursor: pointer; }
            .user_profile_wrapper .profile_block .card_body .card_items_row > div .arrows img {
              height: 15px;
              margin-right: 3px;
              opacity: 0.1;
              fill: #1A1A1A; }
            .user_profile_wrapper .profile_block .card_body .card_items_row > div .arrows .active_arrow {
              opacity: 0.5; }
        .user_profile_wrapper .profile_block .card_body .card_items_row:nth-child(even) {
          background-color: rgba(0, 0, 0, 0.05); }
      .user_profile_wrapper .profile_block .card_body .card_items_row.companies {
        display: flex;
        border-top: 1px solid #e3e6f0;
        color: #858796;
        font-size: 16px;
        font-weight: 800;
        justify-content: flex-start;
        padding: 12px 6px; }
        .user_profile_wrapper .profile_block .card_body .card_items_row.companies > div {
          display: flex;
          align-items: flex-end;
          width: 10%;
          justify-content: space-around;
          text-align: center; }
          .user_profile_wrapper .profile_block .card_body .card_items_row.companies > div .table_div {
            margin: 0 auto; }
          .user_profile_wrapper .profile_block .card_body .card_items_row.companies > div:first-child {
            width: 20%; }
          .user_profile_wrapper .profile_block .card_body .card_items_row.companies > div:nth-child(2) {
            width: 30%; }
          .user_profile_wrapper .profile_block .card_body .card_items_row.companies > div:nth-child(3) {
            width: 20%; }
          .user_profile_wrapper .profile_block .card_body .card_items_row.companies > div:last-child {
            width: 30%; }
          .user_profile_wrapper .profile_block .card_body .card_items_row.companies > div .arrows {
            display: flex;
            margin: 5px 10px;
            cursor: pointer; }
            .user_profile_wrapper .profile_block .card_body .card_items_row.companies > div .arrows img {
              height: 15px;
              margin-right: 3px;
              opacity: 0.1;
              fill: #1A1A1A; }
            .user_profile_wrapper .profile_block .card_body .card_items_row.companies > div .arrows .active_arrow {
              opacity: 0.5; }
        .user_profile_wrapper .profile_block .card_body .card_items_row.companies:nth-child(even) {
          background-color: rgba(0, 0, 0, 0.05); }
      .user_profile_wrapper .profile_block .card_body .default_row.companies > div:nth-child(3) {
        display: flex;
        justify-content: center !important; }
        .user_profile_wrapper .profile_block .card_body .default_row.companies > div:nth-child(3) .table_circle {
          height: 15px;
          width: 15px;
          background-color: #bbbbbb;
          border-radius: 50%;
          margin-right: 11px; }
        .user_profile_wrapper .profile_block .card_body .default_row.companies > div:nth-child(3) .active {
          background-color: green; }
      .user_profile_wrapper .profile_block .card_body .default_row.lawyers div:nth-child(4) {
        display: flex;
        flex-direction: column; }
      .user_profile_wrapper .profile_block .card_body .default_row {
        font-weight: 400 !important;
        align-items: center; }
        .user_profile_wrapper .profile_block .card_body .default_row > div {
          justify-content: center !important;
          align-items: center !important; }
          .user_profile_wrapper .profile_block .card_body .default_row > div:nth-child(5) {
            display: flex;
            justify-content: center !important; }
            .user_profile_wrapper .profile_block .card_body .default_row > div:nth-child(5) .table_circle {
              height: 15px;
              width: 15px;
              background-color: #bbbbbb;
              border-radius: 50%;
              margin-right: 11px; }
            .user_profile_wrapper .profile_block .card_body .default_row > div:nth-child(5) .active {
              background-color: green; }
      .user_profile_wrapper .profile_block .card_body .header_row {
        background-color: #FFFFFF !important; }
    .user_profile_wrapper .profile_block .form_select {
      display: block;
      padding: 0.375rem 0.75rem;
      margin-bottom: 16px;
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #6e707e;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #d1d3e2;
      border-radius: 0.35rem;
      box-sizing: border-box;
      -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin-top: 8px;
      -webkit-appearance: none;
      -moz-appearance: none; }
      .user_profile_wrapper .profile_block .form_select:focus {
        border-color: #bac8f3;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .user_profile_wrapper .blocks_wrapper {
    display: flex;
    flex-direction: row; }
    .user_profile_wrapper .blocks_wrapper .profile_block:first-child {
      width: 100%;
      margin-right: 24px; }
      .user_profile_wrapper .blocks_wrapper .profile_block:first-child .collapse_form {
        width: 100%; }
    .user_profile_wrapper .blocks_wrapper .profile_block:last-child {
      width: 100%; }
      .user_profile_wrapper .blocks_wrapper .profile_block:last-child .collapse_form {
        width: 100%; }
  .user_profile_wrapper .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 0 16px;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796; }
    .user_profile_wrapper .breadcrumbs a {
      color: #4e73df;
      text-decoration: none; }
  .user_profile_wrapper .add_form {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid #e3e6f0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #858796;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-bottom-left-radius: 5.6px;
    border-bottom-right-radius: 5.6px; }
    .user_profile_wrapper .add_form .add_form_city {
      display: flex;
      flex-direction: row; }
      .user_profile_wrapper .add_form .add_form_city .names {
        width: calc(25%);
        margin-left: 10px; }
      .user_profile_wrapper .add_form .add_form_city .names.first {
        width: calc(50% + 10px);
        margin-left: 0; }
    .user_profile_wrapper .add_form .block-input-mail span.error {
      top: -10px; }
    .user_profile_wrapper .add_form .block-input-pass {
      width: calc(50% - 5px); }
      .user_profile_wrapper .add_form .block-input-pass span.error {
        top: -25px; }
    .user_profile_wrapper .add_form .add_form_names {
      display: flex;
      flex-direction: row; }
      .user_profile_wrapper .add_form .add_form_names .names {
        width: 100%; }
      .user_profile_wrapper .add_form .add_form_names div:first-child {
        margin-right: 10px; }
    .user_profile_wrapper .add_form .add_input {
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #6e707e;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #d1d3e2;
      border-radius: 0.35rem;
      box-sizing: border-box;
      -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin-top: 8px;
      margin-bottom: 16px; }
      .user_profile_wrapper .add_form .add_input::-webkit-input-placeholder {
        color: #858796; }
      .user_profile_wrapper .add_form .add_input::-moz-placeholder {
        color: #858796; }
      .user_profile_wrapper .add_form .add_input::-ms-input-placeholder {
        color: #858796; }
      .user_profile_wrapper .add_form .add_input::placeholder {
        color: #858796; }
      .user_profile_wrapper .add_form .add_input:focus {
        border-color: #bac8f3;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .user_profile_wrapper .add_form button {
      color: #fff;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      background-color: #1cc88a;
      border-color: #1cc88a;
      cursor: pointer;
      padding: 6px 12px;
      font-size: 16px;
      line-height: 1.5;
      border-radius: 5.6px;
      -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      text-align: center;
      vertical-align: middle;
      margin-top: 16px; }
      .user_profile_wrapper .add_form button:focus {
        box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
      .user_profile_wrapper .add_form button:hover {
        background-color: #17a673; }
      .user_profile_wrapper .add_form button:active {
        background-color: #169b6b;
        border-color: #149063; }

@media only screen and (max-width: 1250px) {
  .user_profile_wrapper .blocks_wrapper {
    flex-direction: column; }
    .user_profile_wrapper .blocks_wrapper .profile_block:first-child .collapse_form {
      width: 50%; }
    .user_profile_wrapper .blocks_wrapper .profile_block:last-child .collapse_form {
      width: 50%; } }

.company_data_card .card_select_row {
  display: flex;
  align-items: center; }
  .company_data_card .card_select_row span {
    color: #858796; }
    .company_data_card .card_select_row span:first-of-type {
      margin-right: 10px; }
    .company_data_card .card_select_row span:last-of-type {
      margin-left: 10px; }
  .company_data_card .card_select_row .form_select {
    display: block;
    padding: 6px 3px 6px 12px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .company_data_card .card_select_row .form_select:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }

.company_data_card .card_search_row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 15px; }
  .company_data_card .card_search_row span {
    color: #858796; }
  .company_data_card .card_search_row .search_form {
    max-width: 325px;
    width: 100%;
    display: flex;
    margin-left: 15px; }
  .company_data_card .card_search_row .search_input {
    font-family: "Nunito Sans", sans-serif;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    color: #6e707e;
    width: 100%;
    height: 38px;
    padding: 0 12px;
    box-sizing: border-box;
    letter-spacing: normal; }

.company_data_card .card_controls {
  display: flex; }

.company_data_card .card_table {
  overflow: auto; }

.company_data_card .card_items_row {
  display: flex;
  border-top: 1px solid #e3e6f0;
  color: #858796;
  font-size: 16px;
  font-weight: 800;
  justify-content: flex-start;
  padding: 12px 6px; }
  .company_data_card .card_items_row > div {
    display: flex;
    align-items: flex-end;
    width: 10%;
    justify-content: space-around;
    text-align: center; }
    .company_data_card .card_items_row > div .table_div {
      margin: 0 auto; }
    .company_data_card .card_items_row > div:first-child {
      width: 10%; }
    .company_data_card .card_items_row > div:nth-child(2) {
      width: 25%; }
    .company_data_card .card_items_row > div:nth-child(3) {
      width: 15%; }
    .company_data_card .card_items_row > div:nth-child(4) {
      width: 20%; }
    .company_data_card .card_items_row > div:nth-child(5) {
      width: 15%; }
    .company_data_card .card_items_row > div:last-child {
      width: 15%; }
    .company_data_card .card_items_row > div .arrows {
      display: flex;
      margin: 5px 10px;
      cursor: pointer; }
      .company_data_card .card_items_row > div .arrows img {
        height: 15px;
        margin-right: 3px;
        opacity: 0.1;
        fill: #1A1A1A; }
      .company_data_card .card_items_row > div .arrows .active_arrow {
        opacity: 0.5; }
  .company_data_card .card_items_row:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05); }
  .company_data_card .card_items_row:last-child {
    background-color: #FFFFFF; }
    .company_data_card .card_items_row:last-child span .arrows svg path {
      fill: #FFFFFF; }

.company_data_card .card_entries_count {
  font-size: 16px;
  color: #858796;
  margin: 20px 0; }

.company_data_card .header_row {
  background-color: #FFFFFF !important; }

.company_data_card .footer_row {
  background-color: #FFFFFF !important;
  align-items: flex-start; }

.company_data_card .default_row {
  font-weight: 400 !important; }
  .company_data_card .default_row > div {
    justify-content: center !important;
    align-items: center !important; }
    .company_data_card .default_row > div:nth-child(5) {
      justify-content: left !important; }
      .company_data_card .default_row > div:nth-child(5) .table_circle {
        height: 15px;
        width: 15px;
        background-color: #bbbbbb;
        border-radius: 50%;
        margin-right: 11px; }
      .company_data_card .default_row > div:nth-child(5) .active {
        background-color: green; }

.data_card_wrapper_table {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 0 24px 0;
  background-color: #FFFFFF; }
  .data_card_wrapper_table .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #858796;
    font-weight: 400;
    font-size: 28px; }
  .data_card_wrapper_table .card_body {
    padding: 20px; }
    .data_card_wrapper_table .card_body .card_buttons_row {
      display: flex; }
      .data_card_wrapper_table .card_body .card_buttons_row .green_button {
        margin: 0 15px 20px 0;
        display: flex;
        justify-content: center;
        align-items: center; }
    .data_card_wrapper_table .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .data_card_wrapper_table .card_body .card_select_row span {
        color: #858796;
        margin: 0 15px 0 0; }
        .data_card_wrapper_table .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .data_card_wrapper_table .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .data_card_wrapper_table .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .data_card_wrapper_table .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .data_card_wrapper_table .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .data_card_wrapper_table .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .data_card_wrapper_table .card_body .card_search_row .search_input {
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .data_card_wrapper_table .card_body .card_controls {
      display: flex; }
    .data_card_wrapper_table .card_body .card_table {
      overflow: auto; }
    .data_card_wrapper_table .card_body .card_items_row.row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .data_card_wrapper_table .card_body .card_items_row.row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .data_card_wrapper_table .card_body .card_items_row.row > div .table_div {
          margin: 0 auto; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:first-child {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(2) {
          width: 15%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(3) {
          width: 10%;
          text-align: center; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(4) {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(5) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(6) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(7) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(8) {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:last-child {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .data_card_wrapper_table .card_body .card_items_row.row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .data_card_wrapper_table .card_body .card_items_row.row > div .arrows .active_arrow {
            opacity: 0.5; }
      .data_card_wrapper_table .card_body .card_items_row.row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .data_card_wrapper_table .card_body .card_items_row.row:last-child {
        background-color: #FFFFFF; }
        .data_card_wrapper_table .card_body .card_items_row.row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .data_card_wrapper_table .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .data_card_wrapper_table .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .data_card_wrapper_table .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .data_card_wrapper_table .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .data_card_wrapper_table .card_body .header_row {
      background-color: #FFFFFF !important; }
    .data_card_wrapper_table .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .data_card_wrapper_table .card_body .default_row.row {
      font-weight: 400 !important; }
      .data_card_wrapper_table .card_body .default_row.row > div {
        justify-content: center !important;
        align-items: center !important; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(5) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(6) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(7) {
          display: flex;
          flex-direction: column;
          justify-content: center !important; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(8) .table_circle {
          height: 15px;
          width: 15px;
          background-color: orange;
          border-radius: 50%;
          margin-right: 11px; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(8) .active {
          background-color: green; }

.dialog_footer {
  flex-direction: row !important; }

.company_profile_wrapper {
  margin: 0 24px; }
  .company_profile_wrapper .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 0 16px;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796; }
    .company_profile_wrapper .breadcrumbs a {
      color: #4e73df;
      text-decoration: none; }
  .company_profile_wrapper .company_name {
    color: #5a5c69;
    margin-bottom: 24px;
    font-size: 28px;
    display: block; }
  .company_profile_wrapper .connection_row {
    margin-bottom: 16px;
    display: flex;
    line-height: 24px;
    font-size: 16px;
    width: 65%;
    position: relative; }
    .company_profile_wrapper .connection_row .connection_title {
      box-sizing: border-box;
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      color: #858796;
      border: 1px solid #d1d3e2;
      background-color: #eaecf4;
      padding: 6px 12px;
      border-bottom-left-radius: 5.6px;
      border-top-left-radius: 5.6px;
      display: flex;
      align-items: center; }
    .company_profile_wrapper .connection_row .popper_wrapper {
      position: absolute;
      width: calc(100% - 250px);
      top: 39px;
      left: 156px;
      z-index: 100;
      background: white;
      font-family: 'Nunito Sans', sans-serif;
      line-height: 1.5;
      color: #6e707e;
      border-radius: 0.35rem; }
      .company_profile_wrapper .connection_row .popper_wrapper li {
        padding: 0 12px; }
        .company_profile_wrapper .connection_row .popper_wrapper li:first-of-type {
          border-top-left-radius: 0.35rem;
          border-top-right-radius: 0.35rem; }
        .company_profile_wrapper .connection_row .popper_wrapper li:last-of-type {
          border-bottom-left-radius: 0.35rem;
          border-bottom-right-radius: 0.35rem; }
        .company_profile_wrapper .connection_row .popper_wrapper li:hover {
          color: #ffffff;
          background-color: #4e73df;
          cursor: pointer; }
    .company_profile_wrapper .connection_row .smaller_popper {
      width: calc(100% - 206px); }
    .company_profile_wrapper .connection_row .connection_input {
      border: 1px solid #d1d3e2;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      margin-right: 8px;
      color: #858796;
      cursor: text; }
      .company_profile_wrapper .connection_row .connection_input:disabled {
        background-color: #eaecf4; }
  .company_profile_wrapper .blocks_wrapper {
    display: flex;
    flex-direction: row; }
    .company_profile_wrapper .blocks_wrapper .field_name {
      color: #5a5c69;
      margin-bottom: 24px;
      font-size: 28px;
      display: block; }
  .company_profile_wrapper .column {
    display: flex;
    flex-direction: column;
    flex: 0 0 75%; }
  .company_profile_wrapper .profile_block {
    border: 1px solid #e3e6f0;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 5.6px;
    margin-bottom: 16px; }
    .company_profile_wrapper .profile_block .image_wrapper {
      width: 100%;
      text-align: center; }
    .company_profile_wrapper .profile_block .profile_block_title {
      background-color: rgba(0, 0, 0, 0.03);
      border-top-left-radius: 5.6px;
      border-top-right-radius: 5.6px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      padding: 12px 20px;
      color: #858796;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .company_profile_wrapper .profile_block .profile_block_title.profile_block_title.services {
        background-color: unset;
        display: flex;
        justify-content: flex-end;
        border-bottom: unset;
        align-items: flex-start; }
      .company_profile_wrapper .profile_block .profile_block_title .add_button {
        display: flex;
        align-items: center;
        color: #fff;
        background-color: #1cc88a;
        border-color: #1cc88a;
        border-radius: 5.6px;
        cursor: pointer;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content; }
        .company_profile_wrapper .profile_block .profile_block_title .add_button div {
          display: flex;
          align-items: center;
          padding: 6px 12px;
          line-height: 24px;
          background-color: rgba(0, 0, 0, 0.15);
          height: 24px; }
        .company_profile_wrapper .profile_block .profile_block_title .add_button span {
          font-size: 16px;
          padding: 6px 12px; }
        .company_profile_wrapper .profile_block .profile_block_title .add_button img {
          width: 16px; }
        .company_profile_wrapper .profile_block .profile_block_title .add_button:hover {
          background-color: #17a673;
          border-color: #169b6b; }
        .company_profile_wrapper .profile_block .profile_block_title .add_button:active {
          background-color: #169b6b;
          border-color: #149063; }
        .company_profile_wrapper .profile_block .profile_block_title .add_button:focus {
          box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .company_profile_wrapper .profile_block .company_banner {
      height: 250px;
      margin-bottom: 16px; }
    .company_profile_wrapper .profile_block .card_body {
      padding: 20px;
      background-color: #ffffff; }
      .company_profile_wrapper .profile_block .card_body span {
        color: #858796; }
      .company_profile_wrapper .profile_block .card_body .input_title {
        margin-bottom: 8px;
        display: block; }
      .company_profile_wrapper .profile_block .card_body .collapse_form {
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: 20px;
        border: 1px solid #e3e6f0;
        border-radius: 5.6px;
        box-sizing: border-box;
        margin-bottom: 16px;
        position: relative; }
        .company_profile_wrapper .profile_block .card_body .collapse_form .lawyer_error {
          left: 20px;
          right: unset; }
        .company_profile_wrapper .profile_block .card_body .collapse_form button {
          line-height: 24px;
          font-size: 16px;
          border-radius: 5.6px;
          color: #ffffff;
          padding: 7px 12px 7px;
          background-color: #4e73df;
          width: 136px;
          height: 38px;
          cursor: pointer;
          margin: 10px 0 20px; }
          .company_profile_wrapper .profile_block .card_body .collapse_form button:hover {
            background-color: #2e59d9;
            border-color: #2653d4; }
          .company_profile_wrapper .profile_block .card_body .collapse_form button:disabled {
            opacity: 0.7; }
            .company_profile_wrapper .profile_block .card_body .collapse_form button:disabled:hover {
              background-color: #4e73df;
              border-color: unset; }
          .company_profile_wrapper .profile_block .card_body .collapse_form button:active {
            background-color: #2653d4;
            border-color: #244ec9; }
          .company_profile_wrapper .profile_block .card_body .collapse_form button:focus {
            box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
        .company_profile_wrapper .profile_block .card_body .collapse_form .form_select {
          display: block;
          padding: 0.375rem 0.75rem;
          margin-bottom: 16px;
          width: 100%;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #6e707e;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #d1d3e2;
          border-radius: 0.35rem;
          box-sizing: border-box;
          -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          margin-top: 8px;
          -webkit-appearance: none;
          -moz-appearance: none; }
          .company_profile_wrapper .profile_block .card_body .collapse_form .form_select:focus {
            border-color: #bac8f3;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
        .company_profile_wrapper .profile_block .card_body .collapse_form .language_row {
          margin-bottom: 16px;
          display: flex;
          line-height: 24px;
          font-size: 16px; }
          .company_profile_wrapper .profile_block .card_body .collapse_form .language_row .language_title {
            box-sizing: border-box;
            min-width: -webkit-fit-content;
            min-width: -moz-fit-content;
            min-width: fit-content;
            color: #858796;
            border: 1px solid #d1d3e2;
            background-color: #eaecf4;
            padding: 4px 8px; }
          .company_profile_wrapper .profile_block .card_body .collapse_form .language_row .language_input {
            border: 1px solid #d1d3e2;
            width: 100%;
            padding: 4px 8px;
            color: #6e707e;
            font-size: 14px; }
      .company_profile_wrapper .profile_block .card_body .card_items_row {
        display: flex;
        border-top: 1px solid #e3e6f0;
        color: #858796;
        font-size: 16px;
        font-weight: 800;
        justify-content: flex-start;
        padding: 12px 6px; }
        .company_profile_wrapper .profile_block .card_body .card_items_row span {
          display: flex;
          align-items: center; }
          .company_profile_wrapper .profile_block .card_body .card_items_row span div {
            margin: 0 auto; }
        .company_profile_wrapper .profile_block .card_body .card_items_row:nth-child(even) {
          background-color: rgba(0, 0, 0, 0.05); }
      .company_profile_wrapper .profile_block .card_body .card_items_row.lawyers span:first-child {
        width: 5%; }
      .company_profile_wrapper .profile_block .card_body .card_items_row.lawyers span:nth-child(2) {
        width: 20%; }
      .company_profile_wrapper .profile_block .card_body .card_items_row.lawyers span:nth-child(3) {
        width: 15%; }
      .company_profile_wrapper .profile_block .card_body .card_items_row.lawyers span:nth-child(4) {
        width: 30%; }
      .company_profile_wrapper .profile_block .card_body .card_items_row.lawyers span:nth-child(5) {
        width: 10%; }
      .company_profile_wrapper .profile_block .card_body .card_items_row.lawyers span:last-child {
        width: 20%; }
      .company_profile_wrapper .profile_block .card_body .upload_file {
        width: 100%;
        position: relative; }
        .company_profile_wrapper .profile_block .card_body .upload_file .upload_pic_error {
          top: 40px; }
        .company_profile_wrapper .profile_block .card_body .upload_file .upload_input {
          position: relative;
          z-index: 2;
          width: 100%;
          height: calc(1.5em + 0.75rem + 2px);
          margin: 0;
          opacity: 0; }
        .company_profile_wrapper .profile_block .card_body .upload_file .upload_label {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          z-index: 1;
          padding: 6px 80px 6px 12px;
          font-weight: 400;
          line-height: 1.5;
          color: #6e707e;
          background-color: #fff;
          border: 1px solid #d1d3e2;
          border-radius: 0.35rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
          .company_profile_wrapper .profile_block .card_body .upload_file .upload_label::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
            display: block;
            padding: 0.375rem 0.75rem;
            line-height: 1.5;
            color: #6e707e;
            content: 'Browse';
            background-color: #eaecf4;
            border-left: inherit;
            border-radius: 0 0.35rem 0.35rem 0; }
      .company_profile_wrapper .profile_block .card_body .button {
        color: #fff;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background-color: #1cc88a;
        border-color: #1cc88a;
        cursor: pointer;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 5.6px;
        -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        text-align: center;
        vertical-align: middle;
        margin-top: 16px;
        min-height: 36px;
        min-width: 59px; }
        .company_profile_wrapper .profile_block .card_body .button:focus {
          box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
        .company_profile_wrapper .profile_block .card_body .button:hover {
          background-color: #17a673; }
        .company_profile_wrapper .profile_block .card_body .button:active {
          background-color: #169b6b;
          border-color: #149063; }
        .company_profile_wrapper .profile_block .card_body .button:disabled {
          opacity: 0.7; }
          .company_profile_wrapper .profile_block .card_body .button:disabled:hover {
            background-color: #1cc88a; }
      .company_profile_wrapper .profile_block .card_body .upload_button {
        min-height: 36px;
        min-width: 111px; }
      .company_profile_wrapper .profile_block .card_body .field_wrapper {
        display: flex;
        flex-direction: row; }
        .company_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher {
          display: flex;
          flex-direction: column;
          margin-right: 24px; }
          .company_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item {
            cursor: pointer;
            display: block;
            padding: 0.75rem 1.25rem;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.125); }
            .company_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item:first-child {
              border-top-left-radius: 0.35rem;
              border-top-right-radius: 0.35rem;
              border-bottom: none; }
            .company_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item:last-child {
              border-bottom-right-radius: 0.35rem;
              border-bottom-left-radius: 0.35rem; }
          .company_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item.active {
            z-index: 2;
            color: #fff;
            background-color: #4e73df;
            border-color: #4e73df; }
        .company_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block {
          width: 100%;
          display: flex;
          position: relative; }
          .company_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea {
            display: block;
            width: 100%;
            padding: 6px 12px;
            font-size: 16px;
            font-weight: 400;
            font-family: 'Nunito Sans', sans-serif;
            line-height: 1.5;
            color: #6e707e;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #d1d3e2;
            -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            margin-top: 0;
            margin-bottom: 0;
            height: 260px;
            border-bottom-right-radius: 5.6px;
            border-top-right-radius: 5.6px;
            resize: none; }
            .company_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea:focus {
              box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
              border-color: #bac8f3; }
      .company_profile_wrapper .profile_block .card_body .card_field {
        display: flex;
        margin-bottom: 8px;
        position: relative; }
        .company_profile_wrapper .profile_block .card_body .card_field .input_name {
          display: flex;
          align-items: center;
          padding: 6px 12px;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          color: #6e707e;
          text-align: center;
          background-color: #eaecf4;
          border: 1px solid #d1d3e2;
          border-radius: 5.6px; }
      .company_profile_wrapper .profile_block .card_body .form_select {
        display: block;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .company_profile_wrapper .profile_block .card_body .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
      .company_profile_wrapper .profile_block .card_body .add_input {
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
        .company_profile_wrapper .profile_block .card_body .add_input::-webkit-input-placeholder {
          color: #858796; }
        .company_profile_wrapper .profile_block .card_body .add_input::-moz-placeholder {
          color: #858796; }
        .company_profile_wrapper .profile_block .card_body .add_input::-ms-input-placeholder {
          color: #858796; }
        .company_profile_wrapper .profile_block .card_body .add_input::placeholder {
          color: #858796; }
        .company_profile_wrapper .profile_block .card_body .add_input:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
      .company_profile_wrapper .profile_block .card_body .add_form_city {
        display: flex;
        flex-direction: row; }
        .company_profile_wrapper .profile_block .card_body .add_form_city .names {
          width: calc(25%);
          margin-left: 10px; }
        .company_profile_wrapper .profile_block .card_body .add_form_city .names.first {
          width: calc(50% + 10px);
          margin-left: 0; }
  .company_profile_wrapper .profile_block:first-child {
    width: 100%;
    margin-right: 24px; }
  .company_profile_wrapper .profile_block:last-child {
    width: 100%; }
  .company_profile_wrapper .profile_block.services .collapse_wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 12px 20px; }
    .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form {
      display: flex;
      flex-direction: column;
      padding: 12px 20px;
      border: 1px solid #e3e6f0;
      border-radius: 5.6px;
      box-sizing: border-box;
      margin-bottom: 16px;
      position: relative;
      width: 100%; }
      .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .back_error {
        right: 10px;
        color: #d0021b; }
      .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .block_field {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column; }
        .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .block_field .field_title {
          color: #858796; }
          .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .block_field .field_title.big {
            font-size: 28px; }
      .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row {
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row .add_input {
          margin-top: 8px;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #6e707e;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #d1d3e2;
          border-radius: 0.35rem;
          box-sizing: border-box;
          -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
          .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row .add_input::-webkit-input-placeholder {
            color: #858796;
            opacity: 0.5; }
          .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row .add_input::-moz-placeholder {
            color: #858796;
            opacity: 0.5; }
          .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row .add_input::-ms-input-placeholder {
            color: #858796;
            opacity: 0.5; }
          .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row .add_input::placeholder {
            color: #858796;
            opacity: 0.5; }
          .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row .add_input:focus {
            border-color: #bac8f3;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
        .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row .placeholder {
          position: absolute;
          top: 157px;
          left: 33px;
          color: #858796;
          opacity: 0.5;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
        .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row .block_field {
          width: 35%;
          margin-right: auto; }
        .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row .card_body {
          padding: unset;
          width: 100%;
          flex-direction: column; }
          .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .collapse_row .card_body .textarea {
            border-radius: 5.6px;
            margin-bottom: 8px; }
      .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form button {
        line-height: 24px;
        font-size: 16px;
        border-radius: 5.6px;
        color: #ffffff;
        padding: 7px 12px 7px;
        background-color: #1cc88a;
        cursor: pointer;
        width: 100%; }
        .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form button:hover {
          background-color: #17a673;
          border-color: #169b6b; }
        .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form button:active {
          background-color: #169b6b;
          border-color: #149063; }
        .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form button:focus {
          box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
        .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form button:disabled {
          opacity: 0.5; }
      .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .form_select {
        display: block;
        padding: 0.375rem 0.75rem;
        margin-bottom: 16px;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .company_profile_wrapper .profile_block.services .collapse_wrapper .collapse_form .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .company_profile_wrapper .profile_block.services .card_body {
    display: flex;
    flex-direction: row; }
    .company_profile_wrapper .profile_block.services .card_body .textarea_block {
      width: 100%;
      display: flex; }
      .company_profile_wrapper .profile_block.services .card_body .textarea_block .textarea_services {
        display: flex;
        width: 100%;
        margin-bottom: 16px; }
      .company_profile_wrapper .profile_block.services .card_body .textarea_block .lang_switcher {
        display: flex;
        flex-direction: column;
        margin-right: 24px; }
        .company_profile_wrapper .profile_block.services .card_body .textarea_block .lang_switcher .item {
          cursor: pointer;
          display: block;
          padding: 0.75rem 1.25rem;
          box-sizing: border-box;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.125); }
          .company_profile_wrapper .profile_block.services .card_body .textarea_block .lang_switcher .item:first-child {
            border-top-left-radius: 0.35rem;
            border-top-right-radius: 0.35rem;
            border-bottom: none; }
          .company_profile_wrapper .profile_block.services .card_body .textarea_block .lang_switcher .item:last-child {
            border-bottom-right-radius: 0.35rem;
            border-bottom-left-radius: 0.35rem; }
        .company_profile_wrapper .profile_block.services .card_body .textarea_block .lang_switcher .item.active {
          z-index: 2;
          color: #fff;
          background-color: #4e73df;
          border-color: #4e73df; }
      .company_profile_wrapper .profile_block.services .card_body .textarea_block .textarea {
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Nunito Sans', sans-serif;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 0;
        margin-bottom: 0;
        height: 260px;
        border-bottom-right-radius: 5.6px;
        border-top-right-radius: 5.6px;
        resize: none; }
        .company_profile_wrapper .profile_block.services .card_body .textarea_block .textarea:focus {
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
          border-color: #bac8f3; }
        .company_profile_wrapper .profile_block.services .card_body .textarea_block .textarea::-webkit-input-placeholder {
          color: #858796; }
        .company_profile_wrapper .profile_block.services .card_body .textarea_block .textarea::-moz-placeholder {
          color: #858796; }
        .company_profile_wrapper .profile_block.services .card_body .textarea_block .textarea::-ms-input-placeholder {
          color: #858796; }
        .company_profile_wrapper .profile_block.services .card_body .textarea_block .textarea::placeholder {
          color: #858796; }
    .company_profile_wrapper .profile_block.services .card_body .row {
      display: flex;
      flex-direction: row; }
      .company_profile_wrapper .profile_block.services .card_body .row.place {
        justify-content: space-between;
        font-size: 28px; }
      .company_profile_wrapper .profile_block.services .card_body .row.controls {
        align-items: flex-end; }
        .company_profile_wrapper .profile_block.services .card_body .row.controls button {
          height: 38px; }
      .company_profile_wrapper .profile_block.services .card_body .row .col {
        text-align: left;
        width: 100%; }
        .company_profile_wrapper .profile_block.services .card_body .row .col:first-child {
          margin-right: 24px; }
        .company_profile_wrapper .profile_block.services .card_body .row .col .error {
          bottom: 40px !important; }
    .company_profile_wrapper .profile_block.services .card_body .textarea_services {
      width: 100%;
      display: flex;
      margin-bottom: 16px; }
      .company_profile_wrapper .profile_block.services .card_body .textarea_services .side_text {
        display: flex;
        align-items: center;
        padding: 0.375rem 0.75rem;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        text-align: center;
        white-space: nowrap;
        background-color: #eaecf4;
        border: 1px solid #d1d3e2;
        border-top-left-radius: 5.6px;
        border-bottom-left-radius: 5.6px; }
      .company_profile_wrapper .profile_block.services .card_body .textarea_services .textarea {
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Nunito Sans', sans-serif;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 0;
        margin-bottom: 0;
        height: 254px;
        border-bottom-right-radius: 5.6px;
        border-top-right-radius: 5.6px;
        resize: none; }
        .company_profile_wrapper .profile_block.services .card_body .textarea_services .textarea:focus {
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
          border-color: #bac8f3; }
    .company_profile_wrapper .profile_block.services .card_body .card_body_services {
      display: flex;
      flex-direction: column;
      width: 33%;
      margin-right: 24px;
      border: 1px solid #d1d3e2;
      border-radius: 5.6px;
      overflow: hidden;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
      .company_profile_wrapper .profile_block.services .card_body .card_body_services .service {
        color: #6e707e;
        width: 100%;
        padding: 12px 20px;
        border-bottom: 1px solid #d1d3e2; }
        .company_profile_wrapper .profile_block.services .card_body .card_body_services .service.blue {
          background-color: #4e73df;
          color: #ffffff; }
          .company_profile_wrapper .profile_block.services .card_body .card_body_services .service.blue:hover {
            background-color: #4e73df; }
          .company_profile_wrapper .profile_block.services .card_body .card_body_services .service.blue:active {
            background-color: #4e73df; }
        .company_profile_wrapper .profile_block.services .card_body .card_body_services .service:last-child {
          border-bottom: unset; }
        .company_profile_wrapper .profile_block.services .card_body .card_body_services .service:hover {
          background-color: #f8f9fc; }
        .company_profile_wrapper .profile_block.services .card_body .card_body_services .service:active {
          background-color: #eaecf4; }
    .company_profile_wrapper .profile_block.services .card_body .card_body_fields {
      width: 66%;
      min-height: 500px; }
  .company_profile_wrapper .seo-edit_wrapper {
    margin: 24px 0px; }

.no_margins {
  margin: 0 !important; }

.lawyer_profile_wrapper {
  margin: 0 24px; }
  .lawyer_profile_wrapper .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 0 16px;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796; }
    .lawyer_profile_wrapper .breadcrumbs a {
      color: #4e73df;
      text-decoration: none; }
  .lawyer_profile_wrapper .button {
    color: #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #1cc88a;
    border-color: #1cc88a;
    cursor: pointer;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 5.6px;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px;
    margin-right: 3px; }
    .lawyer_profile_wrapper .button:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .lawyer_profile_wrapper .button:hover {
      background-color: #17a673; }
    .lawyer_profile_wrapper .button:active {
      background-color: #169b6b;
      border-color: #149063; }
    .lawyer_profile_wrapper .button:disabled {
      opacity: 0.7; }
      .lawyer_profile_wrapper .button:disabled:hover {
        background-color: #1cc88a; }
  .lawyer_profile_wrapper .lawyer_name {
    color: #5a5c69;
    margin-bottom: 24px;
    font-size: 28px;
    display: block; }
  .lawyer_profile_wrapper .connection_row {
    margin-bottom: 16px;
    display: flex;
    line-height: 24px;
    font-size: 16px;
    width: 65%;
    padding: 0 12px;
    position: relative; }
    .lawyer_profile_wrapper .connection_row .connection_title {
      box-sizing: border-box;
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      color: #858796;
      border: 1px solid #d1d3e2;
      background-color: #eaecf4;
      padding: 6px 12px;
      border-bottom-left-radius: 5.6px;
      border-top-left-radius: 5.6px;
      display: flex;
      align-items: center; }
    .lawyer_profile_wrapper .connection_row .popper_wrapper {
      position: absolute;
      width: 73.5%;
      top: 39px;
      left: 169px;
      z-index: 100;
      background: white;
      font-family: 'Nunito Sans', sans-serif;
      line-height: 1.5;
      color: #6e707e;
      border-radius: 0.35rem; }
      .lawyer_profile_wrapper .connection_row .popper_wrapper li {
        padding: 0 12px; }
        .lawyer_profile_wrapper .connection_row .popper_wrapper li:first-of-type {
          border-top-left-radius: 0.35rem;
          border-top-right-radius: 0.35rem; }
        .lawyer_profile_wrapper .connection_row .popper_wrapper li:last-of-type {
          border-bottom-left-radius: 0.35rem;
          border-bottom-right-radius: 0.35rem; }
        .lawyer_profile_wrapper .connection_row .popper_wrapper li:hover {
          color: #ffffff;
          background-color: #4e73df;
          cursor: pointer; }
    .lawyer_profile_wrapper .connection_row .connection_input {
      border: 1px solid #d1d3e2;
      background-color: #ffffff;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      margin-right: 8px;
      color: #858796; }
      .lawyer_profile_wrapper .connection_row .connection_input:disabled {
        background-color: #eaecf4; }
  .lawyer_profile_wrapper .blocks_wrapper {
    display: flex;
    flex-direction: row; }
  .lawyer_profile_wrapper .column {
    display: flex;
    flex-direction: column;
    flex: 0 0 75%; }
  .lawyer_profile_wrapper .profile_block {
    border: 1px solid #e3e6f0;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 5.6px;
    margin-bottom: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .lawyer_profile_wrapper .profile_block .profile_block_title {
      background-color: rgba(0, 0, 0, 0.03);
      border-top-left-radius: 5.6px;
      border-top-right-radius: 5.6px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      padding: 12px 20px;
      color: #858796;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .lawyer_profile_wrapper .profile_block .profile_block_title.profile_block_title.categories {
        background-color: unset;
        display: flex;
        justify-content: flex-end;
        border-bottom: unset;
        align-items: flex-start; }
      .lawyer_profile_wrapper .profile_block .profile_block_title .add_button {
        display: flex;
        align-items: center;
        color: #fff;
        background-color: #1cc88a;
        border-color: #1cc88a;
        border-radius: 5.6px;
        cursor: pointer;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content; }
        .lawyer_profile_wrapper .profile_block .profile_block_title .add_button div {
          display: flex;
          align-items: center;
          padding: 6px 12px;
          line-height: 24px;
          background-color: rgba(0, 0, 0, 0.15);
          height: 24px; }
        .lawyer_profile_wrapper .profile_block .profile_block_title .add_button span {
          font-size: 16px;
          padding: 6px 12px; }
        .lawyer_profile_wrapper .profile_block .profile_block_title .add_button svg {
          width: 16px; }
        .lawyer_profile_wrapper .profile_block .profile_block_title .add_button:hover {
          background-color: #17a673;
          border-color: #169b6b; }
        .lawyer_profile_wrapper .profile_block .profile_block_title .add_button:active {
          background-color: #169b6b;
          border-color: #149063; }
        .lawyer_profile_wrapper .profile_block .profile_block_title .add_button:focus {
          box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
      .lawyer_profile_wrapper .profile_block .profile_block_title .level {
        margin: 0 auto;
        font-size: 48px;
        text-transform: uppercase;
        color: white; }
    .lawyer_profile_wrapper .profile_block .profile_block_title.at {
      background-color: #e66a6a;
      line-height: 72px; }
    .lawyer_profile_wrapper .profile_block .profile_block_title.ma {
      background-color: #0a558c;
      line-height: 72px; }
    .lawyer_profile_wrapper .profile_block .profile_block_title.ba {
      background-color: #3ebd93;
      line-height: 72px; }
    .lawyer_profile_wrapper .profile_block .lawyer_banner {
      width: 100%; }
    .lawyer_profile_wrapper .profile_block .collapse_form {
      padding: 20px;
      border: 1px solid #e3e6f0;
      border-radius: 5.6px;
      box-sizing: border-box;
      margin-bottom: 16px;
      position: relative; }
      .lawyer_profile_wrapper .profile_block .collapse_form .back_error {
        right: unset;
        color: #d0021b; }
      .lawyer_profile_wrapper .profile_block .collapse_form button {
        line-height: 24px;
        font-size: 16px;
        border-radius: 5.6px;
        color: #ffffff;
        padding: 7px 12px 7px;
        background-color: #4e73df;
        cursor: pointer; }
        .lawyer_profile_wrapper .profile_block .collapse_form button:hover {
          background-color: #2e59d9;
          border-color: #2653d4; }
        .lawyer_profile_wrapper .profile_block .collapse_form button:active {
          background-color: #2653d4;
          border-color: #244ec9; }
        .lawyer_profile_wrapper .profile_block .collapse_form button:focus {
          box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5); }
      .lawyer_profile_wrapper .profile_block .collapse_form .form_select {
        display: block;
        padding: 0.375rem 0.75rem;
        margin-bottom: 16px;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .lawyer_profile_wrapper .profile_block .collapse_form .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
      .lawyer_profile_wrapper .profile_block .collapse_form .language_row {
        margin-bottom: 16px;
        display: flex;
        line-height: 24px;
        font-size: 16px; }
        .lawyer_profile_wrapper .profile_block .collapse_form .language_row .language_title {
          box-sizing: border-box;
          min-width: -webkit-fit-content;
          min-width: -moz-fit-content;
          min-width: fit-content;
          color: #858796;
          border: 1px solid #d1d3e2;
          background-color: #eaecf4;
          padding: 4px 8px;
          border-radius: 0; }
        .lawyer_profile_wrapper .profile_block .collapse_form .language_row .language_input {
          border: 1px solid #d1d3e2;
          width: calc(100% - 18px);
          height: 24px;
          padding: 4px 8px;
          color: #6e707e;
          font-size: 14px; }
    .lawyer_profile_wrapper .profile_block .card_body {
      padding: 20px;
      background-color: #ffffff; }
      .lawyer_profile_wrapper .profile_block .card_body span {
        color: #858796; }
      .lawyer_profile_wrapper .profile_block .card_body .flag-icon {
        border-radius: 50%;
        margin: 0 70px 0 auto; }
      .lawyer_profile_wrapper .profile_block .card_body .input_title {
        margin-bottom: 8px;
        margin-top: 16px;
        display: block; }
      .lawyer_profile_wrapper .profile_block .card_body .card_items_row {
        display: flex;
        border-top: 1px solid #e3e6f0;
        color: #858796;
        font-size: 16px;
        font-weight: 800;
        justify-content: flex-start;
        padding: 12px 6px; }
        .lawyer_profile_wrapper .profile_block .card_body .card_items_row span {
          display: flex;
          align-items: center; }
          .lawyer_profile_wrapper .profile_block .card_body .card_items_row span div {
            margin: 0 auto; }
        .lawyer_profile_wrapper .profile_block .card_body .card_items_row:nth-child(even) {
          background-color: rgba(0, 0, 0, 0.05); }
      .lawyer_profile_wrapper .profile_block .card_body .textarea_block {
        width: 100%;
        display: flex; }
        .lawyer_profile_wrapper .profile_block .card_body .textarea_block .lang_switcher {
          display: flex;
          flex-direction: column;
          margin-right: 24px; }
          .lawyer_profile_wrapper .profile_block .card_body .textarea_block .lang_switcher .item {
            cursor: pointer;
            display: block;
            padding: 0.75rem 1.25rem;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.125); }
            .lawyer_profile_wrapper .profile_block .card_body .textarea_block .lang_switcher .item:first-child {
              border-top-left-radius: 0.35rem;
              border-top-right-radius: 0.35rem;
              border-bottom: none; }
            .lawyer_profile_wrapper .profile_block .card_body .textarea_block .lang_switcher .item:last-child {
              border-bottom-right-radius: 0.35rem;
              border-bottom-left-radius: 0.35rem; }
          .lawyer_profile_wrapper .profile_block .card_body .textarea_block .lang_switcher .item.active {
            z-index: 2;
            color: #fff;
            background-color: #4e73df;
            border-color: #4e73df; }
        .lawyer_profile_wrapper .profile_block .card_body .textarea_block .textarea {
          display: block;
          width: 100%;
          padding: 6px 12px;
          font-size: 16px;
          font-weight: 400;
          font-family: 'Nunito Sans', sans-serif;
          line-height: 1.5;
          color: #6e707e;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #d1d3e2;
          -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          margin-top: 0;
          margin-bottom: 0;
          height: 260px;
          border-bottom-right-radius: 5.6px;
          border-top-right-radius: 5.6px;
          resize: none; }
          .lawyer_profile_wrapper .profile_block .card_body .textarea_block .textarea:focus {
            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
            border-color: #bac8f3; }
          .lawyer_profile_wrapper .profile_block .card_body .textarea_block .textarea::-webkit-input-placeholder {
            color: #858796; }
          .lawyer_profile_wrapper .profile_block .card_body .textarea_block .textarea::-moz-placeholder {
            color: #858796; }
          .lawyer_profile_wrapper .profile_block .card_body .textarea_block .textarea::-ms-input-placeholder {
            color: #858796; }
          .lawyer_profile_wrapper .profile_block .card_body .textarea_block .textarea::placeholder {
            color: #858796; }
      .lawyer_profile_wrapper .profile_block .card_body .textarea_categories {
        width: 100%;
        display: flex;
        margin-bottom: 16px; }
        .lawyer_profile_wrapper .profile_block .card_body .textarea_categories .side_text {
          display: flex;
          align-items: center;
          padding: 0.375rem 0.75rem;
          margin-bottom: 0;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #6e707e;
          text-align: center;
          white-space: nowrap;
          background-color: #eaecf4;
          border: 1px solid #d1d3e2;
          border-top-left-radius: 5.6px;
          border-bottom-left-radius: 5.6px; }
        .lawyer_profile_wrapper .profile_block .card_body .textarea_categories .textarea {
          display: block;
          width: 100%;
          padding: 6px 12px;
          font-size: 16px;
          font-weight: 400;
          font-family: 'Nunito Sans', sans-serif;
          line-height: 1.5;
          color: #6e707e;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #d1d3e2;
          -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          margin-top: 0;
          margin-bottom: 0;
          height: 254px;
          border-bottom-right-radius: 5.6px;
          border-top-right-radius: 5.6px;
          resize: none; }
          .lawyer_profile_wrapper .profile_block .card_body .textarea_categories .textarea:focus {
            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
            border-color: #bac8f3; }
      .lawyer_profile_wrapper .profile_block .card_body .row {
        display: flex;
        flex-direction: row; }
        .lawyer_profile_wrapper .profile_block .card_body .row.place {
          justify-content: space-between;
          font-size: 28px; }
        .lawyer_profile_wrapper .profile_block .card_body .row.controls {
          align-items: flex-end; }
          .lawyer_profile_wrapper .profile_block .card_body .row.controls button {
            height: 38px; }
        .lawyer_profile_wrapper .profile_block .card_body .row .col {
          text-align: left;
          width: 100%; }
          .lawyer_profile_wrapper .profile_block .card_body .row .col:first-child {
            margin-right: 24px; }
          .lawyer_profile_wrapper .profile_block .card_body .row .col .error {
            bottom: 40px !important; }
      .lawyer_profile_wrapper .profile_block .card_body .card_field {
        display: flex;
        margin-bottom: 8px; }
        .lawyer_profile_wrapper .profile_block .card_body .card_field .input_name {
          display: flex;
          align-items: center;
          padding: 6px 12px;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          color: #6e707e;
          text-align: center;
          background-color: #eaecf4;
          border: 1px solid #d1d3e2;
          border-radius: 5.6px; }
      .lawyer_profile_wrapper .profile_block .card_body .form_select {
        display: block;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .lawyer_profile_wrapper .profile_block .card_body .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
      .lawyer_profile_wrapper .profile_block .card_body .add_input {
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
        .lawyer_profile_wrapper .profile_block .card_body .add_input::-webkit-input-placeholder {
          color: #858796;
          display: flex;
          z-index: 1; }
        .lawyer_profile_wrapper .profile_block .card_body .add_input::-moz-placeholder {
          color: #858796;
          display: flex;
          z-index: 1; }
        .lawyer_profile_wrapper .profile_block .card_body .add_input::-ms-input-placeholder {
          color: #858796;
          display: flex;
          z-index: 1; }
        .lawyer_profile_wrapper .profile_block .card_body .add_input::placeholder {
          color: #858796;
          display: flex;
          z-index: 1; }
        .lawyer_profile_wrapper .profile_block .card_body .add_input:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
      .lawyer_profile_wrapper .profile_block .card_body .add_form_city {
        display: flex;
        flex-direction: row; }
        .lawyer_profile_wrapper .profile_block .card_body .add_form_city .names {
          width: calc(25%);
          margin-left: 10px; }
        .lawyer_profile_wrapper .profile_block .card_body .add_form_city .names.first {
          width: calc(50% + 10px);
          margin-left: 0; }
      .lawyer_profile_wrapper .profile_block .card_body .card_items_row {
        text-align: center; }
        .lawyer_profile_wrapper .profile_block .card_body .card_items_row > div:first-child {
          width: 15%; }
        .lawyer_profile_wrapper .profile_block .card_body .card_items_row > div:nth-child(2) {
          width: 45%; }
        .lawyer_profile_wrapper .profile_block .card_body .card_items_row > div:nth-child(3) {
          width: 15%; }
        .lawyer_profile_wrapper .profile_block .card_body .card_items_row > div:last-child {
          width: 25%;
          margin: 0 3px; }
      .lawyer_profile_wrapper .profile_block .card_body .card_items_row.positions {
        text-align: center; }
        .lawyer_profile_wrapper .profile_block .card_body .card_items_row.positions > div:first-child {
          width: 15%; }
        .lawyer_profile_wrapper .profile_block .card_body .card_items_row.positions > div:nth-child(2) {
          width: 55%; }
        .lawyer_profile_wrapper .profile_block .card_body .card_items_row.positions > div:last-child {
          width: 30%;
          margin: 0 3px; }
      .lawyer_profile_wrapper .profile_block .card_body .default_row {
        font-weight: 400 !important;
        align-items: center; }
        .lawyer_profile_wrapper .profile_block .card_body .default_row > div {
          justify-content: center !important;
          align-items: center !important; }
          .lawyer_profile_wrapper .profile_block .card_body .default_row > div:nth-child(3) {
            display: flex;
            justify-content: center !important; }
            .lawyer_profile_wrapper .profile_block .card_body .default_row > div:nth-child(3) .table_circle {
              height: 15px;
              width: 15px;
              background-color: #bbbbbb;
              border-radius: 50%;
              margin-right: 11px; }
            .lawyer_profile_wrapper .profile_block .card_body .default_row > div:nth-child(3) .active {
              background-color: green; }
      .lawyer_profile_wrapper .profile_block .card_body .header_row {
        background-color: #ffffff !important; }
      .lawyer_profile_wrapper .profile_block .card_body .footer_row {
        background-color: #ffffff !important;
        align-items: flex-start; }
      .lawyer_profile_wrapper .profile_block .card_body .card_controls {
        display: flex; }
      .lawyer_profile_wrapper .profile_block .card_body .language_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-bottom: none;
        color: #858796; }
        .lawyer_profile_wrapper .profile_block .card_body .language_box:first-child {
          border-top-left-radius: 5.6px;
          border-top-right-radius: 5.6px; }
        .lawyer_profile_wrapper .profile_block .card_body .language_box:last-child {
          border-bottom-left-radius: 5.6px;
          border-bottom-right-radius: 5.6px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .lawyer_profile_wrapper .card_footer {
    padding: 12px 20px;
    background-color: #f8f9fc;
    border-top: 1px solid #e3e6f0; }
    .lawyer_profile_wrapper .card_footer .pic_err {
      margin-bottom: 16px;
      display: flex;
      color: #d0021b; }
    .lawyer_profile_wrapper .card_footer .upload_file {
      width: 100%;
      position: relative; }
      .lawyer_profile_wrapper .card_footer .upload_file .upload_input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        margin: 0;
        opacity: 0; }
      .lawyer_profile_wrapper .card_footer .upload_file .upload_label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        padding: 6px 80px 6px 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .lawyer_profile_wrapper .card_footer .upload_file .upload_label::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 3;
          display: block;
          padding: 0.375rem 0.75rem;
          line-height: 1.5;
          color: #6e707e;
          content: 'Browse';
          background-color: #eaecf4;
          border-left: inherit;
          border-radius: 0 0.35rem 0.35rem 0; }
  .lawyer_profile_wrapper .profile_block:first-child {
    width: 100%;
    margin-right: 24px; }
  .lawyer_profile_wrapper .profile_block:last-child {
    width: 100%; }
  .lawyer_profile_wrapper .profile_block.access_level .profile_block_title {
    border: none;
    background-color: #ffffff;
    font-size: 24px; }
  .lawyer_profile_wrapper .profile_block.access_level .card_body {
    display: flex;
    align-items: center;
    justify-content: center; }
  .lawyer_profile_wrapper .profile_block.access_level .btn_wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 35px 12px 20px; }
    .lawyer_profile_wrapper .profile_block.access_level .btn_wrapper .button {
      margin: 0; }
  .lawyer_profile_wrapper .profile_block.access_level label {
    font-size: 48px;
    color: #ffffff;
    padding: 8px;
    text-transform: uppercase;
    margin-right: 16px; }
    .lawyer_profile_wrapper .profile_block.access_level label input {
      width: 32px;
      height: 32px;
      margin-right: 5px; }
    .lawyer_profile_wrapper .profile_block.access_level label:first-child {
      background-color: #e66a6a; }
    .lawyer_profile_wrapper .profile_block.access_level label:nth-child(2) {
      background-color: #0a558c; }
    .lawyer_profile_wrapper .profile_block.access_level label:last-child {
      background-color: #3ebd93; }
  .lawyer_profile_wrapper .profile_block.presentation .profile_block_title {
    border: none;
    background-color: #ffffff;
    font-size: 24px; }
  .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper {
    display: flex;
    flex-direction: row; }
    .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper .lang_switcher {
      display: flex;
      flex-direction: column;
      margin-right: 24px; }
      .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper .lang_switcher .item {
        cursor: pointer;
        display: block;
        padding: 0.75rem 1.25rem;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125); }
        .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper .lang_switcher .item:first-child {
          border-top-left-radius: 0.35rem;
          border-top-right-radius: 0.35rem;
          border-bottom: none; }
        .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper .lang_switcher .item:last-child {
          border-bottom-right-radius: 0.35rem;
          border-bottom-left-radius: 0.35rem; }
      .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper .lang_switcher .item.active {
        z-index: 2;
        color: #fff;
        background-color: #4e73df;
        border-color: #4e73df; }
    .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper .textarea {
      height: 192px; }
      .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper .textarea::-webkit-input-placeholder {
        color: #858796; }
      .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper .textarea::-moz-placeholder {
        color: #858796; }
      .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper .textarea::-ms-input-placeholder {
        color: #858796; }
      .lawyer_profile_wrapper .profile_block.presentation .resume_body .field_wrapper .textarea::placeholder {
        color: #858796; }
  .lawyer_profile_wrapper .profile_block.presentation .btn_wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 35px 12px 20px; }
    .lawyer_profile_wrapper .profile_block.presentation .btn_wrapper .button {
      margin: 0; }
  .lawyer_profile_wrapper .profile_block.presentation label {
    font-size: 48px;
    color: #ffffff;
    padding: 8px;
    text-transform: uppercase;
    margin-right: 16px; }
    .lawyer_profile_wrapper .profile_block.presentation label input {
      width: 32px;
      height: 32px;
      margin-right: 5px; }
    .lawyer_profile_wrapper .profile_block.presentation label:first-child {
      background-color: #e66a6a; }
    .lawyer_profile_wrapper .profile_block.presentation label:nth-child(2) {
      background-color: #0a558c; }
    .lawyer_profile_wrapper .profile_block.presentation label:last-child {
      background-color: #3ebd93; }
  .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 12px 20px; }
    .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form {
      display: flex;
      flex-direction: column;
      padding: 12px 20px;
      border: 1px solid #e3e6f0;
      border-radius: 5.6px;
      box-sizing: border-box;
      margin-bottom: 16px;
      position: relative;
      width: 100%; }
      .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .back_error {
        right: 10px;
        color: #d0021b; }
      .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .block_field {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column; }
        .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .block_field .field_title {
          color: #858796; }
          .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .block_field .field_title.big {
            font-size: 28px; }
      .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row {
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row .add_input {
          margin-top: 8px;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #6e707e;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #d1d3e2;
          border-radius: 0.35rem;
          box-sizing: border-box;
          -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
          .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row .add_input::-webkit-input-placeholder {
            color: #858796;
            opacity: 0.5; }
          .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row .add_input::-moz-placeholder {
            color: #858796;
            opacity: 0.5; }
          .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row .add_input::-ms-input-placeholder {
            color: #858796;
            opacity: 0.5; }
          .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row .add_input::placeholder {
            color: #858796;
            opacity: 0.5; }
          .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row .add_input:focus {
            border-color: #bac8f3;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
        .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row .placeholder {
          position: absolute;
          top: 157px;
          left: 33px;
          color: #858796;
          opacity: 0.5;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none; }
        .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row .block_field {
          width: 35%;
          margin-right: auto; }
        .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row .card_body {
          padding: unset;
          width: 100%;
          flex-direction: column; }
          .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .collapse_row .card_body .textarea {
            border-radius: 5.6px;
            margin-bottom: 8px; }
      .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form button {
        line-height: 24px;
        font-size: 16px;
        border-radius: 5.6px;
        color: #ffffff;
        padding: 7px 12px 7px;
        background-color: #1cc88a;
        cursor: pointer;
        width: 100%; }
        .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form button:hover {
          background-color: #17a673;
          border-color: #169b6b; }
        .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form button:active {
          background-color: #169b6b;
          border-color: #149063; }
        .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form button:focus {
          box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
        .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form button:disabled {
          opacity: 0.5; }
      .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .form_select {
        display: block;
        padding: 0.375rem 0.75rem;
        margin-bottom: 16px;
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .lawyer_profile_wrapper .profile_block.categories .collapse_wrapper .collapse_form .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
  .lawyer_profile_wrapper .profile_block.categories .card_body {
    display: flex;
    flex-direction: row; }
    .lawyer_profile_wrapper .profile_block.categories .card_body .card_body_categories {
      display: flex;
      flex-direction: column;
      width: 33%;
      margin-right: 24px;
      border: 1px solid #d1d3e2;
      border-radius: 5.6px;
      overflow: hidden;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
      .lawyer_profile_wrapper .profile_block.categories .card_body .card_body_categories .category {
        color: #6e707e;
        width: 100%;
        padding: 12px 20px;
        border-bottom: 1px solid #d1d3e2; }
        .lawyer_profile_wrapper .profile_block.categories .card_body .card_body_categories .category.blue {
          background-color: #4e73df;
          color: #ffffff; }
          .lawyer_profile_wrapper .profile_block.categories .card_body .card_body_categories .category.blue:hover {
            background-color: #4e73df; }
          .lawyer_profile_wrapper .profile_block.categories .card_body .card_body_categories .category.blue:active {
            background-color: #4e73df; }
        .lawyer_profile_wrapper .profile_block.categories .card_body .card_body_categories .category:last-child {
          border-bottom: unset; }
        .lawyer_profile_wrapper .profile_block.categories .card_body .card_body_categories .category:hover {
          background-color: #f8f9fc; }
        .lawyer_profile_wrapper .profile_block.categories .card_body .card_body_categories .category:active {
          background-color: #eaecf4; }
    .lawyer_profile_wrapper .profile_block.categories .card_body .card_body_fields {
      width: 66%;
      min-height: 500px; }
  .lawyer_profile_wrapper .seo-edit_wrapper {
    margin: 24px 0px; }

@media only screen and (max-width: 1350px) {
  .lawyer_profile_wrapper .blocks_wrapper.switcher {
    flex-direction: column; } }

.data_card_wrapper_table {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 0 24px 0;
  background-color: #FFFFFF; }
  .data_card_wrapper_table .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #858796;
    font-weight: 400;
    font-size: 28px; }
  .data_card_wrapper_table .card_body {
    padding: 20px; }
    .data_card_wrapper_table .card_body .card_buttons_row {
      display: flex; }
      .data_card_wrapper_table .card_body .card_buttons_row .green_button {
        margin: 0 15px 20px 0;
        display: flex;
        justify-content: center;
        align-items: center; }
    .data_card_wrapper_table .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .data_card_wrapper_table .card_body .card_select_row span {
        color: #858796;
        margin: 0 15px 0 0; }
        .data_card_wrapper_table .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .data_card_wrapper_table .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .data_card_wrapper_table .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .data_card_wrapper_table .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .data_card_wrapper_table .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .data_card_wrapper_table .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .data_card_wrapper_table .card_body .card_search_row .search_input {
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .data_card_wrapper_table .card_body .card_controls {
      display: flex; }
    .data_card_wrapper_table .card_body .card_table {
      overflow: auto; }
    .data_card_wrapper_table .card_body .card_items_row.row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .data_card_wrapper_table .card_body .card_items_row.row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .data_card_wrapper_table .card_body .card_items_row.row > div .table_div {
          margin: 0 auto; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:first-child {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(2) {
          width: 15%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(3) {
          width: 10%;
          text-align: center; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(4) {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(5) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(6) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(7) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(8) {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:last-child {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .data_card_wrapper_table .card_body .card_items_row.row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .data_card_wrapper_table .card_body .card_items_row.row > div .arrows .active_arrow {
            opacity: 0.5; }
      .data_card_wrapper_table .card_body .card_items_row.row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .data_card_wrapper_table .card_body .card_items_row.row:last-child {
        background-color: #FFFFFF; }
        .data_card_wrapper_table .card_body .card_items_row.row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .data_card_wrapper_table .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .data_card_wrapper_table .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .data_card_wrapper_table .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .data_card_wrapper_table .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .data_card_wrapper_table .card_body .header_row {
      background-color: #FFFFFF !important; }
    .data_card_wrapper_table .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .data_card_wrapper_table .card_body .default_row.row {
      font-weight: 400 !important; }
      .data_card_wrapper_table .card_body .default_row.row > div {
        justify-content: center !important;
        align-items: center !important; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(5) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(6) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(7) {
          display: flex;
          flex-direction: column;
          justify-content: center !important; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(8) .table_circle {
          height: 15px;
          width: 15px;
          background-color: orange;
          border-radius: 50%;
          margin-right: 11px; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(8) .active {
          background-color: green; }

.dialog_footer {
  flex-direction: row !important; }

.category_profile_wrapper {
  margin: 0 24px; }
  .category_profile_wrapper .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 0 16px;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796; }
    .category_profile_wrapper .breadcrumbs a {
      color: #4e73df;
      text-decoration: none; }
  .category_profile_wrapper .button {
    color: #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #1cc88a;
    border-color: #1cc88a;
    cursor: pointer;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 5.6px;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px; }
    .category_profile_wrapper .button:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .category_profile_wrapper .button:hover {
      background-color: #17a673; }
    .category_profile_wrapper .button:active {
      background-color: #169b6b;
      border-color: #149063; }
    .category_profile_wrapper .button:disabled {
      opacity: 0.7; }
      .category_profile_wrapper .button:disabled:hover {
        background-color: #1cc88a; }
  .category_profile_wrapper .category_name {
    color: #5a5c69;
    margin-bottom: 24px;
    font-size: 28px;
    display: block; }
  .category_profile_wrapper .connection_row {
    margin-bottom: 16px;
    display: flex;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    max-width: 66%;
    padding: 0 12px;
    position: relative; }
    .category_profile_wrapper .connection_row .connection_title {
      box-sizing: border-box;
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      color: #858796;
      border: 1px solid #d1d3e2;
      background-color: #eaecf4;
      padding: 6px 12px;
      border-bottom-left-radius: 5.6px;
      border-top-left-radius: 5.6px;
      display: flex;
      align-items: center; }
    .category_profile_wrapper .connection_row .popper_wrapper {
      position: absolute;
      width: 73.5%;
      top: 39px;
      left: 169px;
      z-index: 100;
      background: white;
      font-family: 'Nunito Sans', sans-serif;
      line-height: 1.5;
      color: #6e707e;
      border-radius: 0.35rem; }
      .category_profile_wrapper .connection_row .popper_wrapper li {
        padding: 0 12px; }
        .category_profile_wrapper .connection_row .popper_wrapper li:first-of-type {
          border-top-left-radius: 0.35rem;
          border-top-right-radius: 0.35rem; }
        .category_profile_wrapper .connection_row .popper_wrapper li:last-of-type {
          border-bottom-left-radius: 0.35rem;
          border-bottom-right-radius: 0.35rem; }
        .category_profile_wrapper .connection_row .popper_wrapper li:hover {
          color: #ffffff;
          background-color: #4e73df;
          cursor: pointer; }
    .category_profile_wrapper .connection_row .connection_input {
      border: 1px solid #d1d3e2;
      border-top-right-radius: 5.6px;
      border-bottom-right-radius: 5.6px;
      background-color: #ffffff;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      margin-right: 8px;
      color: #858796; }
      .category_profile_wrapper .connection_row .connection_input:disabled {
        background-color: #eaecf4; }
  .category_profile_wrapper .blocks_wrapper {
    display: flex;
    flex-direction: row; }
  .category_profile_wrapper .column {
    display: flex;
    flex-direction: column;
    flex: 0 0 75%;
    max-width: 75%; }
  .category_profile_wrapper .profile_block {
    border: 1px solid #e3e6f0;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 5.6px;
    margin-bottom: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .category_profile_wrapper .profile_block.image {
      margin-right: 24px; }
    .category_profile_wrapper .profile_block .profile_block_title {
      border-top-left-radius: 5.6px;
      border-top-right-radius: 5.6px;
      padding: 12px 20px;
      color: #858796;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 28px; }
      .category_profile_wrapper .profile_block .profile_block_title .add_button {
        display: flex;
        align-items: center;
        color: #fff;
        background-color: #1cc88a;
        border-color: #1cc88a;
        border-radius: 5.6px;
        cursor: pointer; }
        .category_profile_wrapper .profile_block .profile_block_title .add_button div {
          display: flex;
          align-items: center;
          padding: 6px 12px;
          line-height: 24px;
          background-color: rgba(0, 0, 0, 0.15);
          height: 24px; }
        .category_profile_wrapper .profile_block .profile_block_title .add_button span {
          font-size: 16px;
          padding: 6px 12px; }
        .category_profile_wrapper .profile_block .profile_block_title .add_button svg {
          width: 16px; }
        .category_profile_wrapper .profile_block .profile_block_title .add_button:hover {
          background-color: #17a673;
          border-color: #169b6b; }
        .category_profile_wrapper .profile_block .profile_block_title .add_button:active {
          background-color: #169b6b;
          border-color: #149063; }
        .category_profile_wrapper .profile_block .profile_block_title .add_button:focus {
          box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
      .category_profile_wrapper .profile_block .profile_block_title .level {
        margin: 0 auto;
        font-size: 48px;
        text-transform: uppercase;
        color: white; }
    .category_profile_wrapper .profile_block .profile_block_title.at {
      background-color: #e66a6a;
      line-height: 72px; }
    .category_profile_wrapper .profile_block .profile_block_title.ma {
      background-color: #0a558c;
      line-height: 72px; }
    .category_profile_wrapper .profile_block .profile_block_title.ba {
      background-color: #3ebd93;
      line-height: 72px; }
    .category_profile_wrapper .profile_block .category_banner {
      width: 100%; }
    .category_profile_wrapper .profile_block .card_body {
      padding: 20px;
      background-color: #ffffff; }
      .category_profile_wrapper .profile_block .card_body span {
        color: #858796; }
      .category_profile_wrapper .profile_block .card_body .flag-icon {
        border-radius: 50%;
        margin: 0 20px 0 auto; }
      .category_profile_wrapper .profile_block .card_body .input_title {
        margin-bottom: 8px;
        margin-top: 16px;
        display: block; }
      .category_profile_wrapper .profile_block .card_body .card_items_row {
        display: flex;
        border-top: 1px solid #e3e6f0;
        color: #858796;
        font-size: 16px;
        font-weight: 800;
        justify-content: flex-start;
        padding: 12px 6px; }
        .category_profile_wrapper .profile_block .card_body .card_items_row span {
          display: flex;
          align-items: center; }
          .category_profile_wrapper .profile_block .card_body .card_items_row span div {
            margin: 0 auto; }
        .category_profile_wrapper .profile_block .card_body .card_items_row:nth-child(even) {
          background-color: rgba(0, 0, 0, 0.05); }
      .category_profile_wrapper .profile_block .card_body .field_wrapper {
        display: flex;
        flex-direction: row; }
        .category_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher {
          display: flex;
          flex-direction: column;
          margin-right: 24px; }
          .category_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item {
            cursor: pointer;
            display: block;
            padding: 0.75rem 1.25rem;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.125); }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item:first-child {
              border-top-left-radius: 0.35rem;
              border-top-right-radius: 0.35rem;
              border-bottom: none; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item:last-child {
              border-bottom-right-radius: 0.35rem;
              border-bottom-left-radius: 0.35rem; }
          .category_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item.active {
            z-index: 2;
            color: #fff;
            background-color: #4e73df;
            border-color: #4e73df; }
        .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block {
          width: 100%;
          display: flex; }
          .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea {
            display: block;
            width: 100%;
            padding: 6px 12px;
            font-size: 16px;
            font-weight: 400;
            font-family: 'Nunito Sans', sans-serif;
            line-height: 1.5;
            color: #6e707e;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #d1d3e2;
            -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            margin-top: 0;
            margin-bottom: 0;
            height: 260px;
            border-bottom-right-radius: 5.6px;
            border-top-right-radius: 5.6px;
            resize: none; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea:focus {
              box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
              border-color: #bac8f3; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea::-webkit-input-placeholder {
              color: #858796; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea::-moz-placeholder {
              color: #858796; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea::-ms-input-placeholder {
              color: #858796; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea::placeholder {
              color: #858796; }
      .category_profile_wrapper .profile_block .card_body .card_field {
        display: flex;
        margin-bottom: 8px; }
        .category_profile_wrapper .profile_block .card_body .card_field .input_name {
          display: flex;
          align-items: center;
          padding: 6px 12px;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          color: #6e707e;
          text-align: center;
          background-color: #eaecf4;
          border: 1px solid #d1d3e2;
          border-radius: 5.6px; }
      .category_profile_wrapper .profile_block .card_body .form_select {
        display: block;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .category_profile_wrapper .profile_block .card_body .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
      .category_profile_wrapper .profile_block .card_body .add_input {
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
        .category_profile_wrapper .profile_block .card_body .add_input::-webkit-input-placeholder {
          color: #858796; }
        .category_profile_wrapper .profile_block .card_body .add_input::-moz-placeholder {
          color: #858796; }
        .category_profile_wrapper .profile_block .card_body .add_input::-ms-input-placeholder {
          color: #858796; }
        .category_profile_wrapper .profile_block .card_body .add_input::placeholder {
          color: #858796; }
        .category_profile_wrapper .profile_block .card_body .add_input:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
      .category_profile_wrapper .profile_block .card_body .add_form_city {
        display: flex;
        flex-direction: row; }
        .category_profile_wrapper .profile_block .card_body .add_form_city .names {
          width: calc(25%);
          margin-left: 10px; }
        .category_profile_wrapper .profile_block .card_body .add_form_city .names.first {
          width: calc(50% + 10px);
          margin-left: 0; }
      .category_profile_wrapper .profile_block .card_body .card_items_row {
        text-align: center; }
        .category_profile_wrapper .profile_block .card_body .card_items_row > div:first-child {
          width: 15%; }
        .category_profile_wrapper .profile_block .card_body .card_items_row > div:nth-child(2) {
          width: 45%; }
        .category_profile_wrapper .profile_block .card_body .card_items_row > div:nth-child(3) {
          width: 15%; }
        .category_profile_wrapper .profile_block .card_body .card_items_row > div:last-child {
          width: 25%;
          margin: 0 3px; }
      .category_profile_wrapper .profile_block .card_body .card_items_row.positions {
        text-align: center; }
        .category_profile_wrapper .profile_block .card_body .card_items_row.positions > div:first-child {
          width: 15%; }
        .category_profile_wrapper .profile_block .card_body .card_items_row.positions > div:nth-child(2) {
          width: 55%; }
        .category_profile_wrapper .profile_block .card_body .card_items_row.positions > div:last-child {
          width: 30%;
          margin: 0 3px; }
      .category_profile_wrapper .profile_block .card_body .default_row {
        font-weight: 400 !important;
        align-items: center; }
        .category_profile_wrapper .profile_block .card_body .default_row > div {
          justify-content: center !important;
          align-items: center !important; }
          .category_profile_wrapper .profile_block .card_body .default_row > div:nth-child(3) {
            display: flex;
            justify-content: center !important; }
            .category_profile_wrapper .profile_block .card_body .default_row > div:nth-child(3) .table_circle {
              height: 15px;
              width: 15px;
              background-color: #bbbbbb;
              border-radius: 50%;
              margin-right: 11px; }
            .category_profile_wrapper .profile_block .card_body .default_row > div:nth-child(3) .active {
              background-color: green; }
      .category_profile_wrapper .profile_block .card_body .header_row {
        background-color: #ffffff !important; }
      .category_profile_wrapper .profile_block .card_body .footer_row {
        background-color: #ffffff !important;
        align-items: flex-start; }
      .category_profile_wrapper .profile_block .card_body .card_controls {
        display: flex; }
      .category_profile_wrapper .profile_block .card_body .language_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-bottom: none;
        color: #858796; }
        .category_profile_wrapper .profile_block .card_body .language_box:first-of-type {
          border-top-left-radius: 5.6px;
          border-top-right-radius: 5.6px; }
        .category_profile_wrapper .profile_block .card_body .language_box:last-of-type {
          border-bottom-left-radius: 5.6px;
          border-bottom-right-radius: 5.6px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .category_profile_wrapper .langs {
    width: 50%; }
  .category_profile_wrapper .card_footer {
    padding: 12px 20px;
    background-color: #f8f9fc;
    border-top: 1px solid #e3e6f0; }
    .category_profile_wrapper .card_footer .pic_err {
      margin-bottom: 16px;
      display: flex;
      color: #d0021b; }
    .category_profile_wrapper .card_footer .upload_file {
      width: 100%;
      position: relative; }
      .category_profile_wrapper .card_footer .upload_file .upload_input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        margin: 0;
        opacity: 0; }
      .category_profile_wrapper .card_footer .upload_file .upload_label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        padding: 6px 80px 6px 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .category_profile_wrapper .card_footer .upload_file .upload_label::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 3;
          display: block;
          padding: 0.375rem 0.75rem;
          line-height: 1.5;
          color: #6e707e;
          content: 'Browse';
          background-color: #eaecf4;
          border-left: inherit;
          border-radius: 0 0.35rem 0.35rem 0; }
  .category_profile_wrapper .profile_block.access_level .profile_block_title {
    border: none;
    background-color: #ffffff;
    font-size: 24px; }
  .category_profile_wrapper .profile_block.access_level .card_body {
    display: flex;
    align-items: center;
    justify-content: center; }
  .category_profile_wrapper .profile_block.access_level .btn_wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 35px 12px 20px; }
    .category_profile_wrapper .profile_block.access_level .btn_wrapper .button {
      margin: 0; }
  .category_profile_wrapper .profile_block.access_level label {
    font-size: 48px;
    color: #ffffff;
    padding: 8px;
    text-transform: uppercase;
    margin-right: 16px; }
    .category_profile_wrapper .profile_block.access_level label input {
      width: 32px;
      height: 32px;
      margin-right: 5px; }
    .category_profile_wrapper .profile_block.access_level label:first-child {
      background-color: #e66a6a; }
    .category_profile_wrapper .profile_block.access_level label:nth-child(2) {
      background-color: #0a558c; }
    .category_profile_wrapper .profile_block.access_level label:last-child {
      background-color: #3ebd93; }
  .category_profile_wrapper .profile_block.resume .profile_block_title {
    border: none;
    background-color: #ffffff;
    font-size: 24px; }
  .category_profile_wrapper .profile_block.resume .card_body {
    display: flex;
    flex-direction: column; }
    .category_profile_wrapper .profile_block.resume .card_body .textarea {
      height: 192px; }
    .category_profile_wrapper .profile_block.resume .card_body .textarea::-webkit-input-placeholder {
      color: #858796; }
    .category_profile_wrapper .profile_block.resume .card_body .textarea::-moz-placeholder {
      color: #858796; }
    .category_profile_wrapper .profile_block.resume .card_body .textarea::-ms-input-placeholder {
      color: #858796; }
    .category_profile_wrapper .profile_block.resume .card_body .textarea::placeholder {
      color: #858796; }
  .category_profile_wrapper .profile_block.resume .btn_wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 35px 12px 20px; }
    .category_profile_wrapper .profile_block.resume .btn_wrapper .button {
      margin: 0; }
  .category_profile_wrapper .profile_block.resume label {
    font-size: 48px;
    color: #ffffff;
    padding: 8px;
    text-transform: uppercase;
    margin-right: 16px; }
    .category_profile_wrapper .profile_block.resume label input {
      width: 32px;
      height: 32px;
      margin-right: 5px; }
    .category_profile_wrapper .profile_block.resume label:first-child {
      background-color: #e66a6a; }
    .category_profile_wrapper .profile_block.resume label:nth-child(2) {
      background-color: #0a558c; }
    .category_profile_wrapper .profile_block.resume label:last-child {
      background-color: #3ebd93; }
  .category_profile_wrapper .seo-edit_wrapper {
    margin: 24px 0px; }

.add_form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e3e6f0;
  border-radius: 5.6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #858796;
  width: 100%;
  box-sizing: border-box; }
  .add_form .relative_position span:first-child {
    border-radius: 50%;
    margin-right: 5px; }
  .add_form .add_input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 8px;
    margin-bottom: 16px; }
    .add_form .add_input:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }

@media only screen and (max-width: 1200px) {
  .category_profile_wrapper .blocks_wrapper:last-child {
    flex-direction: column; } }

.data_card_wrapper_table {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 0 24px 0;
  background-color: #FFFFFF; }
  .data_card_wrapper_table .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #858796;
    font-weight: 400;
    font-size: 28px; }
  .data_card_wrapper_table .card_body {
    padding: 20px; }
    .data_card_wrapper_table .card_body .card_buttons_row {
      display: flex; }
      .data_card_wrapper_table .card_body .card_buttons_row .green_button {
        margin: 0 15px 20px 0;
        display: flex;
        justify-content: center;
        align-items: center; }
    .data_card_wrapper_table .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .data_card_wrapper_table .card_body .card_select_row span {
        color: #858796;
        margin: 0 15px 0 0; }
        .data_card_wrapper_table .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .data_card_wrapper_table .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .data_card_wrapper_table .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .data_card_wrapper_table .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .data_card_wrapper_table .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .data_card_wrapper_table .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .data_card_wrapper_table .card_body .card_search_row .search_input {
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .data_card_wrapper_table .card_body .card_controls {
      display: flex; }
    .data_card_wrapper_table .card_body .card_table {
      overflow: auto; }
    .data_card_wrapper_table .card_body .card_items_row.row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .data_card_wrapper_table .card_body .card_items_row.row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .data_card_wrapper_table .card_body .card_items_row.row > div .table_div {
          margin: 0 auto; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:first-child {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(2) {
          width: 15%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(3) {
          width: 10%;
          text-align: center; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(4) {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(5) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(6) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(7) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(8) {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:last-child {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .data_card_wrapper_table .card_body .card_items_row.row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .data_card_wrapper_table .card_body .card_items_row.row > div .arrows .active_arrow {
            opacity: 0.5; }
      .data_card_wrapper_table .card_body .card_items_row.row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .data_card_wrapper_table .card_body .card_items_row.row:last-child {
        background-color: #FFFFFF; }
        .data_card_wrapper_table .card_body .card_items_row.row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .data_card_wrapper_table .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .data_card_wrapper_table .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .data_card_wrapper_table .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .data_card_wrapper_table .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .data_card_wrapper_table .card_body .header_row {
      background-color: #FFFFFF !important; }
    .data_card_wrapper_table .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .data_card_wrapper_table .card_body .default_row.row {
      font-weight: 400 !important; }
      .data_card_wrapper_table .card_body .default_row.row > div {
        justify-content: center !important;
        align-items: center !important; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(5) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(6) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(7) {
          display: flex;
          flex-direction: column;
          justify-content: center !important; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(8) .table_circle {
          height: 15px;
          width: 15px;
          background-color: orange;
          border-radius: 50%;
          margin-right: 11px; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(8) .active {
          background-color: green; }

.dialog_footer {
  flex-direction: row !important; }

.category_profile_wrapper {
  margin: 0 24px; }
  .category_profile_wrapper .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 0 16px;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796; }
    .category_profile_wrapper .breadcrumbs a {
      color: #4e73df;
      text-decoration: none; }
  .category_profile_wrapper .button {
    color: #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #1cc88a;
    border-color: #1cc88a;
    cursor: pointer;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 5.6px;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px; }
    .category_profile_wrapper .button:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .category_profile_wrapper .button:hover {
      background-color: #17a673; }
    .category_profile_wrapper .button:active {
      background-color: #169b6b;
      border-color: #149063; }
    .category_profile_wrapper .button:disabled {
      opacity: 0.7; }
      .category_profile_wrapper .button:disabled:hover {
        background-color: #1cc88a; }
  .category_profile_wrapper .profile_name {
    color: #5a5c69;
    margin-bottom: 24px;
    font-size: 28px;
    display: block; }
  .category_profile_wrapper .connection_row {
    margin-bottom: 16px;
    display: flex;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    max-width: 66%;
    padding: 0 12px;
    position: relative; }
    .category_profile_wrapper .connection_row .connection_title {
      box-sizing: border-box;
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      color: #858796;
      border: 1px solid #d1d3e2;
      background-color: #eaecf4;
      padding: 6px 12px;
      border-bottom-left-radius: 5.6px;
      border-top-left-radius: 5.6px;
      display: flex;
      align-items: center; }
    .category_profile_wrapper .connection_row .connection_input {
      border: 1px solid #d1d3e2;
      border-top-right-radius: 5.6px;
      border-bottom-right-radius: 5.6px;
      background-color: #ffffff;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      margin-right: 8px;
      color: #858796; }
      .category_profile_wrapper .connection_row .connection_input:disabled {
        background-color: #eaecf4; }
  .category_profile_wrapper .blocks_wrapper {
    display: flex;
    flex-direction: row; }
  .category_profile_wrapper .profile_block {
    border: 1px solid #e3e6f0;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 5.6px;
    margin-bottom: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .category_profile_wrapper .profile_block .profile_block_title {
      border-top-left-radius: 5.6px;
      border-top-right-radius: 5.6px;
      padding: 12px 20px;
      color: #858796;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 28px; }
    .category_profile_wrapper .profile_block .profile_name {
      width: 100%; }
    .category_profile_wrapper .profile_block .card_body {
      padding: 20px;
      background-color: #ffffff; }
      .category_profile_wrapper .profile_block .card_body span {
        color: #858796; }
      .category_profile_wrapper .profile_block .card_body .field_wrapper {
        display: flex;
        flex-direction: row; }
        .category_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher {
          display: flex;
          flex-direction: column;
          margin-right: 24px; }
          .category_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item {
            cursor: pointer;
            display: block;
            padding: 0.75rem 1.25rem;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.125); }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item:first-child {
              border-top-left-radius: 0.35rem;
              border-top-right-radius: 0.35rem;
              border-bottom: none; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item:last-child {
              border-bottom-right-radius: 0.35rem;
              border-bottom-left-radius: 0.35rem; }
          .category_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item.active {
            z-index: 2;
            color: #fff;
            background-color: #4e73df;
            border-color: #4e73df; }
        .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block {
          width: 100%;
          display: flex; }
          .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea {
            display: block;
            width: 100%;
            padding: 6px 12px;
            font-size: 16px;
            font-weight: 400;
            font-family: 'Nunito Sans', sans-serif;
            line-height: 1.5;
            color: #6e707e;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #d1d3e2;
            -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            margin-top: 0;
            margin-bottom: 0;
            height: 260px;
            border-bottom-right-radius: 5.6px;
            border-top-right-radius: 5.6px;
            resize: none; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea:focus {
              box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
              border-color: #bac8f3; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea::-webkit-input-placeholder {
              color: #858796; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea::-moz-placeholder {
              color: #858796; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea::-ms-input-placeholder {
              color: #858796; }
            .category_profile_wrapper .profile_block .card_body .field_wrapper .textarea_block .textarea::placeholder {
              color: #858796; }
      .category_profile_wrapper .profile_block .card_body .add_input {
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
        .category_profile_wrapper .profile_block .card_body .add_input::-webkit-input-placeholder {
          color: #858796; }
        .category_profile_wrapper .profile_block .card_body .add_input::-moz-placeholder {
          color: #858796; }
        .category_profile_wrapper .profile_block .card_body .add_input::-ms-input-placeholder {
          color: #858796; }
        .category_profile_wrapper .profile_block .card_body .add_input::placeholder {
          color: #858796; }
        .category_profile_wrapper .profile_block .card_body .add_input:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
      .category_profile_wrapper .profile_block .card_body .card_footer {
        padding: 12px 20px;
        background-color: #f8f9fc;
        border-top: 1px solid #e3e6f0; }
        .category_profile_wrapper .profile_block .card_body .card_footer .pic_err {
          margin-bottom: 16px;
          display: flex;
          color: #d0021b; }
        .category_profile_wrapper .profile_block .card_body .card_footer .upload_file {
          width: 100%;
          position: relative; }
          .category_profile_wrapper .profile_block .card_body .card_footer .upload_file .upload_input {
            position: relative;
            z-index: 2;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            margin: 0;
            opacity: 0; }
          .category_profile_wrapper .profile_block .card_body .card_footer .upload_file .upload_label {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 1;
            padding: 6px 80px 6px 12px;
            font-weight: 400;
            line-height: 1.5;
            color: #6e707e;
            background-color: #fff;
            border: 1px solid #d1d3e2;
            border-radius: 0.35rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; }
            .category_profile_wrapper .profile_block .card_body .card_footer .upload_file .upload_label::after {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              z-index: 3;
              display: block;
              padding: 0.375rem 0.75rem;
              line-height: 1.5;
              color: #6e707e;
              content: 'Browse';
              background-color: #eaecf4;
              border-left: inherit;
              border-radius: 0 0.35rem 0.35rem 0; }

.data_card_wrapper_table {
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin: 0 0 24px 0;
  background-color: #FFFFFF; }
  .data_card_wrapper_table .card_title {
    border-radius: 0.35rem;
    padding: 16px 20px;
    color: #858796;
    font-weight: 400;
    font-size: 28px; }
  .data_card_wrapper_table .card_body {
    padding: 20px; }
    .data_card_wrapper_table .card_body .card_buttons_row {
      display: flex; }
      .data_card_wrapper_table .card_body .card_buttons_row .green_button {
        margin: 0 15px 20px 0;
        display: flex;
        justify-content: center;
        align-items: center; }
    .data_card_wrapper_table .card_body .card_select_row {
      display: flex;
      align-items: center; }
      .data_card_wrapper_table .card_body .card_select_row span {
        color: #858796;
        margin: 0 15px 0 0; }
        .data_card_wrapper_table .card_body .card_select_row span:first-of-type {
          margin-right: 10px; }
        .data_card_wrapper_table .card_body .card_select_row span:last-of-type {
          margin-left: 10px; }
      .data_card_wrapper_table .card_body .card_select_row .form_select {
        display: block;
        padding: 6px 3px 6px 12px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-right: 10px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .data_card_wrapper_table .card_body .card_select_row .form_select:focus {
          border-color: #bac8f3;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .data_card_wrapper_table .card_body .card_search_row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 15px; }
      .data_card_wrapper_table .card_body .card_search_row .search_form {
        max-width: 325px;
        width: 100%;
        display: flex;
        margin-left: 15px; }
      .data_card_wrapper_table .card_body .card_search_row .search_input {
        background-color: #f8f9fc;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.5;
        color: #6e707e;
        width: 100%;
        height: 38px;
        padding: 0 12px;
        box-sizing: border-box;
        letter-spacing: normal; }
    .data_card_wrapper_table .card_body .card_controls {
      display: flex; }
    .data_card_wrapper_table .card_body .card_table {
      overflow: auto; }
    .data_card_wrapper_table .card_body .card_items_row.row {
      display: flex;
      border-top: 1px solid #e3e6f0;
      color: #858796;
      font-size: 16px;
      font-weight: 800;
      justify-content: flex-start;
      min-width: 1500px;
      padding: 12px 6px; }
      .data_card_wrapper_table .card_body .card_items_row.row > div {
        display: flex;
        align-items: flex-end;
        width: 10%;
        justify-content: space-around;
        text-align: center; }
        .data_card_wrapper_table .card_body .card_items_row.row > div .table_div {
          margin: 0 auto; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:first-child {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(2) {
          width: 15%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(3) {
          width: 10%;
          text-align: center; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(4) {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(5) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(6) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(7) {
          width: 12%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:nth-child(8) {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div:last-child {
          width: 10%; }
        .data_card_wrapper_table .card_body .card_items_row.row > div .arrows {
          display: flex;
          margin: 5px 10px;
          cursor: pointer; }
          .data_card_wrapper_table .card_body .card_items_row.row > div .arrows img {
            height: 15px;
            margin-right: 3px;
            opacity: 0.1;
            fill: #1A1A1A; }
          .data_card_wrapper_table .card_body .card_items_row.row > div .arrows .active_arrow {
            opacity: 0.5; }
      .data_card_wrapper_table .card_body .card_items_row.row:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05); }
      .data_card_wrapper_table .card_body .card_items_row.row:last-child {
        background-color: #FFFFFF; }
        .data_card_wrapper_table .card_body .card_items_row.row:last-child span .arrows svg path {
          fill: #FFFFFF; }
    .data_card_wrapper_table .card_body .pagination_block {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .data_card_wrapper_table .card_body .pagination_block .card_entries_count {
        font-size: 16px;
        color: #858796;
        margin: 20px 0; }
      .data_card_wrapper_table .card_body .pagination_block .pagination_arrows {
        color: #4e73df;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear; }
        .data_card_wrapper_table .card_body .pagination_block .pagination_arrows:hover {
          text-decoration: underline;
          cursor: pointer; }
    .data_card_wrapper_table .card_body .header_row {
      background-color: #FFFFFF !important; }
    .data_card_wrapper_table .card_body .footer_row {
      background-color: #FFFFFF !important;
      align-items: flex-start; }
    .data_card_wrapper_table .card_body .default_row.row {
      font-weight: 400 !important; }
      .data_card_wrapper_table .card_body .default_row.row > div {
        justify-content: center !important;
        align-items: center !important; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(5) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(6) {
          justify-content: center !important;
          display: flex;
          flex-direction: column; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(7) {
          display: flex;
          flex-direction: column;
          justify-content: center !important; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(8) .table_circle {
          height: 15px;
          width: 15px;
          background-color: orange;
          border-radius: 50%;
          margin-right: 11px; }
        .data_card_wrapper_table .card_body .default_row.row > div:nth-child(8) .active {
          background-color: green; }

.dialog_footer {
  flex-direction: row !important; }

.MuiFormControl-root.multi_selector {
  max-width: 49.5%;
  min-width: 49.5%; }
  .MuiFormControl-root.multi_selector svg {
    display: none !important; }
  .MuiFormControl-root.multi_selector .title_wrapper {
    display: flex;
    justify-content: space-between; }
  .MuiFormControl-root.multi_selector .MuiSelect-selectMenu {
    white-space: unset;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    min-height: 50px;
    margin-bottom: 16px; }
  .MuiFormControl-root.multi_selector .MuiInput-underline:before {
    content: unset !important; }
  .MuiFormControl-root.multi_selector .MuiInput-underline:after {
    content: unset !important; }
  .MuiFormControl-root.multi_selector .MuiSelect-select:focus {
    border-color: #bac8f3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    background-color: unset; }

.news_profile_wrapper {
  margin: 0 24px; }
  .news_profile_wrapper .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 0 16px;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796; }
    .news_profile_wrapper .breadcrumbs a {
      color: #4e73df;
      text-decoration: none; }
  .news_profile_wrapper .button {
    color: #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #1cc88a;
    border-color: #1cc88a;
    cursor: pointer;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 5.6px;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px; }
    .news_profile_wrapper .button:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .news_profile_wrapper .button:hover {
      background-color: #17a673; }
    .news_profile_wrapper .button:active {
      background-color: #169b6b;
      border-color: #149063; }
    .news_profile_wrapper .button:disabled {
      opacity: 0.7; }
      .news_profile_wrapper .button:disabled:hover {
        background-color: #1cc88a; }
  .news_profile_wrapper .profile_title {
    color: #858796;
    font-size: 40px;
    padding: 12px 20px; }
  .news_profile_wrapper .profile_name {
    padding: 12px 20px;
    color: #858796;
    margin-bottom: 24px;
    font-size: 32px;
    display: block; }
  .news_profile_wrapper .connection_row {
    margin-bottom: 16px;
    display: flex;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    max-width: 66%;
    padding: 0 12px;
    position: relative; }
    .news_profile_wrapper .connection_row .connection_title {
      box-sizing: border-box;
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      color: #858796;
      border: 1px solid #d1d3e2;
      background-color: #eaecf4;
      padding: 6px 12px;
      border-bottom-left-radius: 5.6px;
      border-top-left-radius: 5.6px;
      display: flex;
      align-items: center; }
    .news_profile_wrapper .connection_row .connection_input {
      border: 1px solid #d1d3e2;
      border-top-right-radius: 5.6px;
      border-bottom-right-radius: 5.6px;
      background-color: #FFFFFF;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      margin-right: 8px;
      color: #858796; }
      .news_profile_wrapper .connection_row .connection_input:disabled {
        background-color: #eaecf4; }
  .news_profile_wrapper .blocks_wrapper {
    display: flex;
    flex-direction: row; }
  .news_profile_wrapper .column {
    display: flex;
    flex-direction: column;
    flex: 0 0 75%;
    max-width: 75%; }
  .news_profile_wrapper .add_input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .news_profile_wrapper .add_input:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .news_profile_wrapper .add_input::-webkit-input-placeholder {
      color: #858796; }
    .news_profile_wrapper .add_input::-moz-placeholder {
      color: #858796; }
    .news_profile_wrapper .add_input::-ms-input-placeholder {
      color: #858796; }
    .news_profile_wrapper .add_input::placeholder {
      color: #858796; }
  .news_profile_wrapper .profile_block {
    border: 1px solid #e3e6f0;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 5.6px;
    margin-bottom: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column; }
    .news_profile_wrapper .profile_block .card_body {
      padding: 20px;
      background-color: #FFFFFF; }
      .news_profile_wrapper .profile_block .card_body .profile_banner {
        width: 100%; }
      .news_profile_wrapper .profile_block .card_body span {
        color: #858796; }
      .news_profile_wrapper .profile_block .card_body .flag-icon {
        border-radius: 50%;
        margin: 0 20px 0 auto; }
      .news_profile_wrapper .profile_block .card_body .input_title {
        margin-bottom: 8px;
        margin-top: 16px;
        display: block; }
      .news_profile_wrapper .profile_block .card_body .field_wrapper {
        display: flex;
        flex-direction: row; }
        .news_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher {
          display: flex;
          flex-direction: column;
          margin-right: 24px; }
          .news_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item {
            cursor: pointer;
            display: block;
            padding: 0.75rem 1.25rem;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.125); }
            .news_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item:first-child {
              border-top-left-radius: 0.35rem;
              border-top-right-radius: 0.35rem;
              border-bottom: none; }
            .news_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item:last-child {
              border-bottom-right-radius: 0.35rem;
              border-bottom-left-radius: 0.35rem; }
          .news_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item.active {
            z-index: 2;
            color: #fff;
            background-color: #4e73df;
            border-color: #4e73df; }
    .news_profile_wrapper .profile_block .title_information_block {
      max-width: 59%;
      width: 100%;
      padding: 20px 20px 20px 0; }
      .news_profile_wrapper .profile_block .title_information_block .lang_switcher {
        display: flex;
        margin-bottom: 15px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
        .news_profile_wrapper .profile_block .title_information_block .lang_switcher .item {
          cursor: pointer;
          display: block;
          padding: 6px 12px;
          box-sizing: border-box;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.125);
          color: #858796; }
          .news_profile_wrapper .profile_block .title_information_block .lang_switcher .item:first-child {
            border-top-left-radius: 0.35rem;
            border-bottom-left-radius: 0.35rem; }
          .news_profile_wrapper .profile_block .title_information_block .lang_switcher .item:last-child {
            border-bottom-right-radius: 0.35rem;
            border-top-right-radius: 0.35rem; }
        .news_profile_wrapper .profile_block .title_information_block .lang_switcher .item.active {
          z-index: 2;
          color: #fff;
          background-color: #4e73df;
          border-color: #4e73df; }
      .news_profile_wrapper .profile_block .title_information_block .lang_block {
        display: flex;
        flex-direction: column; }
        .news_profile_wrapper .profile_block .title_information_block .lang_block .add_input {
          margin-bottom: 16px; }
    .news_profile_wrapper .profile_block.picture {
      flex-direction: column;
      margin: 20px 10px 20px 20px;
      max-width: 40%;
      width: 100%; }
    .news_profile_wrapper .profile_block .input_row {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin: 0 20px;
      display: flex;
      justify-content: space-between; }
      .news_profile_wrapper .profile_block .input_row .textarea {
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Nunito Sans', sans-serif;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 0;
        margin-bottom: 0;
        min-height: 150px;
        border-bottom-right-radius: 5.6px;
        border-top-right-radius: 5.6px;
        resize: vertical; }
        .news_profile_wrapper .profile_block .input_row .textarea:focus {
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
          border-color: #bac8f3; }
      .news_profile_wrapper .profile_block .input_row:last-child {
        margin-bottom: 36px; }
    .news_profile_wrapper .profile_block .content_title {
      padding: 20px;
      color: #858796;
      font-size: 32px;
      font-weight: 400; }
    .news_profile_wrapper .profile_block .buttons {
      padding: 20px;
      display: flex;
      justify-content: space-between; }
      .news_profile_wrapper .profile_block .buttons .green_button {
        margin: unset;
        width: unset;
        height: unset;
        padding: 6px 12px; }
      .news_profile_wrapper .profile_block .buttons .red_button {
        background-color: #e74a3b;
        cursor: pointer;
        padding: 9px 12px 6px;
        line-height: 24px;
        font-size: 16px;
        color: #FFFFFF;
        border-radius: 5.6px;
        font-family: "Nunito Sans";
        -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
        .news_profile_wrapper .profile_block .buttons .red_button:focus {
          box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
          border-color: #ca2819; }
        .news_profile_wrapper .profile_block .buttons .red_button:active {
          background-color: #d52a1a;
          border-color: #ca2819; }
        .news_profile_wrapper .profile_block .buttons .red_button:hover {
          background-color: #e02d1b; }
    .news_profile_wrapper .profile_block .back_error {
      font-size: 14px !important;
      top: 5px !important; }
  .news_profile_wrapper .profile_block .tox-tinymce {
    margin: 0 20px 20px 20px; }
  .news_profile_wrapper .card_footer {
    padding: 12px 20px;
    background-color: #f8f9fc;
    border-top: 1px solid #e3e6f0; }
    .news_profile_wrapper .card_footer .pic_err {
      margin-bottom: 16px;
      display: flex;
      color: #D0021B; }
    .news_profile_wrapper .card_footer .upload_file {
      width: 100%;
      position: relative; }
      .news_profile_wrapper .card_footer .upload_file .upload_input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        margin: 0;
        opacity: 0; }
      .news_profile_wrapper .card_footer .upload_file .upload_label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        padding: 6px 80px 6px 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .news_profile_wrapper .card_footer .upload_file .upload_label::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 3;
          display: block;
          padding: 0.375rem 0.75rem;
          line-height: 1.5;
          color: #6e707e;
          content: "Browse";
          background-color: #eaecf4;
          border-left: inherit;
          border-radius: 0 0.35rem 0.35rem 0; }
  .news_profile_wrapper .text_row {
    color: #858796;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px; }

.dialog_footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between !important; }

.MuiFormControl-root.multi_selector {
  max-width: 49.5%;
  min-width: 49.5%; }
  .MuiFormControl-root.multi_selector svg {
    display: none !important; }
  .MuiFormControl-root.multi_selector .title_wrapper {
    display: flex;
    justify-content: space-between; }
  .MuiFormControl-root.multi_selector .MuiSelect-selectMenu {
    white-space: unset;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    min-height: 50px;
    margin-bottom: 16px; }
  .MuiFormControl-root.multi_selector .MuiInput-underline:before {
    content: unset !important; }
  .MuiFormControl-root.multi_selector .MuiInput-underline:after {
    content: unset !important; }
  .MuiFormControl-root.multi_selector .MuiSelect-select:focus {
    border-color: #bac8f3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    background-color: unset; }

.publications_profile_wrapper {
  margin: 0 24px; }
  .publications_profile_wrapper .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 0 16px;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796; }
    .publications_profile_wrapper .breadcrumbs a {
      color: #4e73df;
      text-decoration: none; }
  .publications_profile_wrapper .button {
    color: #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #1cc88a;
    border-color: #1cc88a;
    cursor: pointer;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 5.6px;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    margin-top: 16px; }
    .publications_profile_wrapper .button:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5); }
    .publications_profile_wrapper .button:hover {
      background-color: #17a673; }
    .publications_profile_wrapper .button:active {
      background-color: #169b6b;
      border-color: #149063; }
    .publications_profile_wrapper .button:disabled {
      opacity: 0.7; }
      .publications_profile_wrapper .button:disabled:hover {
        background-color: #1cc88a; }
  .publications_profile_wrapper .profile_title {
    color: #858796;
    font-size: 40px;
    padding: 12px 20px; }
  .publications_profile_wrapper .profile_name {
    padding: 12px 20px;
    color: #858796;
    margin-bottom: 24px;
    font-size: 32px;
    display: block; }
  .publications_profile_wrapper .connection_row {
    margin-bottom: 16px;
    display: flex;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
    max-width: 66%;
    padding: 0 12px;
    position: relative; }
    .publications_profile_wrapper .connection_row .connection_title {
      box-sizing: border-box;
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      color: #858796;
      border: 1px solid #d1d3e2;
      background-color: #eaecf4;
      padding: 6px 12px;
      border-bottom-left-radius: 5.6px;
      border-top-left-radius: 5.6px;
      display: flex;
      align-items: center; }
    .publications_profile_wrapper .connection_row .connection_input {
      border: 1px solid #d1d3e2;
      border-top-right-radius: 5.6px;
      border-bottom-right-radius: 5.6px;
      background-color: #FFFFFF;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      margin-right: 8px;
      color: #858796; }
      .publications_profile_wrapper .connection_row .connection_input:disabled {
        background-color: #eaecf4; }
  .publications_profile_wrapper .blocks_wrapper {
    display: flex;
    flex-direction: row; }
  .publications_profile_wrapper .column {
    display: flex;
    flex-direction: column;
    flex: 0 0 75%;
    max-width: 75%; }
  .publications_profile_wrapper .add_input {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .publications_profile_wrapper .add_input:focus {
      border-color: #bac8f3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25); }
    .publications_profile_wrapper .add_input::-webkit-input-placeholder {
      color: #858796; }
    .publications_profile_wrapper .add_input::-moz-placeholder {
      color: #858796; }
    .publications_profile_wrapper .add_input::-ms-input-placeholder {
      color: #858796; }
    .publications_profile_wrapper .add_input::placeholder {
      color: #858796; }
  .publications_profile_wrapper .profile_block {
    border: 1px solid #e3e6f0;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 5.6px;
    margin-bottom: 16px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column; }
    .publications_profile_wrapper .profile_block .card_body {
      padding: 20px;
      background-color: #FFFFFF; }
      .publications_profile_wrapper .profile_block .card_body .profile_banner {
        width: 100%; }
      .publications_profile_wrapper .profile_block .card_body span {
        color: #858796; }
      .publications_profile_wrapper .profile_block .card_body .flag-icon {
        border-radius: 50%;
        margin: 0 20px 0 auto; }
      .publications_profile_wrapper .profile_block .card_body .input_title {
        margin-bottom: 8px;
        margin-top: 16px;
        display: block; }
      .publications_profile_wrapper .profile_block .card_body .field_wrapper {
        display: flex;
        flex-direction: row; }
        .publications_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher {
          display: flex;
          flex-direction: column;
          margin-right: 24px; }
          .publications_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item {
            cursor: pointer;
            display: block;
            padding: 0.75rem 1.25rem;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.125); }
            .publications_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item:first-child {
              border-top-left-radius: 0.35rem;
              border-top-right-radius: 0.35rem;
              border-bottom: none; }
            .publications_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item:last-child {
              border-bottom-right-radius: 0.35rem;
              border-bottom-left-radius: 0.35rem; }
          .publications_profile_wrapper .profile_block .card_body .field_wrapper .lang_switcher .item.active {
            z-index: 2;
            color: #fff;
            background-color: #4e73df;
            border-color: #4e73df; }
    .publications_profile_wrapper .profile_block .title_information_block {
      max-width: 59%;
      width: 100%;
      padding: 20px 20px 20px 0; }
      .publications_profile_wrapper .profile_block .title_information_block .lang_switcher {
        display: flex;
        margin-bottom: 15px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
        .publications_profile_wrapper .profile_block .title_information_block .lang_switcher .item {
          cursor: pointer;
          display: block;
          padding: 6px 12px;
          box-sizing: border-box;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.125);
          color: #858796; }
          .publications_profile_wrapper .profile_block .title_information_block .lang_switcher .item:first-child {
            border-top-left-radius: 0.35rem;
            border-bottom-left-radius: 0.35rem; }
          .publications_profile_wrapper .profile_block .title_information_block .lang_switcher .item:last-child {
            border-bottom-right-radius: 0.35rem;
            border-top-right-radius: 0.35rem; }
        .publications_profile_wrapper .profile_block .title_information_block .lang_switcher .item.active {
          z-index: 2;
          color: #fff;
          background-color: #4e73df;
          border-color: #4e73df; }
      .publications_profile_wrapper .profile_block .title_information_block .lang_block {
        display: flex;
        flex-direction: column; }
        .publications_profile_wrapper .profile_block .title_information_block .lang_block .add_input {
          margin-bottom: 16px; }
    .publications_profile_wrapper .profile_block.picture {
      flex-direction: column;
      margin: 20px 10px 20px 20px;
      max-width: 40%;
      width: 100%; }
    .publications_profile_wrapper .profile_block .input_row {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin: 0 20px;
      display: flex;
      justify-content: space-between; }
      .publications_profile_wrapper .profile_block .input_row .textarea {
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Nunito Sans', sans-serif;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 0;
        margin-bottom: 0;
        min-height: 150px;
        border-bottom-right-radius: 5.6px;
        border-top-right-radius: 5.6px;
        resize: vertical; }
        .publications_profile_wrapper .profile_block .input_row .textarea:focus {
          box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
          border-color: #bac8f3; }
      .publications_profile_wrapper .profile_block .input_row:last-child {
        margin-bottom: 36px; }
    .publications_profile_wrapper .profile_block:last-child .content_title {
      padding: 20px 0;
      color: #858796;
      font-size: 32px;
      font-weight: 400; }
    .publications_profile_wrapper .profile_block:last-child .buttons {
      padding-top: 20px;
      display: flex;
      justify-content: space-between; }
      .publications_profile_wrapper .profile_block:last-child .buttons .green_button {
        margin: unset;
        width: unset;
        height: unset;
        padding: 6px 12px; }
      .publications_profile_wrapper .profile_block:last-child .buttons .red_button {
        background-color: #e74a3b;
        cursor: pointer;
        padding: 9px 12px 6px;
        line-height: 24px;
        font-size: 16px;
        color: #FFFFFF;
        border-radius: 5.6px;
        font-family: "Nunito Sans";
        -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
        .publications_profile_wrapper .profile_block:last-child .buttons .red_button:focus {
          box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
          border-color: #ca2819; }
        .publications_profile_wrapper .profile_block:last-child .buttons .red_button:active {
          background-color: #d52a1a;
          border-color: #ca2819; }
        .publications_profile_wrapper .profile_block:last-child .buttons .red_button:hover {
          background-color: #e02d1b; }
    .publications_profile_wrapper .profile_block .back_error {
      font-size: 14px !important;
      top: 5px !important; }
  .publications_profile_wrapper .profile_block.wysiwyg {
    padding: 0 20px 20px 20px; }
  .publications_profile_wrapper .card_footer {
    padding: 12px 20px;
    background-color: #f8f9fc;
    border-top: 1px solid #e3e6f0; }
    .publications_profile_wrapper .card_footer .pic_err {
      margin-bottom: 16px;
      display: flex;
      color: #D0021B; }
    .publications_profile_wrapper .card_footer .upload_file {
      width: 100%;
      position: relative; }
      .publications_profile_wrapper .card_footer .upload_file .upload_input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        margin: 0;
        opacity: 0; }
      .publications_profile_wrapper .card_footer .upload_file .upload_label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        padding: 6px 80px 6px 12px;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .publications_profile_wrapper .card_footer .upload_file .upload_label::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 3;
          display: block;
          padding: 0.375rem 0.75rem;
          line-height: 1.5;
          color: #6e707e;
          content: "Browse";
          background-color: #eaecf4;
          border-left: inherit;
          border-radius: 0 0.35rem 0.35rem 0; }
  .publications_profile_wrapper .text_row {
    color: #858796;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px; }

.dialog_footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between !important; }

.seo-publication {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem; }
  .seo-publication__btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 8px;
    gap: 8px;
    padding: 17px;
    background-color: transparent;
    cursor: pointer; }
  .seo-publication__title {
    text-align: left;
    font-family: inherit;
    font-size: 16px; }
  .seo-publication__arrow {
    flex-shrink: 0;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s; }
    .seo-publication__arrow--open {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
  .seo-publication__content {
    display: none;
    border-top: 1px solid #d1d3e2; }
    .seo-publication__content--open {
      display: block; }
  .seo-publication .seo-edit_wrapper {
    margin: 0;
    border: 0; }
  .seo-publication .meta_fields {
    width: 100% !important; }

.container {
  max-width: 936px;
  margin: 48px auto 0;
  display: flex;
  flex-direction: row;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  box-sizing: border-box;
  height: 400px;
  border-radius: 4px; }
  .container .image_site {
    width: 50%;
    background: url("https://source.unsplash.com/K4mSJ7kc0As/600x800") no-repeat center;
    background-size: cover;
    border-radius: 4px; }

.form_title {
  margin-bottom: 20px;
  font-size: 24px; }

.form_site {
  width: 50%;
  padding: 48px 60px;
  text-align: center;
  flex: 2 1; }
  .form_site .form_auth {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .form_site .form_auth .back_error {
      top: 130px;
      right: unset; }
    .form_site .form_auth .email_input {
      border-radius: 50px;
      border: 1px solid #d1d3e2;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.5;
      color: #6e707e;
      width: 100%;
      height: 50px;
      padding: 0 16px;
      box-sizing: border-box; }
    .form_site .form_auth .checkbox_input {
      width: 100%;
      display: flex; }
    .form_site .form_auth label {
      color: #858796;
      font-size: 13px;
      margin-bottom: 16px; }
    .form_site .form_auth button {
      width: 100%;
      background: #4E73DF;
      border-radius: 50px;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.5;
      color: #FFFFFF;
      text-decoration: none;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      cursor: pointer; }
      .form_site .form_auth button:hover {
        background-color: #2e59d9; }
    .form_site .form_auth label .error {
      bottom: unset !important; }
  .form_site hr {
    margin: 16px 0;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }

