.dashboard_wrapper {
  background-color: #f8f9fc;
  display: flex;
  box-sizing: border-box;
  padding: 0 24px;
  flex-direction: column;
  .top_wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .title {
      color: rgb(90, 92, 105);
      font-size: 28px;
    }
    .report {
      background-color:rgb(78, 115, 223);
      border-radius: 3px;
      box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2);
      font-size: 14px;
      line-height: 1.5;
      height: 31px;
      box-sizing: border-box;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
  .row{
    display: flex;
    flex-direction: row;
    .data_wrapper:last-child{
      margin-right: 0;
    }
  }
}