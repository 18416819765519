.users_wrapper {
  .breadcrumbs {
    display: flex;
    padding: 12px 16px;
    margin: 0 24px 16px;
    list-style: none;
    background-color: #eaecf4;
    border-radius: 0.35rem;
    color: #858796;
    a{
      color: #4e73df;
      text-decoration: none;
    }
  }
  .data_wrapper {
    display: flex;
    .data_card {
      width: 100%;
      margin-right: 24px;
      margin-bottom: 24px;
    }
    .active {
      margin-left: 24px;
      .card_title{
        color: #1cc88a;
      }
    }
    .no_data{
      display: none;
    }
    .unactive{
      .card_title{
        color: #5a5c69;
      }
    }
  }
  .add_form {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid rgb(227, 230, 240);
    border-radius: 5.6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #858796;
    width: 100%;
    padding: 20px;
    max-width: 40%;
    box-sizing: border-box;
    margin: 0 0 16px 24px;

    .block-input-mail {
      span.error{
        top: -10px;
      }
    }
    .block-input-pass {
      span.error{
        top: -25px;
      }
    }

    .add_form_names{
      display: flex;
      flex-direction: row;
      .names{
        width: 100%;
        .back_error {
          width: 50px;
          top: 5px;
        }
      }
      div:first-child{
        margin-right: 10px;
      }
    }

    .add_input{
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #6e707e;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #d1d3e2;
      border-radius: 0.35rem;
      box-sizing: border-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin-top: 8px;
      &:focus{
        border-color: #bac8f3;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
      }
    }
    button {
      color: #fff;
      background-color: #4e73df;
      border-color: #4e73df;
      cursor: pointer;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.35rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      text-align: center;
      vertical-align: middle;
      margin-top: 16px;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5)
      }
      &:hover{
        background-color: #2e59d9;
        border-color: #2653d4;
      }
      &:active{
        background-color: #2653d4;
        border-color: #244ec9;
      }
    }
  }
  .data_card_wrapper .card_body .card_items_row{
    min-width: unset;
    span {
      text-align: center;
      justify-content: space-evenly;

    }
  }
  .MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6{
    background-color: #1cc88a;
  }
  .MuiSnackbar-root.MuiSnackbar-anchorOriginTopLeft{
    top: 260px;
    left: 500px;
  }
}
