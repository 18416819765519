.companies_wrapper {
    .breadcrumbs {
        display: flex;
        padding: 12px 16px;
        margin: 0 24px 16px;
        list-style: none;
        background-color: #eaecf4;
        border-radius: 0.35rem;
        color: #858796;

        a {
            color: #4e73df;
            text-decoration: none;
        }
    }
    .forms_items {
        margin: 0 24px 24px 24px;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;

        cursor: pointer;
        .choose_form {
            border-bottom: 1px solid #d1d3e2;

            .seo-edit_wrapper {
                margin: 0;
                border: 0;
                border-top: 1px solid #d1d3e2;
                border-radius: 0;
            }

            .forms_title {
                padding: 17px;
                display: flex;
                justify-content: space-between;
                img {
                    transition: 0.4s;
                    transform: rotate(270deg);
                }
            }
            &:last-child {
                border-bottom: unset;
            }
            &.clicked {
                .forms_title {
                    img {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }

    .data_wrapper {
        display: flex;

        .data_card {
            width: 100%;
            margin-right: 24px;
            margin-bottom: 24px;
        }
        .active {
            margin-left: 24px;
            .card_title {
                color: #1cc88a;
            }
        }
        .no_data {
            .card_title {
                color: #f6c23e;
            }
        }
        .unactive {
            .card_title {
                color: #5a5c69;
            }
        }
    }
    .add_form {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border: 1px solid rgb(227, 230, 240);
        border-radius: 5.6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #858796;
        width: auto;
        box-sizing: border-box;
        margin: 0 24px 16px 24px;
        .MuiFormControl-root {
            display: flex;
        }
        .add_form-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 20px 32px 40px;
            .create_info {
                width: calc(50% - 20px);
            }
            .add_form_seo {
                width: calc(50% - 20px);
            }
            .input-mui__error-icon {
                position: absolute;
                top: -20px;
                right: 5px;
            }
            .add_form_seo {
                .seo_form {
                    display: none;
                    &.visible {
                        display: flex;
                        flex-direction: column;
                    }
                }
                .tabs {
                    display: flex;
                    margin: 0px 0px 10px;
                    .control_lang-btn {
                        background-color: transparent;
                        border: 1px solid;
                        border-color: #e3e6f0;
                        padding: 11px 23px;
                        color: #858796;
                        cursor: pointer;
                        font-size: 16px;
                        width: auto;
                        &:first-child {
                            border-radius: 6px 0 0 6px;
                            border-right: none;
                        }
                        &:last-child {
                            border-radius: 0 6px 6px 0;
                            border-left: none;
                        }
                        &.active {
                            background-color: #4e73df;
                            border-color: #4e73df;
                            color: #ffffff;
                        }
                        &.error {
                            border-color: red;
                        }
                    }
                }
            }
        }
        .add_form-button-wrapper {
            padding: 20px 32px;
            border-top: 1px solid #d1d3e2;
        }
        .form_select {
            margin-bottom: 10px;
            display: block;
            width: 50%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #6e707e;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #d1d3e2;
            border-radius: 0.35rem;
            box-sizing: border-box;
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            margin-top: 8px;
            -webkit-appearance: none;
            -moz-appearance: none;
            cursor: pointer;
            &:focus {
                border-color: #bac8f3;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
            }
        }

        .block-input-mail {
            margin-bottom: 10px;
            span.error {
                top: -10px;
            }
        }
        .block-input-pass {
            margin-bottom: 10px;
            span.error {
                top: -25px;
            }
        }

        .add_form_city {
            display: flex;
            flex-direction: row;
            .names {
                width: calc(25% - 10px);
                margin-left: 10px;
                position: relative;
            }
            .names.first {
                width: 50%;
                margin-left: 0;
            }
        }

        .add_input {
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #6e707e;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #d1d3e2;
            border-radius: 0.35rem;
            box-sizing: border-box;
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            margin-top: 8px;
            &:focus {
                border-color: #bac8f3;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
            }
        }
        .add_description {
            font-family: 'Nunito Sans', sans-serif;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #6e707e;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #d1d3e2;
            border-radius: 0.35rem;
            box-sizing: border-box;
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            margin-top: 8px;
            margin-bottom: 16px;
            resize: none;
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-track {
                -webkit-border-radius: 2px;

                background: #edf2f8;
            }
            &::-webkit-scrollbar-thumb {
                -webkit-border-radius: 5px;
                border-radius: 5px;
                background: #4e73df;
            }
            &::-webkit-scrollbar-thumb:window-inactive {
                background: #4e73df;
            }
            &:focus {
                border-color: #bac8f3;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
            }
        }
        button {
            color: #fff;
            background-color: #4e73df;
            border-color: #4e73df;
            cursor: pointer;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            border-radius: 0.35rem;
            transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            text-align: center;
            vertical-align: middle;
            min-height: 38px;
            width: 200px;
            &:focus {
                box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
            }
            &:hover {
                background-color: #2e59d9;
                border-color: #2653d4;
            }
            &:active {
                background-color: #2653d4;
                border-color: #244ec9;
            }
        }
    }
    .data_card_wrapper .card_body .card_items_row {
        span {
            text-align: center;
            width: 10% !important;
        }
    }
    .data_card_wrapper .card_body .card_items_row.user {
        span {
            &:nth-child(11) {
                display: none;
            }
            &:nth-child(12) {
                display: none;
            }
        }
    }
}
