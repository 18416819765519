.company_profile_wrapper {
    margin: 0 24px;
    .breadcrumbs {
        display: flex;
        padding: 12px 16px;
        margin: 0 0 16px;
        list-style: none;
        background-color: #eaecf4;
        border-radius: 0.35rem;
        color: #858796;
        a {
            color: #4e73df;
            text-decoration: none;
        }
    }

    .company_name {
        color: #5a5c69;
        margin-bottom: 24px;
        font-size: 28px;
        display: block;
    }

    .connection_row {
        margin-bottom: 16px;
        display: flex;
        line-height: 24px;
        font-size: 16px;
        width: 65%;
        position: relative;
        .connection_title {
            box-sizing: border-box;
            min-width: fit-content;
            color: #858796;
            border: 1px solid rgb(209, 211, 226);
            background-color: #eaecf4;
            padding: 6px 12px;
            border-bottom-left-radius: 5.6px;
            border-top-left-radius: 5.6px;
            display: flex;
            align-items: center;
        }
        .popper_wrapper {
            position: absolute;
            width: calc(100% - 250px);
            top: 39px;
            left: 156px;
            z-index: 100;
            background: white;
            font-family: 'Nunito Sans', sans-serif;
            line-height: 1.5;
            color: #6e707e;
            border-radius: 0.35rem;
            li {
                padding: 0 12px;
                &:first-of-type {
                    border-top-left-radius: 0.35rem;
                    border-top-right-radius: 0.35rem;
                }
                &:last-of-type {
                    border-bottom-left-radius: 0.35rem;
                    border-bottom-right-radius: 0.35rem;
                }
                &:hover {
                    color: #ffffff;
                    background-color: #4e73df;
                    cursor: pointer;
                }
            }
        }
        .smaller_popper {
            width: calc(100% - 206px);
        }
        .connection_input {
            border: 1px solid rgb(209, 211, 226);
            width: 100%;
            padding: 6px 12px;
            font-size: 16px;
            margin-right: 8px;
            color: #858796;
            cursor: text;
            &:disabled {
                background-color: #eaecf4;
            }
        }
    }
    .blocks_wrapper {
        display: flex;
        flex-direction: row;
        .field_name {
            color: #5a5c69;
            margin-bottom: 24px;
            font-size: 28px;
            display: block;
        }
    }
    .column {
        display: flex;
        flex-direction: column;
        flex: 0 0 75%;
    }
    .profile_block {
        border: 1px solid #e3e6f0;
        background-color: #ffffff;
        box-sizing: border-box;
        border-radius: 5.6px;
        margin-bottom: 16px;
        .image_wrapper {
            width: 100%;
            text-align: center;
        }
        .profile_block_title {
            background-color: rgba(0, 0, 0, 0.03);
            border-top-left-radius: 5.6px;
            border-top-right-radius: 5.6px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            padding: 12px 20px;
            color: #858796;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.profile_block_title.services {
                background-color: unset;
                display: flex;
                justify-content: flex-end;
                border-bottom: unset;
                align-items: flex-start;
            }
            .add_button {
                display: flex;
                align-items: center;
                color: #fff;
                background-color: #1cc88a;
                border-color: #1cc88a;
                border-radius: 5.6px;
                cursor: pointer;
                width: max-content;
                div {
                    display: flex;
                    align-items: center;
                    padding: 6px 12px;
                    line-height: 24px;
                    background-color: rgba(0, 0, 0, 0.15);
                    height: 24px;
                }
                span {
                    font-size: 16px;
                    padding: 6px 12px;
                }
                img {
                    width: 16px;
                }
                &:hover {
                    background-color: #17a673;
                    border-color: #169b6b;
                }
                &:active {
                    background-color: #169b6b;
                    border-color: #149063;
                }
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
                }
            }
        }
        .company_banner {
            height: 250px;
            margin-bottom: 16px;
        }
        .card_body {
            padding: 20px;
            background-color: #ffffff;

            span {
                color: #858796;
            }

            .input_title {
                margin-bottom: 8px;
                display: block;
            }

            .collapse_form {
                display: flex;
                flex-flow: column;
                width: 100%;
                padding: 20px;
                border: 1px solid rgb(227, 230, 240);
                border-radius: 5.6px;
                box-sizing: border-box;
                margin-bottom: 16px;
                position: relative;

                .lawyer_error {
                    left: 20px;
                    right: unset;
                }

                button {
                    line-height: 24px;
                    font-size: 16px;
                    border-radius: 5.6px;
                    color: #ffffff;
                    padding: 7px 12px 7px;
                    background-color: #4e73df;
                    width: 136px;
                    height: 38px;
                    cursor: pointer;
                    margin: 10px 0 20px;

                    &:hover {
                        background-color: #2e59d9;
                        border-color: #2653d4;
                    }

                    &:disabled {
                        opacity: 0.7;

                        &:hover {
                            background-color: #4e73df;
                            border-color: unset;
                        }
                    }

                    &:active {
                        background-color: #2653d4;
                        border-color: #244ec9;
                    }

                    &:focus {
                        box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
                    }
                }

                .form_select {
                    display: block;
                    padding: 0.375rem 0.75rem;
                    margin-bottom: 16px;
                    width: 100%;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #6e707e;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #d1d3e2;
                    border-radius: 0.35rem;
                    box-sizing: border-box;
                    transition: border-color 0.15s ease-in-out,
                        box-shadow 0.15s ease-in-out;
                    margin-top: 8px;
                    -webkit-appearance: none;
                    -moz-appearance: none;

                    &:focus {
                        border-color: #bac8f3;
                        outline: 0;
                        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                    }
                }

                .language_row {
                    margin-bottom: 16px;
                    display: flex;
                    line-height: 24px;
                    font-size: 16px;

                    .language_title {
                        box-sizing: border-box;
                        min-width: fit-content;
                        color: #858796;
                        border: 1px solid rgb(209, 211, 226);
                        background-color: #eaecf4;
                        padding: 4px 8px;
                    }

                    .language_input {
                        border: 1px solid rgb(209, 211, 226);
                        width: 100%;
                        padding: 4px 8px;
                        color: #6e707e;
                        font-size: 14px;
                    }
                }
            }

            .card_items_row {
                display: flex;
                border-top: 1px solid #e3e6f0;
                color: #858796;
                font-size: 16px;
                font-weight: 800;
                justify-content: flex-start;
                padding: 12px 6px;

                span {
                    display: flex;
                    align-items: center;

                    div {
                        margin: 0 auto;
                    }
                }

                &:nth-child(even) {
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }

            .card_items_row.lawyers span {
                &:first-child {
                    width: 5%;
                }

                &:nth-child(2) {
                    width: 20%;
                }

                &:nth-child(3) {
                    width: 15%;
                }

                &:nth-child(4) {
                    width: 30%;
                }

                &:nth-child(5) {
                    width: 10%;
                }

                &:last-child {
                    width: 20%;
                }
            }

            .upload_file {
                width: 100%;
                position: relative;

                .upload_pic_error {
                    top: 40px;
                }

                .upload_input {
                    position: relative;
                    z-index: 2;
                    width: 100%;
                    height: calc(1.5em + 0.75rem + 2px);
                    margin: 0;
                    opacity: 0;
                }

                .upload_label {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    z-index: 1;
                    padding: 6px 80px 6px 12px;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #6e707e;
                    background-color: #fff;
                    border: 1px solid #d1d3e2;
                    border-radius: 0.35rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &::after {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 3;
                        display: block;
                        padding: 0.375rem 0.75rem;
                        line-height: 1.5;
                        color: #6e707e;
                        content: 'Browse';
                        background-color: #eaecf4;
                        border-left: inherit;
                        border-radius: 0 0.35rem 0.35rem 0;
                    }
                }
            }

            .button {
                color: #fff;
                width: fit-content;
                background-color: #1cc88a;
                border-color: #1cc88a;
                cursor: pointer;
                padding: 6px 12px;
                font-size: 16px;
                line-height: 1.5;
                border-radius: 5.6px;
                transition: color 0.15s ease-in-out,
                    background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                text-align: center;
                vertical-align: middle;
                margin-top: 16px;
                min-height: 36px;
                min-width: 59px;

                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
                }

                &:hover {
                    background-color: #17a673;
                }

                &:active {
                    background-color: #169b6b;
                    border-color: #149063;
                }

                &:disabled {
                    opacity: 0.7;

                    &:hover {
                        background-color: #1cc88a;
                    }
                }
            }

            .upload_button {
                min-height: 36px;
                min-width: 111px;
            }

            .field_wrapper {
                display: flex;
                flex-direction: row;
                .lang_switcher {
                    display: flex;
                    flex-direction: column;
                    margin-right: 24px;
                    .item {
                        cursor: pointer;
                        display: block;
                        padding: 0.75rem 1.25rem;
                        box-sizing: border-box;
                        background-color: #fff;
                        border: 1px solid rgba(0, 0, 0, 0.125);
                        &:first-child {
                            border-top-left-radius: 0.35rem;
                            border-top-right-radius: 0.35rem;
                            border-bottom: none;
                        }
                        &:last-child {
                            border-bottom-right-radius: 0.35rem;
                            border-bottom-left-radius: 0.35rem;
                        }
                    }
                    .item.active {
                        z-index: 2;
                        color: #fff;
                        background-color: #4e73df;
                        border-color: #4e73df;
                    }
                }
                .textarea_block {
                    width: 100%;
                    display: flex;
                    position: relative;
                    .textarea {
                        display: block;
                        width: 100%;
                        padding: 6px 12px;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: 'Nunito Sans', sans-serif;
                        line-height: 1.5;
                        color: #6e707e;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #d1d3e2;
                        transition: border-color 0.15s ease-in-out,
                            box-shadow 0.15s ease-in-out;
                        margin-top: 0;
                        margin-bottom: 0;
                        height: 260px;
                        border-bottom-right-radius: 5.6px;
                        border-top-right-radius: 5.6px;
                        resize: none;

                        &:focus {
                            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                            border-color: #bac8f3;
                        }
                    }
                }
            }

            .card_field {
                display: flex;
                margin-bottom: 8px;
                position: relative;
                .input_name {
                    display: flex;
                    align-items: center;
                    padding: 6px 12px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #6e707e;
                    text-align: center;
                    background-color: #eaecf4;
                    border: 1px solid #d1d3e2;
                    border-radius: 5.6px;
                }
            }

            .form_select {
                display: block;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #6e707e;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #d1d3e2;
                border-radius: 0.35rem;
                box-sizing: border-box;
                transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                -webkit-appearance: none;
                -moz-appearance: none;
                &:focus {
                    border-color: #bac8f3;
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                }
            }

            .add_input {
                width: 100%;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #6e707e;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #d1d3e2;
                border-radius: 0.35rem;
                box-sizing: border-box;
                transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                &::placeholder {
                    color: #858796;
                }
                &:focus {
                    border-color: #bac8f3;
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                }
            }

            .add_form_city {
                display: flex;
                flex-direction: row;
                .names {
                    width: calc(25%);
                    margin-left: 10px;
                }
                .names.first {
                    width: calc(50% + 10px);
                    margin-left: 0;
                }
            }
        }
    }

    .profile_block:first-child {
        width: 100%;
        margin-right: 24px;
    }
    .profile_block:last-child {
        width: 100%;
    }

    .profile_block.services {
        .collapse_wrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 12px 20px;
            .collapse_form {
                display: flex;
                flex-direction: column;
                padding: 12px 20px;
                border: 1px solid rgb(227, 230, 240);
                border-radius: 5.6px;
                box-sizing: border-box;
                margin-bottom: 16px;
                position: relative;
                width: 100%;
                .back_error {
                    right: 10px;
                    color: #d0021b;
                }
                .block_field {
                    margin-bottom: 16px;
                    display: flex;
                    flex-direction: column;
                    .field_title {
                        color: #858796;
                        &.big {
                            font-size: 28px;
                        }
                    }
                }
                .collapse_row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .add_input {
                        margin-top: 8px;
                        width: 100%;
                        padding: 0.375rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #6e707e;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #d1d3e2;
                        border-radius: 0.35rem;
                        box-sizing: border-box;
                        transition: border-color 0.15s ease-in-out,
                            box-shadow 0.15s ease-in-out;
                        &::placeholder {
                            color: #858796;
                            opacity: 0.5;
                        }
                        &:focus {
                            border-color: #bac8f3;
                            outline: 0;
                            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                        }
                    }
                    .placeholder {
                        position: absolute;
                        top: 157px;
                        left: 33px;
                        color: #858796;
                        opacity: 0.5;
                        user-select: none;
                    }
                    .block_field {
                        width: 35%;
                        margin-right: auto;
                    }
                    .card_body {
                        padding: unset;
                        width: 100%;
                        flex-direction: column;
                        .textarea {
                            border-radius: 5.6px;
                            margin-bottom: 8px;
                        }
                    }
                }
                button {
                    line-height: 24px;
                    font-size: 16px;
                    border-radius: 5.6px;
                    color: #ffffff;
                    padding: 7px 12px 7px;
                    background-color: #1cc88a;
                    cursor: pointer;
                    width: 100%;
                    &:hover {
                        background-color: #17a673;
                        border-color: #169b6b;
                    }
                    &:active {
                        background-color: #169b6b;
                        border-color: #149063;
                    }
                    &:focus {
                        box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
                    }
                    &:disabled {
                        opacity: 0.5;
                    }
                }
                .form_select {
                    display: block;
                    padding: 0.375rem 0.75rem;
                    margin-bottom: 16px;
                    width: 100%;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #6e707e;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #d1d3e2;
                    border-radius: 0.35rem;
                    box-sizing: border-box;
                    transition: border-color 0.15s ease-in-out,
                        box-shadow 0.15s ease-in-out;
                    margin-top: 8px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    &:focus {
                        border-color: #bac8f3;
                        outline: 0;
                        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                    }
                }
            }
        }
        .card_body {
            display: flex;
            flex-direction: row;
            .textarea_block {
                width: 100%;
                display: flex;
                .textarea_services {
                    display: flex;
                    width: 100%;
                    margin-bottom: 16px;
                }
                .lang_switcher {
                    display: flex;
                    flex-direction: column;
                    margin-right: 24px;
                    .item {
                        cursor: pointer;
                        display: block;
                        padding: 0.75rem 1.25rem;
                        box-sizing: border-box;
                        background-color: #fff;
                        border: 1px solid rgba(0, 0, 0, 0.125);
                        &:first-child {
                            border-top-left-radius: 0.35rem;
                            border-top-right-radius: 0.35rem;
                            border-bottom: none;
                        }
                        &:last-child {
                            border-bottom-right-radius: 0.35rem;
                            border-bottom-left-radius: 0.35rem;
                        }
                    }
                    .item.active {
                        z-index: 2;
                        color: #fff;
                        background-color: #4e73df;
                        border-color: #4e73df;
                    }
                }
                .textarea {
                    display: block;
                    width: 100%;
                    padding: 6px 12px;
                    font-size: 16px;
                    font-weight: 400;
                    font-family: 'Nunito Sans', sans-serif;
                    line-height: 1.5;
                    color: #6e707e;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #d1d3e2;
                    transition: border-color 0.15s ease-in-out,
                        box-shadow 0.15s ease-in-out;
                    margin-top: 0;
                    margin-bottom: 0;
                    height: 260px;
                    border-bottom-right-radius: 5.6px;
                    border-top-right-radius: 5.6px;
                    resize: none;
                    &:focus {
                        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                        border-color: #bac8f3;
                    }
                    &::placeholder {
                        color: #858796;
                    }
                }
            }
            .row {
                display: flex;
                flex-direction: row;
                &.place {
                    justify-content: space-between;
                    font-size: 28px;
                }
                &.controls {
                    align-items: flex-end;
                    button {
                        height: 38px;
                    }
                }
                .col {
                    text-align: left;
                    width: 100%;
                    &:first-child {
                        margin-right: 24px;
                    }
                    .error {
                        bottom: 40px !important;
                    }
                }
            }
            .textarea_services {
                width: 100%;
                display: flex;
                margin-bottom: 16px;
                .side_text {
                    display: flex;
                    align-items: center;
                    padding: 0.375rem 0.75rem;
                    margin-bottom: 0;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #6e707e;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #eaecf4;
                    border: 1px solid #d1d3e2;
                    border-top-left-radius: 5.6px;
                    border-bottom-left-radius: 5.6px;
                }
                .textarea {
                    display: block;
                    width: 100%;
                    padding: 6px 12px;
                    font-size: 16px;
                    font-weight: 400;
                    font-family: 'Nunito Sans', sans-serif;
                    line-height: 1.5;
                    color: #6e707e;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #d1d3e2;
                    transition: border-color 0.15s ease-in-out,
                        box-shadow 0.15s ease-in-out;
                    margin-top: 0;
                    margin-bottom: 0;
                    height: 254px;
                    border-bottom-right-radius: 5.6px;
                    border-top-right-radius: 5.6px;
                    resize: none;
                    &:focus {
                        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                        border-color: #bac8f3;
                    }
                }
            }
            .card_body_services {
                display: flex;
                flex-direction: column;
                width: 33%;
                margin-right: 24px;
                border: 1px solid #d1d3e2;
                border-radius: 5.6px;
                overflow: hidden;
                height: fit-content;
                .service {
                    color: #6e707e;
                    width: 100%;
                    padding: 12px 20px;
                    border-bottom: 1px solid #d1d3e2;
                    &.blue {
                        background-color: #4e73df;
                        color: #ffffff;
                        &:hover {
                            background-color: #4e73df;
                        }
                        &:active {
                            background-color: #4e73df;
                        }
                    }
                    &:last-child {
                        border-bottom: unset;
                    }
                    &:hover {
                        background-color: #f8f9fc;
                    }
                    &:active {
                        background-color: #eaecf4;
                    }
                }
            }
            .card_body_fields {
                width: 66%;
                min-height: 500px;
            }
        }
    }
    .seo-edit_wrapper {
        margin: 24px 0px;
    }
}

.no_margins {
    margin: 0 !important;
}
