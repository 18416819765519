.MuiFormControl-root.multi_selector {
  max-width: 49.5%;
  min-width: 49.5%;
  svg {
    display: none!important;
  }
  .title_wrapper {
    display: flex;
    justify-content: space-between;
  }
  .MuiSelect-selectMenu {
    white-space: unset;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    box-sizing: border-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    min-height: 50px;
    margin-bottom: 16px;
  }
  .MuiInput-underline{
    &:before {
      content: unset!important;
    }
    &:after{
      content: unset!important;
    }
  }
  .MuiSelect-select:focus {
    border-color: #bac8f3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    background-color: unset;
  }
}