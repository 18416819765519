.category_profile_wrapper {
    margin: 0 24px;
    .breadcrumbs {
        display: flex;
        padding: 12px 16px;
        margin: 0 0 16px;
        list-style: none;
        background-color: #eaecf4;
        border-radius: 0.35rem;
        color: #858796;
        a {
            color: #4e73df;
            text-decoration: none;
        }
    }
    .button {
        color: #fff;
        width: fit-content;
        background-color: #1cc88a;
        border-color: #1cc88a;
        cursor: pointer;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 5.6px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        text-align: center;
        vertical-align: middle;
        margin-top: 16px;
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
        }
        &:hover {
            background-color: #17a673;
        }
        &:active {
            background-color: #169b6b;
            border-color: #149063;
        }
        &:disabled {
            opacity: 0.7;
            &:hover {
                background-color: #1cc88a;
            }
        }
    }
    .profile_name {
        color: #5a5c69;
        margin-bottom: 24px;
        font-size: 28px;
        display: block;
    }

    .connection_row {
        margin-bottom: 16px;
        display: flex;
        line-height: 24px;
        font-size: 16px;
        width: 100%;
        max-width: 66%;
        padding: 0 12px;
        position: relative;
        .connection_title {
            box-sizing: border-box;
            min-width: fit-content;
            color: #858796;
            border: 1px solid rgb(209, 211, 226);
            background-color: #eaecf4;
            padding: 6px 12px;
            border-bottom-left-radius: 5.6px;
            border-top-left-radius: 5.6px;
            display: flex;
            align-items: center;
        }
        .connection_input {
            border: 1px solid rgb(209, 211, 226);
            border-top-right-radius: 5.6px;
            border-bottom-right-radius: 5.6px;
            background-color: #ffffff;
            width: 100%;
            padding: 6px 12px;
            font-size: 16px;
            margin-right: 8px;
            color: #858796;
            &:disabled {
                background-color: #eaecf4;
            }
        }
    }
    .blocks_wrapper {
        display: flex;
        flex-direction: row;
    }
    .profile_block {
        border: 1px solid #e3e6f0;
        background-color: #ffffff;
        box-sizing: border-box;
        border-radius: 5.6px;
        margin-bottom: 16px;
        height: fit-content;

        .profile_block_title {
            border-top-left-radius: 5.6px;
            border-top-right-radius: 5.6px;
            padding: 12px 20px;
            color: #858796;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 28px;
        }
        .profile_name {
            width: 100%;
        }
        .card_body {
            padding: 20px;
            background-color: #ffffff;
            span {
                color: #858796;
            }
            .field_wrapper {
                display: flex;
                flex-direction: row;
                .lang_switcher {
                    display: flex;
                    flex-direction: column;
                    margin-right: 24px;
                    .item {
                        cursor: pointer;
                        display: block;
                        padding: 0.75rem 1.25rem;
                        box-sizing: border-box;
                        background-color: #fff;
                        border: 1px solid rgba(0, 0, 0, 0.125);
                        &:first-child {
                            border-top-left-radius: 0.35rem;
                            border-top-right-radius: 0.35rem;
                            border-bottom: none;
                        }
                        &:last-child {
                            border-bottom-right-radius: 0.35rem;
                            border-bottom-left-radius: 0.35rem;
                        }
                    }
                    .item.active {
                        z-index: 2;
                        color: #fff;
                        background-color: #4e73df;
                        border-color: #4e73df;
                    }
                }
                .textarea_block {
                    width: 100%;
                    display: flex;
                    .textarea {
                        display: block;
                        width: 100%;
                        padding: 6px 12px;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: 'Nunito Sans', sans-serif;
                        line-height: 1.5;
                        color: #6e707e;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #d1d3e2;
                        transition: border-color 0.15s ease-in-out,
                            box-shadow 0.15s ease-in-out;
                        margin-top: 0;
                        margin-bottom: 0;
                        height: 260px;
                        border-bottom-right-radius: 5.6px;
                        border-top-right-radius: 5.6px;
                        resize: none;
                        &:focus {
                            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                            border-color: #bac8f3;
                        }
                        &::placeholder {
                            color: #858796;
                        }
                    }
                }
            }

            .add_input {
                width: 100%;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #6e707e;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #d1d3e2;
                border-radius: 0.35rem;
                box-sizing: border-box;
                transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                &::placeholder {
                    color: #858796;
                }
                &:focus {
                    border-color: #bac8f3;
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                }
            }

            .card_footer {
                padding: 12px 20px;
                background-color: #f8f9fc;
                border-top: 1px solid #e3e6f0;
                .pic_err {
                    margin-bottom: 16px;
                    display: flex;
                    color: #d0021b;
                }
                .upload_file {
                    width: 100%;
                    position: relative;
                    .upload_input {
                        position: relative;
                        z-index: 2;
                        width: 100%;
                        height: calc(1.5em + 0.75rem + 2px);
                        margin: 0;
                        opacity: 0;
                    }
                    .upload_label {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        z-index: 1;
                        padding: 6px 80px 6px 12px;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #6e707e;
                        background-color: #fff;
                        border: 1px solid #d1d3e2;
                        border-radius: 0.35rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        &::after {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            z-index: 3;
                            display: block;
                            padding: 0.375rem 0.75rem;
                            line-height: 1.5;
                            color: #6e707e;
                            content: 'Browse';
                            background-color: #eaecf4;
                            border-left: inherit;
                            border-radius: 0 0.35rem 0.35rem 0;
                        }
                    }
                }
            }
        }
    }
}
