.category_profile_wrapper {
    margin: 0 24px;
    .breadcrumbs {
        display: flex;
        padding: 12px 16px;
        margin: 0 0 16px;
        list-style: none;
        background-color: #eaecf4;
        border-radius: 0.35rem;
        color: #858796;
        a {
            color: #4e73df;
            text-decoration: none;
        }
    }
    .button {
        color: #fff;
        width: fit-content;
        background-color: #1cc88a;
        border-color: #1cc88a;
        cursor: pointer;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 5.6px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        text-align: center;
        vertical-align: middle;
        margin-top: 16px;
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
        }
        &:hover {
            background-color: #17a673;
        }
        &:active {
            background-color: #169b6b;
            border-color: #149063;
        }
        &:disabled {
            opacity: 0.7;
            &:hover {
                background-color: #1cc88a;
            }
        }
    }
    .category_name {
        color: #5a5c69;
        margin-bottom: 24px;
        font-size: 28px;
        display: block;
    }

    .connection_row {
        margin-bottom: 16px;
        display: flex;
        line-height: 24px;
        font-size: 16px;
        width: 100%;
        max-width: 66%;
        padding: 0 12px;
        position: relative;
        .connection_title {
            box-sizing: border-box;
            min-width: fit-content;
            color: #858796;
            border: 1px solid rgb(209, 211, 226);
            background-color: #eaecf4;
            padding: 6px 12px;
            border-bottom-left-radius: 5.6px;
            border-top-left-radius: 5.6px;
            display: flex;
            align-items: center;
        }
        .popper_wrapper {
            position: absolute;
            width: 73.5%;
            top: 39px;
            left: 169px;
            z-index: 100;
            background: white;
            font-family: 'Nunito Sans', sans-serif;
            line-height: 1.5;
            color: #6e707e;
            border-radius: 0.35rem;
            li {
                padding: 0 12px;
                &:first-of-type {
                    border-top-left-radius: 0.35rem;
                    border-top-right-radius: 0.35rem;
                }
                &:last-of-type {
                    border-bottom-left-radius: 0.35rem;
                    border-bottom-right-radius: 0.35rem;
                }
                &:hover {
                    color: #ffffff;
                    background-color: #4e73df;
                    cursor: pointer;
                }
            }
        }
        .connection_input {
            border: 1px solid rgb(209, 211, 226);
            border-top-right-radius: 5.6px;
            border-bottom-right-radius: 5.6px;
            background-color: #ffffff;
            width: 100%;
            padding: 6px 12px;
            font-size: 16px;
            margin-right: 8px;
            color: #858796;
            &:disabled {
                background-color: #eaecf4;
            }
        }
    }
    .blocks_wrapper {
        display: flex;
        flex-direction: row;
    }
    .column {
        display: flex;
        flex-direction: column;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .profile_block {
        border: 1px solid #e3e6f0;
        background-color: #ffffff;
        box-sizing: border-box;
        border-radius: 5.6px;
        margin-bottom: 16px;
        height: fit-content;
        &.image {
            margin-right: 24px;
        }
        .profile_block_title {
            border-top-left-radius: 5.6px;
            border-top-right-radius: 5.6px;
            padding: 12px 20px;
            color: #858796;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 28px;

            .add_button {
                display: flex;
                align-items: center;
                color: #fff;
                background-color: #1cc88a;
                border-color: #1cc88a;
                border-radius: 5.6px;
                cursor: pointer;
                div {
                    display: flex;
                    align-items: center;
                    padding: 6px 12px;
                    line-height: 24px;
                    background-color: rgba(0, 0, 0, 0.15);
                    height: 24px;
                }
                span {
                    font-size: 16px;
                    padding: 6px 12px;
                }
                svg {
                    width: 16px;
                }
                &:hover {
                    background-color: #17a673;
                    border-color: #169b6b;
                }
                &:active {
                    background-color: #169b6b;
                    border-color: #149063;
                }
                &:focus {
                    box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
                }
            }
            .level {
                margin: 0 auto;
                font-size: 48px;
                text-transform: uppercase;
                color: white;
            }
        }
        .profile_block_title.at {
            background-color: #e66a6a;
            line-height: 72px;
        }
        .profile_block_title.ma {
            background-color: #0a558c;
            line-height: 72px;
        }
        .profile_block_title.ba {
            background-color: #3ebd93;
            line-height: 72px;
        }
        .category_banner {
            width: 100%;
        }
        .card_body {
            padding: 20px;
            background-color: #ffffff;
            span {
                color: #858796;
            }
            .flag-icon {
                border-radius: 50%;
                margin: 0 20px 0 auto;
            }
            .input_title {
                margin-bottom: 8px;
                margin-top: 16px;
                display: block;
            }

            .card_items_row {
                display: flex;
                border-top: 1px solid #e3e6f0;
                color: #858796;
                font-size: 16px;
                font-weight: 800;
                justify-content: flex-start;
                padding: 12px 6px;
                span {
                    display: flex;
                    align-items: center;
                    div {
                        margin: 0 auto;
                    }
                }
                &:nth-child(even) {
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
            .field_wrapper {
                display: flex;
                flex-direction: row;
                .lang_switcher {
                    display: flex;
                    flex-direction: column;
                    margin-right: 24px;
                    .item {
                        cursor: pointer;
                        display: block;
                        padding: 0.75rem 1.25rem;
                        box-sizing: border-box;
                        background-color: #fff;
                        border: 1px solid rgba(0, 0, 0, 0.125);
                        &:first-child {
                            border-top-left-radius: 0.35rem;
                            border-top-right-radius: 0.35rem;
                            border-bottom: none;
                        }
                        &:last-child {
                            border-bottom-right-radius: 0.35rem;
                            border-bottom-left-radius: 0.35rem;
                        }
                    }
                    .item.active {
                        z-index: 2;
                        color: #fff;
                        background-color: #4e73df;
                        border-color: #4e73df;
                    }
                }
                .textarea_block {
                    width: 100%;
                    display: flex;
                    .textarea {
                        display: block;
                        width: 100%;
                        padding: 6px 12px;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: 'Nunito Sans', sans-serif;
                        line-height: 1.5;
                        color: #6e707e;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #d1d3e2;
                        transition: border-color 0.15s ease-in-out,
                            box-shadow 0.15s ease-in-out;
                        margin-top: 0;
                        margin-bottom: 0;
                        height: 260px;
                        border-bottom-right-radius: 5.6px;
                        border-top-right-radius: 5.6px;
                        resize: none;
                        &:focus {
                            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                            border-color: #bac8f3;
                        }
                        &::placeholder {
                            color: #858796;
                        }
                    }
                }
            }

            .card_field {
                display: flex;
                margin-bottom: 8px;
                .input_name {
                    display: flex;
                    align-items: center;
                    padding: 6px 12px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #6e707e;
                    text-align: center;
                    background-color: #eaecf4;
                    border: 1px solid #d1d3e2;
                    border-radius: 5.6px;
                }
            }

            .form_select {
                display: block;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #6e707e;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #d1d3e2;
                border-radius: 0.35rem;
                box-sizing: border-box;
                transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                -webkit-appearance: none;
                -moz-appearance: none;
                &:focus {
                    border-color: #bac8f3;
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                }
            }

            .add_input {
                width: 100%;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #6e707e;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #d1d3e2;
                border-radius: 0.35rem;
                box-sizing: border-box;
                transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                &::placeholder {
                    color: #858796;
                }
                &:focus {
                    border-color: #bac8f3;
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
                }
            }

            .add_form_city {
                display: flex;
                flex-direction: row;
                .names {
                    width: calc(25%);
                    margin-left: 10px;
                }
                .names.first {
                    width: calc(50% + 10px);
                    margin-left: 0;
                }
            }

            .card_items_row {
                text-align: center;
                > div {
                    &:first-child {
                        width: 15%;
                    }
                    &:nth-child(2) {
                        width: 45%;
                    }
                    &:nth-child(3) {
                        width: 15%;
                    }
                    &:last-child {
                        width: 25%;
                        margin: 0 3px;
                    }
                }
            }
            .card_items_row.positions {
                text-align: center;
                > div {
                    &:first-child {
                        width: 15%;
                    }
                    &:nth-child(2) {
                        width: 55%;
                    }
                    &:last-child {
                        width: 30%;
                        margin: 0 3px;
                    }
                }
            }
            .default_row {
                font-weight: 400 !important;
                align-items: center;
                & > div {
                    justify-content: center !important;
                    align-items: center !important;
                    &:nth-child(3) {
                        display: flex;
                        justify-content: center !important;
                        .table_circle {
                            height: 15px;
                            width: 15px;
                            background-color: #bbbbbb;
                            border-radius: 50%;
                            margin-right: 11px;
                        }
                        .active {
                            background-color: green;
                        }
                    }
                }
            }
            .header_row {
                background-color: #ffffff !important;
            }
            .footer_row {
                background-color: #ffffff !important;
                align-items: flex-start;
            }
            .card_controls {
                display: flex;
            }
            .language_box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 12px 20px;
                border: 1px solid rgba(0, 0, 0, 0.125);
                border-bottom: none;
                color: #858796;
                &:first-of-type {
                    border-top-left-radius: 5.6px;
                    border-top-right-radius: 5.6px;
                }
                &:last-of-type {
                    border-bottom-left-radius: 5.6px;
                    border-bottom-right-radius: 5.6px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                }
            }
        }
    }
    .langs {
        width: 50%;
    }
    .card_footer {
        padding: 12px 20px;
        background-color: #f8f9fc;
        border-top: 1px solid #e3e6f0;
        .pic_err {
            margin-bottom: 16px;
            display: flex;
            color: #d0021b;
        }
        .upload_file {
            width: 100%;
            position: relative;
            .upload_input {
                position: relative;
                z-index: 2;
                width: 100%;
                height: calc(1.5em + 0.75rem + 2px);
                margin: 0;
                opacity: 0;
            }
            .upload_label {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                z-index: 1;
                padding: 6px 80px 6px 12px;
                font-weight: 400;
                line-height: 1.5;
                color: #6e707e;
                background-color: #fff;
                border: 1px solid #d1d3e2;
                border-radius: 0.35rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 3;
                    display: block;
                    padding: 0.375rem 0.75rem;
                    line-height: 1.5;
                    color: #6e707e;
                    content: 'Browse';
                    background-color: #eaecf4;
                    border-left: inherit;
                    border-radius: 0 0.35rem 0.35rem 0;
                }
            }
        }
    }

    .profile_block.access_level {
        .profile_block_title {
            border: none;
            background-color: #ffffff;
            font-size: 24px;
        }
        .card_body {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .btn_wrapper {
            display: flex;
            justify-content: flex-end;
            padding: 0 35px 12px 20px;
            .button {
                margin: 0;
            }
        }
        label {
            font-size: 48px;
            color: #ffffff;
            padding: 8px;
            text-transform: uppercase;
            margin-right: 16px;
            input {
                width: 32px;
                height: 32px;
                margin-right: 5px;
            }
            &:first-child {
                background-color: #e66a6a;
            }
            &:nth-child(2) {
                background-color: #0a558c;
            }
            &:last-child {
                background-color: #3ebd93;
            }
        }
    }
    .profile_block.resume {
        .profile_block_title {
            border: none;
            background-color: #ffffff;
            font-size: 24px;
        }
        .card_body {
            display: flex;
            flex-direction: column;
            .textarea {
                height: 192px;
            }
            .textarea::placeholder {
                color: #858796;
            }
        }
        .btn_wrapper {
            display: flex;
            justify-content: flex-end;
            padding: 0 35px 12px 20px;
            .button {
                margin: 0;
            }
        }
        label {
            font-size: 48px;
            color: #ffffff;
            padding: 8px;
            text-transform: uppercase;
            margin-right: 16px;
            input {
                width: 32px;
                height: 32px;
                margin-right: 5px;
            }
            &:first-child {
                background-color: #e66a6a;
            }
            &:nth-child(2) {
                background-color: #0a558c;
            }
            &:last-child {
                background-color: #3ebd93;
            }
        }
    }
    .seo-edit_wrapper {
        margin: 24px 0px;
    }
}

.add_form {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid rgb(227, 230, 240);
    border-radius: 5.6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #858796;
    width: 100%;
    box-sizing: border-box;
    .relative_position span:first-child {
        border-radius: 50%;
        margin-right: 5px;
    }
    .add_input {
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #6e707e;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d1d3e2;
        border-radius: 0.35rem;
        box-sizing: border-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-top: 8px;
        margin-bottom: 16px;
        &:focus {
            border-color: #bac8f3;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        }
    }
}

@media only screen and (max-width: 1200px) {
    .category_profile_wrapper .blocks_wrapper {
        &:last-child {
            flex-direction: column;
        }
    }
}
