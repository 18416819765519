.summary_table {
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 24px;
  &:nth-last-child{
    margin-right: 0;
  }
  .data_card {
    width: 100%;
    min-height: 60px;
    padding: 20px;
    border-left: 0.25rem solid #1cc88a;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 5.6px;
    margin: 0 24px 8px 0;
    align-items: center;
    .card_text{
      display: flex;
      flex-direction: column;
      .card_title{
        margin-bottom: 4px;
        font-weight: 800;
        text-transform: uppercase;
        color: #1cc88a;
        font-size: 11px;
      }
      .card_amount{
        color: #5a5c69;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
  .data_card.no_data {
    border-left: 0.25rem solid #f6c23e;
    img{
      height: 32px;
    }
    .card_title{
      color: #f6c23e!important;
    }
  }
  .data_card.unactive{
    border-left: 0.25rem solid #5a5c69;
    .card_title{
      color: #5a5c69!important;
    }
  }
  .data_card.active_without_lawyer{
    border-left: 0.25rem solid #4e73df;
    .card_title{
      color: #4e73df!important;
    }
  }
  img{
    width: 32px;
  }
}